import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Checkbox,
  InputLabel,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  Backdrop,
  Fade,
  Modal,
} from "@mui/material";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import { IoMdSearch, IoMdAddCircle } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import Header from "../header/Header";
import Loading from "../loading/loading";
import {
  addCourier,
  getCourier,
  getCourierByMobile,
  updateCourier,
} from "../../features/shippingCourier/ShippingCourierSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify, { toastMes } from "../../globalConf/notification";
import { getGovernorates } from "./../../features/governorate/governorateSlice";

const ShippingCourier = () => {
  let data = {};
  data = JSON.parse(localStorage.getItem("userOP"));

  const { couriers, isLoading, isSuccess, error } = useSelector(
    (state) => state.courier
  );
  const { governorates } = useSelector((state) => state.governorate);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [addEditFlag, setAddEditFlag] = useState(true);
  const [selectedCourier, setSelectedCourier] = useState("");
  const [selectedCourierInfo, setSelectedCourierInfo] = useState("");
  const [searchByMobile, setSearchByMobile] = useState("");
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState("");
  const [manager, setManager] = useState("");
  const [courierAddres, setCourierAddres] = useState("");
  const [phone, setPhone] = useState("");
  const [landline, setLandline] = useState("");
  const [governorate, setGovernorate] = useState("");
  const [courierStatus, setCourierStatus] = useState(false);
  const [checkedCourierStatus, setCheckedCourierStatus] = useState([]);
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(false);

  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setName("");
    setManager("");
    setCourierAddres("");
    setPhone("");
    setLandline("");
    setGovernorate("");
    setCourierStatus(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (data) {
        if (data.user.groups.includes(2)) {
          let courierData = await dispatch(getCourier({ page, pageSize }));
          dispatch(getGovernorates());

          let checkedCourierArr = [...checkedCourierStatus];
          for (let i = 0; i < courierData.payload.results.length; i++) {
            checkedCourierArr.push(
              courierData.payload.results[i].courierStatus
            );
          }
          setCheckedCourierStatus(checkedCourierArr);
        } else {
          navigate("/");
        }
      } else {
        navigate("/login");
      }
    })();
  }, [page, pageSize, flag]);

  const handlePageChange = (pageNum) => {
    setPage(pageNum);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !manager ||
      !courierAddres ||
      !phone ||
      !landline ||
      !governorate
    ) {
      toastMes.error("All Fields required");
      return;
    } else {
      const data = {
        name,
        manager,
        courierAddres,
        phone,
        landline,
        governorate,
        courierStatus,
      };

      try {
        await httpNonToken.post(`${endPoints.createCourier}`, data);
        handleClose();
        setFlag(!flag);
      } catch ({ response }) {
        setSuccess(false);
        toastMes.error(response.data.message);
      }

      // dispatch(addCourier(data));
    }
  };
  const handleSelected = (id) => {
    setAddEditFlag(false);
    setSelectedCourier(id);
    const selectedCourierInfo = couriers.results.find(
      (courierInfo) => courierInfo.id === id
    );
    setSelectedCourierInfo(selectedCourierInfo);
    setName(selectedCourierInfo.name);
    setManager(selectedCourierInfo.manager);
    setCourierAddres(selectedCourierInfo.courierAddres);
    setPhone(selectedCourierInfo.phone);
    setLandline(selectedCourierInfo.landline);
    setGovernorate(selectedCourierInfo.governorate.id);
    setCourierStatus(selectedCourierInfo.courierStatus);
  };

  const handleChangeChecked = (e, val) => {
    setCourierStatus(val);
  };

  const handleChange = async (event, id, position = 0) => {
    let data = {};
    const updatedCheckedState = checkedCourierStatus.map((item, index) => {
      if (index === position) data.printingStatus = !item;
      return index === position ? !item : item;
    });
    setCheckedCourierStatus(updatedCheckedState);

    try {
      await httpNonToken.put(`${endPoints.updateCourier}/${id}`, data);
      setSuccess(true);
      toastMes.success("Courier Updated Successfully");
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = {
      name,
      manager,
      courierAddres,
      phone,
      landline,
      governorate,
      courierStatus,
    };
    if (
      !name ||
      !manager ||
      !courierAddres ||
      !phone ||
      !landline ||
      !phone ||
      !governorate
    ) {
      toastMes.error("All Fields required");
      return;
    }
    try {
      await httpNonToken.put(
        `${endPoints.updateCourier}/${selectedCourier}`,
        data
      );
      handleClose();
      setFlag(!flag);
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
    // dispatch(updateCourier({ courierData: data, id: selectedCourier }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchByMobile(e.target.value);
    dispatch(getCourierByMobile({ page, pageSize, phone: e.target.value }));
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      <Header />
      <TableContainer component={Paper} className="w_90 mt-4 m-auto">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div>
            <h2 className="text-start mx-3 mt-4 float-start">
              Shipping Courier
            </h2>
            <TextField
              id="outlined-basic"
              label="Search By Phone Number .."
              variant="outlined"
              size="small"
              value={searchByMobile}
              onChange={handleSearch}
              className="float-start mt-4 me-3"
            />
          </div>
          <div>
            <Button>
              <IoMdAddCircle
                className="fs-3 me-3 mt-3 fn_primary cursor-pointer"
                onClick={() => {
                  handleOpen();
                  setAddEditFlag(true);
                }}
              />
            </Button>
          </div>
        </div>
        <Table sx={{ minWidth: 450 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#dbdce0" }}>
            <TableRow>
              <TableCell>Shipping Courier</TableCell>
              <TableCell>Manager Name</TableCell>
              <TableCell>Courier Address</TableCell>
              <TableCell>Courier Phone Number</TableCell>
              <TableCell>Courier LandLine</TableCell>
              <TableCell>governorate</TableCell>
              <TableCell>Courier Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {couriers.results
              ? couriers.results.map((courier, index) => (
                  <TableRow key={courier.id}>
                    <TableCell>{courier.name}</TableCell>
                    <TableCell>{courier.manager}</TableCell>
                    <TableCell>{courier.courierAddres}</TableCell>
                    <TableCell>{courier.phone}</TableCell>
                    <TableCell>{courier.landline}</TableCell>
                    <TableCell>{courier.governorate.name}</TableCell>
                    <TableCell>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              name="printingStatus"
                              checked={checkedCourierStatus[index]}
                              onChange={(e) =>
                                handleChange(e, courier.id, index)
                              }
                            />
                          }
                          label=""
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell>
                      <Button>
                        <MdModeEdit
                          className="fs-3 me-3 mt-3 w_30 fn_primary cursor-pointer"
                          onClick={() => {
                            handleOpen();
                            handleSelected(courier.id);
                          }}
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {couriers.results?.length > 0 ? (
          <div className="paginate-box d-flex justify-content-end p-2">
            <Box>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pageSize}
                  label=""
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <button
              disabled={!couriers.previous}
              className="m-2 border-0 bg-transparent"
              onClick={() => handlePageChange(1)}
            >
              <Tooltip title={`First Page: 1`}>
                <i className="	fas fa-angle-double-left	"></i>
              </Tooltip>
            </button>
            <button
              onClick={() => handlePageChange(couriers.page_number - 1)}
              disabled={!couriers.previous}
              className="m-2 mx-3 border-0 bg-transparent"
            >
              <Tooltip title={`Previous Page: ${couriers.page_number - 1}`}>
                <i className="fas fa-chevron-left"></i>
              </Tooltip>
            </button>
            <p className="m-2 mt-3">{`${couriers.from}-${couriers.to}  of  ${couriers.count}`}</p>

            <button
              className="m-2 mx-3 border-0 bg-transparent"
              disabled={!couriers.next}
              onClick={() => handlePageChange(couriers.page_number + 1)}
            >
              <Tooltip title={`Next Page: ${couriers.page_number + 1}`}>
                <i className="fas fa-chevron-right"></i>
              </Tooltip>
            </button>
            <button
              className="m-2 border-0 bg-transparent"
              disabled={!couriers.next}
              onClick={() => handlePageChange(couriers.total_pages)}
            >
              <Tooltip title={`Last Page: ${couriers.total_pages}`}>
                <i className="	fas fa-angle-double-right	"></i>
              </Tooltip>
            </button>
          </div>
        ) : (
          ""
        )}
      </TableContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="row">
            <h5 className="col-12">
              {addEditFlag ? "Add New Courier" : "Edit Courier"}
            </h5>
            <div className="row">
              <div className="col-6 mt-2">
                <TextField
                  fullWidth
                  type="text"
                  id="outlined-basic"
                  label="Shipping Courier"
                  variant="outlined"
                  size="small"
                  className="me-3 mb-3"
                  value={name}
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-6 mt-2">
                <TextField
                  fullWidth
                  type="text"
                  id="outlined-basic"
                  label="Manager Name"
                  variant="outlined"
                  size="small"
                  className="me-3 mb-3"
                  value={manager}
                  name="manager"
                  onChange={(e) => setManager(e.target.value)}
                />
              </div>
              <div className="col-6 mt-2">
                <TextField
                  fullWidth
                  type="text"
                  id="outlined-basic"
                  label="Courier Address"
                  variant="outlined"
                  size="small"
                  className="me-3 mb-3"
                  value={courierAddres}
                  name="courierAddres"
                  onChange={(e) => setCourierAddres(e.target.value)}
                />
              </div>
              <div className="col-6 mt-2">
                <TextField
                  fullWidth
                  type="text"
                  id="outlined-basic"
                  label="Phone"
                  variant="outlined"
                  size="small"
                  className="me-3 mb-3"
                  value={phone}
                  name="phone"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="col-6 mt-2">
                <TextField
                  fullWidth
                  type="text"
                  id="outlined-basic"
                  label="LandLine"
                  variant="outlined"
                  size="small"
                  className="me-3 mb-3"
                  value={landline}
                  name="landline"
                  onChange={(e) => setLandline(e.target.value)}
                />
              </div>
              <div className="col-6 mt-2">
                <FormControl sx={{ minWidth: "100%" }} className="me-4">
                  <InputLabel id="governorate-label">Governorate</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Governorate"
                    value={governorate}
                    onChange={(e) => setGovernorate(e.target.value)}
                    // MenuProps={{ disablePortal: true }}
                  >
                    {governorates?.map((governorate, index) => (
                      <MenuItem key={index} value={governorate.id}>
                        {governorate.name}
                      </MenuItem>
                    ))}
                    )
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-6 offset-6 mt-3">
              {addEditFlag ? (
                <button className="btn btn-primary w-75" onClick={handleSubmit}>
                  Add Courier
                </button>
              ) : (
                <button className="btn btn-primary w-75" onClick={handleUpdate}>
                  Edit Courier
                </button>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* <CustomToastify bgColor={success ? "green" : "red"} duration={false} /> */}
    </div>
  );
};

export default ShippingCourier;
