import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { endPoints, httpNonToken } from "../../globalConf/http-non-token";
import CustomToastify, { toastMes } from "../../globalConf/notification";

export const getRcGroups = createAsyncThunk("rcGroup/get", async () => {
  try {
    const res = await httpNonToken.get(`${endPoints.rcGroup}`);
    return res.data;
  } catch (error) {}
});

export const getRadiologyCenter = createAsyncThunk(
  "radiologyCenter/get",
  async ({ page, pageSize }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await httpNonToken.get(
        `${endPoints.rcBranch}?page=${page}&page_size=${pageSize}`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getRadiologyCenterByMobile = createAsyncThunk(
  "radiologyCenter/getByMobile",
  async ({ page, pageSize, phone }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await httpNonToken.get(
        `${endPoints.rcBranch}?page=${page}&page_size=${pageSize}&phone__contains=${phone}`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addRadiologyCenter = createAsyncThunk(
  "radiologyCenter/create",
  async (radiologyCenterData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    let response;
    try {
      const res = await fetch(
        "https://py.qubx3d.com/api/v1/branch/user/create",
        {
          method: "POST",
          body: JSON.stringify(radiologyCenterData),
          headers: { "content-type": "application/json" },
        }
      );
      // const response = await res.json();
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateRadiologyCenter = createAsyncThunk(
  "radiologyCenter/update",
  async ({ radiologyCenterData, id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await fetch(
        `https://py.qubx3d.com/api/v1/branch/update/${id}`,
        {
          method: "PUT",
          body: JSON.stringify({ ...radiologyCenterData, id, user: 4 }),
          headers: { "content-type": "application/json" },
        }
      );
      const response = await res.json();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  radiologyCenters: [],
  rcGroups: [],
  isLoading: false,
  isSuccess: false,
  error: null,
};

export const radiologyCenterSlice = createSlice({
  name: "radiologyCenter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRcGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rcGroups = action.payload.results;
      })
      .addCase(getRcGroups.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getRadiologyCenter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getRadiologyCenter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.radiologyCenters = action.payload;
      })
      .addCase(getRadiologyCenter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getRadiologyCenterByMobile.pending, (state) => {
        // state.isLoading = true;
        state.error = null;
      })
      .addCase(getRadiologyCenterByMobile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.radiologyCenters = action.payload;
      })
      .addCase(getRadiologyCenterByMobile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(addRadiologyCenter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addRadiologyCenter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.radiologyCenters.results.unshift(action.payload);
      })
      .addCase(addRadiologyCenter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(updateRadiologyCenter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateRadiologyCenter.fulfilled, (state, action) => {
        state.radiologyCenters.results = [
          ...state?.radiologyCenters.results,
        ].map((radiologyCenter) =>
          radiologyCenter.id === action.payload.id
            ? action.payload
            : radiologyCenter
        );
        state.isLoading = false;
        state.isSuccess = true;

        toastMes.success("Radiology Center Updated Successfully");
        <CustomToastify
          bgColor={state.isSuccess ? "green" : "red"}
          duration={false}
        />;
      })
      .addCase(updateRadiologyCenter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default radiologyCenterSlice.reducer;
