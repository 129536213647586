import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import CustomToastify, { toastMes } from "../../globalConf/notification";

export const getPatient = createAsyncThunk(
  "patients/get",
  async ({ page, pageSize }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await httpNonToken.get(
        `${endPoints.patients}?page=${page}&page_size=${pageSize}`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPatientByMobile = createAsyncThunk(
  "patients/getByMobile",
  async ({ page, pageSize, mobile }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await httpNonToken.get(
        `${endPoints.patients}?page=${page}&page_size=${pageSize}&mobile__contains=${mobile}`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//Create Patient
export const addPatient = createAsyncThunk(
  "patient/create",
  async (patientData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await fetch("https://py.qubx3d.com/api/v1/patient/create", {
        method: "POST",
        body: JSON.stringify(patientData),
        headers: { "content-type": "application/json" },
      });
      const response = await res.json();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Edit Patient
export const updatePatient = createAsyncThunk(
  "patient/update",
  async ({ patientData, id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await fetch(
        `https://py.qubx3d.com/api/v1/patient/update/${id}`,
        {
          method: "PUT",
          body: JSON.stringify(patientData, id),
          headers: { "content-type": "application/json" },
        }
      );
      const response = await res.json();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  patients: [],
  // patientInfo: null,
  isLoading: false,
  isSuccess: false,
  error: null,
};
export const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get all patients
    builder
      .addCase(getPatient.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPatient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patients = action.payload;
      })
      .addCase(getPatient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getPatientByMobile.pending, (state) => {
        // state.isLoading = true;
        state.error = null;
      })
      .addCase(getPatientByMobile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patients = action.payload;
      })
      .addCase(getPatientByMobile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // create patient
    builder
      .addCase(addPatient.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addPatient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patients.results.unshift(action.payload);
        toastMes.success("Patient Added Successfully");
        <CustomToastify
          bgColor={state.isSuccess ? "green" : "red"}
          duration={false}
        />;
      })
      .addCase(addPatient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    //Edit Patient
    builder
      .addCase(updatePatient.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        state.patients.results = [...state?.patients.results].map((patient) =>
          patient.id === action.payload.id ? action.payload : patient
        );
        state.isLoading = false;
        state.isSuccess = true;
        toastMes.success("Patient Updated Successfully");
        <CustomToastify
          bgColor={state.isSuccess ? "green" : "red"}
          duration={false}
        />;
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});
export default patientSlice.reducer;
