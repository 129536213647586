import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../features/auth/loginSlice";
import radiologyCenterReducer from "../features/radiologyCenter/radiologyCenterSlice";
import patientReducer from "../features/patient/patientSlice";
import doctorReducer from "../features/doctor/doctorSlice";
import printReducer from "../features/print/printSlice";
import courierReducer from "../features/shippingCourier/ShippingCourierSlice";
import governorateReducer from "../features/governorate/governorateSlice";
import diconSourceReducer from "../features/diconSource/diconSourceSlice";

const store = configureStore({
  reducer: {
    login: loginReducer,
    radiologyCenter: radiologyCenterReducer,
    patient: patientReducer,
    doctor: doctorReducer,
    print: printReducer,
    courier: courierReducer,
    governorate: governorateReducer,
    diconSource: diconSourceReducer,

  },
});
export default store;
