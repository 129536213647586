import React, { useEffect, useState } from "react";

import { TextField } from "@mui/material";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import Loading from "../loading/loading";

import CustomToastify, { toastMes } from "../../globalConf/notification";

const Pathology = ({ pathologies, setFlag, flag }) => {
  const [success, setSuccess] = useState(false);
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [pathologiesArr, setPathologiesArr] = useState([]);
  const [selectedPart, setSelectedPart] = useState("");

  useEffect(() => {
    (async () => {
      let pathologiesArray = [...pathologiesArr];
      for (let i = 0; i < pathologies.length; i++) {
        pathologiesArray.push(pathologies[i].name);
      }

      setPathologiesArr(pathologiesArray);
    })();
  }, [flag]);

  const handleEdit = async (id, val) => {
    try {
      await httpNonToken.put(`${endPoints.updatePathology}/${id}`, {
        name: val,
      });
      setFlag(!flag);

      toastMes.success("pathology Updated Successfully");
      setSelectedPart("");
      setEdit(false);
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  const handleAdd = async () => {
    try {
      await httpNonToken.post(`${endPoints.createPathology}`, {
        name: selectedPart,
      });
      setFlag(!flag);

      toastMes.success("Pathology Created Successfully");
      setSelectedPart("");
      setAdd(false);
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  const handlePathologyChange = (e, position = 0) => {
    const updatedPathologyName = pathologiesArr.map((item, index) => {
      return index === position ? e.target.value : item;
    });
    setPathologiesArr(updatedPathologyName);
  };

  return (
    <div className="w-100 overflow-auto px-3 mt-5 bg-white">
      <div className="row border">
        <div className="col-11  text-bold py-4">Pathology</div>
        {add ? (
          <div className="row">
            <div className="col-10">
              <TextField
                fullWidth
                className="mb-3"
                type="text"
                id="outlined-basic"
                label="Add Pathology"
                size="small"
                value={selectedPart}
                onChange={(e) => setSelectedPart(e.target.value)}
              />
            </div>
            <div className="col-2 mt-2">
              <i
                className="fa-solid fa-circle-xmark text-danger cursor-pointer mx-2 fa-lg"
                onClick={() => {
                  setAdd(false);
                  setSelectedPart("");
                }}
              ></i>
              <i
                className="fa-solid fa-circle-check text-success cursor-pointer mx-2 fa-lg"
                onClick={handleAdd}
              ></i>
            </div>
          </div>
        ) : (
          <p className="col-1 text-bold mt-4">
            <i
              className="fa-solid fa-circle-plus fa-lg text-primary cursor-pointer"
              onClick={() => {
                setAdd(true);
                setEdit(false);
              }}
            ></i>
          </p>
        )}
      </div>
      <div className="row">
        {pathologies.length > 0
          ? pathologies.map((pathology, idx) => {
              return edit && selectedPart === pathology.name ? (
                <div key={idx} className="col-12 border-none p-4">
                  <TextField
                    fullWidth
                    type="text"
                    id="outlined-basic"
                    label="Edit Part Name"
                    size="small"
                    value={pathologiesArr[idx]}
                    onChange={(e) => handlePathologyChange(e, idx)}
                  />
                  <div className="mt-1">
                    <i
                      className="fa-solid fa-circle-xmark text-danger cursor-pointer mx-2 fa-lg"
                      onClick={() => {
                        setEdit(false);
                        setSelectedPart("");
                      }}
                    ></i>
                    <i
                      className="fa-solid fa-circle-check text-success cursor-pointer mx-2 fa-lg"
                      onClick={() =>
                        handleEdit(pathology.id, pathologiesArr[idx])
                      }
                    ></i>
                  </div>
                </div>
              ) : (
                <p
                  key={idx}
                  onClick={() => {
                    setEdit(true);
                    setAdd(false);
                    setSelectedPart(pathology.name);
                  }}
                  className="border p-4 mb-0 col-12 "
                >
                  {pathology.name}
                </p>
              );
            })
          : null}
      </div>

      {/* <CustomToastify  duration={false} /> */}
    </div>
  );
};

export default Pathology;
