import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";

import Loading from "../loading/loading";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Button,
  Modal,
  Backdrop,
  Fade,
  Typography,
} from "@mui/material";
import { IoMdSearch, IoMdAddCircle } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";

import {
  getPatient,
  addPatient,
  updatePatient,
} from "../../features/patient/patientSlice";
import CustomToastify, { toastMes } from "../../globalConf/notification";

const Patients = () => {
  let data = {};
  data = JSON.parse(localStorage.getItem("userOP"));

  const { patients, isLoading, isSuccess, error } = useSelector(
    (state) => state.patient
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [addEditFlag, setAddEditFlag] = useState(true);
  const [success, setSuccess] = useState(true);
  const [selectedPatient, setSelectedPatient] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setMobile("");
    setPhone("");
    setAddress("");
    setFullname("");
    setDOB("");
    setNational_id("");
  };

  //Data Patient
  const [fullname, setFullname] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [DOB, setDOB] = useState("");
  const [national_id, setNational_id] = useState("");
  const [address, setAddress] = useState("");
  const [flag, setFlag] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      if (data.user.groups.includes(2)) {
        dispatch(getPatient({ page, pageSize }));
      } else {
        navigate("/");
      }
    } else {
      navigate("/login");
    }
  }, [page, pageSize, flag]);

  const handlePageChange = (pageNum) => {
    setPage(pageNum);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!fullname || !mobile || !DOB) {
      toastMes.error("All Fields required");
    } else {
      const data = { fullname, mobile, phone, DOB, national_id, address };

      try {
        const addPatientRes = await httpNonToken.post(
          `${endPoints.createPatient}`,
          data
        );
        setSuccess(true);
        toastMes.success("Patient Created Successfully");
        handleClose();
        setFlag(!flag);
        setMobile("");
        setPhone("");
        setAddress("");
        setFullname("");
        setDOB("");
        setNational_id("");
        handleClose();
      } catch ({ response }) {
        for (const key in response.data) {
          setSuccess(false);
          toastMes.error(`${key}: ${response.data[key]}`);
        }
      }
    }
  };

  const handleSelected = async (id) => {
    setAddEditFlag(false);
    setSelectedPatient(id);

    try {
      const getPatientRes = await httpNonToken.get(
        `${endPoints.patients}/${id}`
      );
      setFullname(getPatientRes.data.fullname);
      setMobile(getPatientRes.data.mobile);
      setPhone(getPatientRes.data.phone);
      setAddress(getPatientRes.data.address);
      setDOB(getPatientRes.data.DOB);
      setNational_id(getPatientRes.data.national_id);

      handleOpen();
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
    //
    handleOpen();
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!fullname || !mobile || !DOB) {
      setSuccess(false);
      toastMes.error("All Fields are required");
      return;
    }
    const data = {
      fullname,
      mobile,
      phone,
      DOB,
      national_id,
      address,
    };
    try {
      const updatePatientRes = await httpNonToken.put(
        `${endPoints.updatePatient}/${selectedPatient}`,
        data
      );
      setFullname("");
      setMobile("");
      setPhone("");
      setAddress("");
      setDOB();
      setNational_id();
      handleClose();
      setFlag(!flag);
    } catch ({ response }) {
      for (const key in response.data) {
        if (key === "user") {
          for (const inKey in response.data[key]) {
            setSuccess(false);
            toastMes.error(`${key}-${inKey}: ${response.data[key][inKey]}`);
          }
        } else {
          setSuccess(false);
          toastMes.error(`${key}: ${response.data[key]}`);
        }
      }
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Header />
      <TableContainer component={Paper} className="w_90 mt-4 m-auto">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h3 className="text-start ms-3 mt-3">Patient</h3>
          <div>
            <Button onClick={handleOpen}>
              <IoMdAddCircle
                className="fs-3 me-3 mt-3 fn_primary cursor-pointer"
                onClick={() => setAddEditFlag(true)}
              />
            </Button>
          </div>
        </div>
        <Table sx={{ minWidth: 450 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#dbdce0" }}>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Date Of Birth</TableCell>
              <TableCell>National ID</TableCell>
              <TableCell>Address</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.results
              ? patients.results.map((patient) => (
                  <TableRow sx={{ padding: "2px 14px" }} key={patient.id}>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {patient.fullname}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {patient.mobile}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {patient.phone}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {patient.DOB}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {patient.national_id}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {patient.address}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      <Button>
                        <MdModeEdit
                          className="fs-3 me-3 mt-3 fn_primary cursor-pointer"
                          onClick={() => handleSelected(patient.id)}
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>

        {patients.results && (
          <div className="paginate-box d-flex justify-content-end p-2">
            <Box>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pageSize}
                  label=""
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <button
              disabled={!patients.previous}
              className="m-2 border-0 bg-transparent"
              onClick={() => handlePageChange(1)}
            >
              <Tooltip title={`First Page: 1`}>
                <i className="	fas fa-angle-double-left	"></i>
              </Tooltip>
            </button>
            <button
              onClick={() => handlePageChange(patients.page_number - 1)}
              disabled={!patients.previous}
              className="m-2 mx-3 border-0 bg-transparent"
            >
              <Tooltip title={`Previous Page: ${patients.page_number - 1}`}>
                <i className="fas fa-chevron-left"></i>
              </Tooltip>
            </button>
            <p className="m-2 mt-3">{`${patients.from}-${patients.to}  of  ${patients.count}`}</p>

            <button
              className="m-2 mx-3 border-0 bg-transparent"
              disabled={!patients.next}
              onClick={() => handlePageChange(patients.page_number + 1)}
            >
              <Tooltip title={`Next Page: ${patients.page_number + 1}`}>
                <i className="fas fa-chevron-right"></i>
              </Tooltip>
            </button>
            <button
              className="m-2 border-0 bg-transparent"
              disabled={!patients.next}
              onClick={() => handlePageChange(patients.total_pages)}
            >
              <Tooltip title={`Last Page: ${patients.total_pages}`}>
                <i className="	fas fa-angle-double-right	"></i>
              </Tooltip>
            </button>
          </div>
        )}
      </TableContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="row">
            <div className="col-12 mt-2">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {addEditFlag ? "Add New Patient" : "Edit Patient"}
              </Typography>
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                value={fullname}
                type="text"
                id="outlined-basic"
                label="Full name"
                variant="outlined"
                size="small"
                className="me-3 mb-3"
                onChange={(e) => setFullname(e.target.value)}
              />
            </div>

            <div className="col-6 mt-2">
              <TextField
                value={mobile}
                fullWidth
                type="text"
                id="outlined-basic"
                label="Mobile"
                variant="outlined"
                size="small"
                className="me-3 mb-3 "
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                value={phone}
                fullWidth
                type="text"
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                size="small"
                className="me-3 mb-3 "
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                value={DOB}
                type="date"
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                className="me-3 mb-3 "
                onChange={(e) => setDOB(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                value={national_id}
                fullWidth
                type="text"
                id="outlined-basic"
                label="National id"
                variant="outlined"
                size="small"
                className="me-3 mb-3 "
                onChange={(e) => setNational_id(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                value={address}
                fullWidth
                type="text"
                id="outlined-basic"
                label="Address"
                variant="outlined"
                size="small"
                className="me-3 mb-3 "
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2 offset-6">
              {addEditFlag ? (
                <button
                  className="btn btn-primary  w-100"
                  onClick={handleSubmit}
                >
                  Add Patient
                </button>
              ) : (
                <button
                  className="btn btn-primary  w-100"
                  onClick={handleUpdate}
                >
                  Edit Patient
                </button>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* <CustomToastify bgColor={success ? "green" : "red"} duration={false} /> */}
    </div>
  );
};

export default Patients;
