import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { enGB } from "date-fns/locale";
import TableToExcel from "@linways/table-to-excel";
import "./style.css";

export default function TemporaryDrawer() {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [allRequestStatus, setAllRequestStatus] = useState([]);
  //
  const [selectedService, setSelectedService] = useState("");
  const [allRequestService, setAllRequestService] = useState([]);
  //
  const [selectedRC, setSelectedRC] = useState("");
  const [allRCs, setAllRCs] = useState([]);

  const [requests, setRequests] = useState([]);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [viewFlag, setViewFlag] = useState(true);
  const [load, setLoad] = useState(true);

  let data = JSON.parse(localStorage.getItem("userOP"));

  useEffect(() => {
    (async () => {
      const resStatus = await httpNonToken.get(
        `${endPoints.requestStatus}?order_by=id`
      );

      const branchedRes = await httpNonToken.get(
        `${endPoints.rcBranch}?order_by=id&page_size=1000`
      );
      const resService = await httpNonToken.get(
        `${endPoints.serviceType}?order_by=id`
      );
      setAllRequestStatus(resStatus.data.results);
      setAllRequestService(resService.data.results);
      setAllRCs(branchedRes.data.results);
    })();
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    clearFilters();
    setViewFlag(true);
    setLoad(true);
  };

  const ToExcell = () => {
    TableToExcel.convert(document.getElementById("table-requests-data"), {
      name: "Qubx-Requests.xlsx",
      sheet: {
        name: "Sheet 1",
      },
    });
  };

  const list = (anchor) => (
    <Box
      className="analytical-data"
      sx={{
        width: "auto",
        margin: "20px 40px",
        minHeight: viewFlag ? "200px" : "100vh",
        overflow: "auto",
      }}
      role="presentation"
    >
      <div className="d-flex justify-content-start">
        <i
          onClick={toggleDrawer(anchor, false)}
          className="fas fa-times fa-2x d-inline-block mb-3 cursor-pointer"
        ></i>
      </div>
      {viewFlag ? (
        <div className="filter-section">
          <div className="row">
            <div className="col-md-2">
              <Box sx={{ minWidth: 200 }}>
                <FormControl className={`w-100`}>
                  <InputLabel
                    id="demo-simple-select-label"
                    // className={Styles.label_margin}
                  >
                    Status
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={selectedStatus}
                    // className={`${Styles.h_status}`}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    {allRequestStatus.map((status, index) => (
                      <MenuItem key={index} value={status.id}>
                        {status.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box sx={{ minWidth: 200 }}>
                <FormControl className={`w-100`}>
                  <InputLabel id="demo-simple-select-label">Service</InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Service"
                    value={selectedService}
                    onChange={(e) => setSelectedService(e.target.value)}
                  >
                    {allRequestService.map((service, index) => (
                      <MenuItem key={index} value={service.id}>
                        {service.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box sx={{ minWidth: 200 }}>
                <FormControl className={`w-100`}>
                  <InputLabel id="demo-simple-select-label">
                    Radiology Center
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Radiology Center"
                    value={selectedRC}
                    onChange={(e) => setSelectedRC(e.target.value)}
                  >
                    {allRCs.map((rc, index) => (
                      <MenuItem key={index} value={rc.id}>
                        {rc.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-6">
              <div className="searchDate">
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onStartDateChange={setStartDate}
                  onEndDateChange={setEndDate}
                  minimumLength={1}
                  format="dd MMM yyyy"
                  locale={enGB}
                >
                  {({ startDateInputProps, endDateInputProps, focus }) => (
                    <div className="date-range">
                      <input
                        className={
                          "input" + (focus === START_DATE ? " -focused" : "")
                        }
                        {...startDateInputProps}
                        placeholder="Start date"
                      />
                      <i className="fa-solid fa-angle-right text-secondary mx-2"></i>
                      <input
                        className={
                          "input" + (focus === END_DATE ? " -focused" : "")
                        }
                        {...endDateInputProps}
                        placeholder="End date"
                      />
                    </div>
                  )}
                </DateRangePicker>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button
              variant="outlined"
              color="error"
              className="mx-2"
              onClick={clearFilters}
            >
              Clear Filters
            </Button>
            <Button variant="contained" onClick={getAllRequests}>
              Get Requests
            </Button>
          </div>
        </div>
      ) : load ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-start m-2 my-4 text-success cursor-pointer">
            <i onClick={ToExcell} className="fa-solid fa-file-excel fa-2x"></i>
          </div>
          <div className="d-flex justify-content-start m-2 my-1">
            <p>
              Requests Count: <b>{requests.length}</b>
            </p>
          </div>
          <Table
            id="table-requests-data"
            style={{ tableLayout: "fixed", width: "100%" }}
            aria-label="simple table"
          >
            <TableHead sx={{ backgroundColor: "#dbdce0" }}>
              <TableRow>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  ID
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Date
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Price
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Special Discount
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Status
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Service Name
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Service Type
                </TableCell>

                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  DICOM Type
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Anatomy
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Pathology
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="300px">
                  Request URL
                </TableCell>

                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Branch Name
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Branch Mobile
                </TableCell>

                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Doctor Name
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Doctor Governorate
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Doctor Region
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Doctor Mobile
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Doctor Assistance-Phone
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Doctor Clinic Contact
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Doctor Clinic Address
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Patient Name
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Patient Mobile
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Patient Phone
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Patient ( Date Of Birth )
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Patient ( National ID )
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Operator
                </TableCell>

                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Courier Tracking Code
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Courier Cost
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Courier Delevery Phone
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Courier Name
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Courier Manager Name
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Courier Landline
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Courier Center Phone
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Courier Address
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Courier Governorate
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Courier Region
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Printing Center Name
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Printing Center Main Store
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Printing Center Manager
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Printing Center Address
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Printing Center Mobile
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Printing Center Phone
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Printing Center Governorate
                </TableCell>
                <TableCell style={{ padding: "8px 16px" }} width="180px">
                  Printing Center Region
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requests.length > 0 ? (
                requests.map((req, index) => (
                  <TableRow key={req.id}>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.id}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {new Date(req.created_at).toLocaleString()}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.price}
                    </TableCell>

                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.special_discount}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.statu.name}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.service.name}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.service.type.name}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.DICON_source.name}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.anatomy ? req.anatomy.name : ""}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.pathology ? req.pathology.name : ""}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="300px">
                      {`https://view.qubx3d.com/${req.code}`}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.branchName}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.created_by ? req.created_by.mobile : ""}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.doctor?.fullname}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.doctor?.governorate?.name}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.doctor?.governorate?.region?.name}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.doctor?.assistance_phone}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.doctor?.clinic_contact}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.doctor?.clinic_address}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.doctor?.mobile}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.patient?.fullname}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.patient?.mobile}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.patient?.phone}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.patient?.DOB}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.patient?.national_id}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.assign_to ? req.assign_to.fullname : ""}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.courier?.tracking_code}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.courier?.cost}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.courier?.phone}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.courier?.courier?.name}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.courier?.courier?.manager}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.courier?.courier?.landline}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.courier?.courier?.phone}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.courier?.courier?.courierAddres}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.courier?.courier?.governorate?.name}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.courier?.courier?.governorate?.region?.name}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.printing?.name}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.printing?.mainStore}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.printing?.manager_name}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.printing?.address}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.printing?.mobile}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.printing?.phone}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.printing?.printing?.governorate?.name}
                    </TableCell>
                    <TableCell style={{ padding: "8px 16px" }} width="180px">
                      {req.printing?.printing?.governorate?.region?.name}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="text-center" colSpan={8}>
                    No Items Here
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  );

  const clearFilters = async () => {
    setSelectedStatus("");
    setSelectedService("");
    setSelectedRC("");
    setRequests([]);
    setEndDate(null);
    setStartDate(null);
  };

  const getAllRequests = async () => {
    setViewFlag(false);
    setLoad(true);

    try {
      const requestsRes = await httpNonToken.get(
        `${endPoints.allRequests}?order_by=-created_at${
          selectedStatus ? `&statu__id=${selectedStatus}` : ""
        }${selectedRC ? `&created_by__branch_profile__id=${selectedRC}` : ""}${
          selectedService ? `&service__type__id=${selectedService}` : ""
        }${
          startDate
            ? `&created_at__date__gte=${
                new Date(startDate).toISOString().split("T")[0]
              }`
            : ""
        }${
          endDate
            ? `&created_at__date__lte=${
                new Date(endDate).toISOString().split("T")[0]
              }`
            : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${data.access}`,
          },
        }
      );
      setRequests(requestsRes.data);
      setLoad(false);
    } catch (error) {
      setLoad(false);
    }
  };

  return (
    <div>
      {["top"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <i className="fa-solid fa-file-excel fa-2x"></i>
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
