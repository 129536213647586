import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./login.module.css";
import CustomToastify, { toastMes } from "../../globalConf/notification";
import { endPoints, httpNonToken } from "../../globalConf/http-non-token";
import { TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Button } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Login = () => {
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    mobile: "",
    password: "",
  });

  const { mobile, password } = formData;
  let navigate = useNavigate();

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const elementsToCheck = [1,2,5];
    if (!formData.mobile.trim() || !formData.password.trim()) {
      setSuccess(false);
      toastMes.error("Mobile and Password are required");
      return;
    }
    // dispatch(loginPending());

    try {
      let dataRes = await httpNonToken.post(endPoints.login, formData);
      const doesNotContainAny = elementsToCheck.every(element => !dataRes?.data?.user?.groups.includes(element));
      if (dataRes.status === 401) {
        setSuccess(false);
        toastMes.error("User Not Found!");
      }
      if(doesNotContainAny){
        setSuccess(false);
        toastMes.error("Unauthorized! you don't have permission to login!");
      
    }else if(!doesNotContainAny){
      localStorage.setItem("userOP", JSON.stringify(dataRes.data));
      navigate("/");
      
  }
  
    } catch ({ response }) {
      if (response.data) {
        setSuccess(false);
        toastMes.error(response.data.detail);
      } else {
        setSuccess(false);
        toastMes.error(response.data.detail);
      }
    }
  };

  const forgetPassword = async () => {
    if (!formData.mobile) {
      toastMes.error("Mobile is required");
      return;
    }
    try {
      const forgetPasswordRes = await httpNonToken.put(
        `${endPoints.forgetPassword}/?mobile=${formData.mobile.trim()}`
      );
      if (forgetPasswordRes.status === 200) {
        toastMes.success("New Password will be sent to your phone");
        handleClose();
      } else {
        toastMes.success("Something went wrong ..!");
      }
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="row m-auto rounded w_70 my-4">
        <div className={`col p-0 ${styles.login_left_side}`}>
          <img
            src={"/images/login/qubx-logo-white.png"}
            alt="Qubx logo"
            className={styles.qubx_logo_left_side}
          />

          <h5 className="text-white ml_30">DIAGNOSE SIMPLE</h5>
        </div>
        <div className={`col p-0 pb-3 bg-white ${styles.login_right_side}`}>
          <div className="text-center">
            <img
              src={"/images/login/qubx-logo-black.png"}
              alt="Qubx logo"
              className={styles.qubx_logo}
            />
          </div>
          <h5 className="text-capitalize text-center fn_main">Login to QUBX</h5>
          <form className="w_90 m-auto" onSubmit={handleSubmit}>
            <div className="text-start my-5 form-box">
              <TextField
                // required
                type="text"
                name="mobile"
                id="mobile"
                value={mobile}
                onChange={handleChange}
                label="Mobile"
                size="small"
                variant="outlined"
                className="w-100"
              />
            </div>

            <div className="text-start my-5 mb-2 form-box">
              <TextField
                // required
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={handleChange}
                label="password"
                size="small"
                variant="outlined"
                className="w-100"
              />
            </div>
            <div className="d-flex justify-content-end">
              <p
                onClick={handleClickOpen}
                className="text-decoration-underline cursor-pointer text-primary"
              >
                Forget Password ..!?
              </p>
            </div>

            <div className=" buttons-g my-5 mt-3">
              <button className="btn btn-primary w_40 mx-3" type="submit">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Forget Password</DialogTitle>
        <DialogContent>
          <div>
            <i className="fas fa-exclamation-triangle fa-3x text-danger text-center d-block my-2"></i>
          </div>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="text-center"
          >
            New Random Password will sent to your Phone Number in Message, and
            you can update it later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={forgetPassword}>Proceed Anyway</Button>
        </DialogActions>
      </Dialog>
      <CustomToastify bgColor={success ? "green" : "red"} duration={9000} />
    </>
  );
};

export default Login;
