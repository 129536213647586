import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import CustomToastify, { toastMes } from "../../globalConf/notification";

// thunk for Get all Printing
export const getPrints = createAsyncThunk(
  "prints/get",
  async ({ page, pageSize }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await httpNonToken.get(
        `${endPoints.printing}?page=${page}&page_size=${pageSize}`
      );

      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPrintByMobile = createAsyncThunk(
  "prints/getByMobile",
  async ({ page, pageSize, mobile }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await httpNonToken.get(
        `${endPoints.printing}?page=${page}&page_size=${pageSize}&mobile__contains=${mobile}`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// thunk for create Printing
export const addPrint = createAsyncThunk(
  "print/create",
  async (printData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await fetch("https://py.qubx3d.com/api/v1/printing/create", {
        method: "POST",
        body: JSON.stringify(printData),
        headers: { "content-type": "application/json" },
      });
      const response = await res.json();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// thunk for Edit Print

export const updatePrint = createAsyncThunk(
  "print/update",
  async ({ printData, id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await fetch(
        `https://py.qubx3d.com/api/v1/printing/update/${id}`,
        {
          method: "PUT",
          body: JSON.stringify(printData, id),
          headers: { "content-type": "application/json" },
        }
      );
      const response = await res.json();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  prints: [],
  isLoading: false,
  isSuccess: false,
  error: null,
};
export const printSlice = createSlice({
  name: "print",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get all print
    builder
      .addCase(getPrints.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPrints.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.prints = action.payload;
      })
      .addCase(getPrints.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getPrintByMobile.pending, (state) => {
        // state.isLoading = true;
        state.error = null;
      })
      .addCase(getPrintByMobile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.prints = action.payload;
      })
      .addCase(getPrintByMobile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    //Create print
    builder
      .addCase(addPrint.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addPrint.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.prints.results.unshift(action.payload);
      })
      .addCase(addPrint.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    //Edit print

    builder
      .addCase(updatePrint.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePrint.fulfilled, (state, action) => {
        state.prints.results = [...state?.prints.results].map((print) =>
          print.id === action.payload.id ? action.payload : print
        );
        state.isLoading = false;
        state.isSuccess = true;
        toastMes.success("Print provider Updated Successfully");
        <CustomToastify
          bgColor={state.isSuccess ? "green" : "red"}
          duration={false}
        />;
      })
      .addCase(updatePrint.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default printSlice.reducer;
