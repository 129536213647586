import React, { useEffect, useState } from "react";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Fade,
  Box,
  Modal,
  Typography,
  Backdrop,
  Divider,
  TextField,
} from "@mui/material";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import Loading from "../loading/loading";

import CustomToastify, { toastMes } from "../../globalConf/notification";

const AgeGroup = ({ ageGroups, flag, setFlag }) => {
  const [success, setSuccess] = useState(false);
  const [ageStartArr, setAgeStartArr] = useState([]);
  const [ageEndArr, setAgeEndArr] = useState([]);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  useEffect(() => {
    (async () => {
      let ageStartArray = [...ageStartArr];
      let ageEndArray = [...ageEndArr];
      for (let i = 0; i < ageGroups.length; i++) {
        ageStartArray.push(ageGroups[i].start);
      }
      for (let i = 0; i < ageGroups.length; i++) {
        ageEndArray.push(ageGroups[i].end);
      }
      setAgeStartArr(ageStartArray);
      setAgeEndArr(ageEndArray);
    })();
  }, [flag]);

  const handleSubmit = async (id, startVal, endVal) => {
    try {
      let startValue, endValue;
      startValue = !start ? startVal : start;
      endValue = !end ? endVal : end;

      await httpNonToken.put(`${endPoints.updateAgeGroup}/${id}`, {
        start: startValue,
        end: endValue,
      });
      toastMes.error("Age Group Updated Successfully");
      setFlag(!flag);
      setStart("");
      setEnd("");
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  const handleAgeChange = (e, position = 0) => {
    if (e.target.name === "start") {
      setStart(e.target.value);
      const updatedStartState = ageStartArr.map((item, index) => {
        return index === position ? e.target.value : item;
      });
      setAgeStartArr(updatedStartState);
    } else {
      setEnd(e.target.value);
      const updatedEndState = ageEndArr.map((item, index) => {
        return index === position ? e.target.value : item;
      });
      setAgeEndArr(updatedEndState);
    }
  };

  return (
    <div className="w-100 py-5 px-3 ">
      <Table aria-label="simple table" sx={{ bgcolor: "background.paper" }}>
        <TableHead>
          <TableRow>
            <TableCell className="border text-bold" colSpan={2}>
              Ages
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="border">Group</TableCell>
            <TableCell className="border">Age</TableCell>
            <TableCell className="border"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ageGroups.length > 0
            ? ageGroups.map((ag, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell className="border" sx={{ padding: "10px 14px" }}>
                      {ag.name}
                    </TableCell>
                    <TableCell
                      className="border d-flex justify-content-between"
                      sx={{ padding: "10px 14px" }}
                    >
                      <TextField
                        type="number"
                        id="outlined-basic"
                        label="Age-Start"
                        variant="outlined"
                        size="small"
                        name="start"
                        value={ageStartArr[idx]}
                        onChange={(e) => handleAgeChange(e, idx)}
                      />
                      <span className="mx-3 mt-2">:</span>
                      <TextField
                        type="number"
                        id="outlined-basic"
                        label="Age-End"
                        variant="outlined"
                        size="small"
                        name="end"
                        value={ageEndArr[idx]}
                        onChange={(e) => handleAgeChange(e, idx)}
                      />
                    </TableCell>
                    <TableCell className="border">
                      <i
                        className="fa-solid fa-pen fa-lg text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleSubmit(ag.id, ageStartArr[idx], ageEndArr[idx])
                        }
                      ></i>
                    </TableCell>
                  </TableRow>
                );
              })
            : null}
        </TableBody>
      </Table>

      {/* <CustomToastify  duration={false} /> */}
    </div>
  );
};

export default AgeGroup;
