import React, { useState, useReducer, Fragment } from "react";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import SelectPrint from "./selectPrint";
import Print360 from "./print360";
import SelectDicomType from "./selectDicomType";
import SelectDoctor from "./selectDoctor";
import Checkbox from "@mui/material/Checkbox";
import { PDFDocument } from "pdf-lib";
import html2canvas from "html2canvas";
import download from "downloadjs";
//
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { httpNonToken, endPoints } from "../../../globalConf/http-non-token";
import CustomToastify, { toastMes } from "../../../globalConf/notification";
import Styles from "./style.module.css";

const steps = ["Select Service Type", "Select DICOM Type", "Choose Doctor"];

const CopyRequest = ({ open, handleClose, copyRequestFun, request }) => {
  let dataAuth = JSON.parse(localStorage.getItem("userOP"));

  const [activeStep, setActiveStep] = useState(0);
  const [serviceFlag, setServiceFlag] = useState(false);
  const [dicomFlag, setDicomFlag] = useState(false);
  const [doctorFlag, setDoctorFlag] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [requestCopy, setRequestCopy] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      doctorName: "",
      serviceName: "",
      digitalFile: false,
      submit: false,
      digitalServiceName: "",
      digitalService: "",

      service: "",
      DICON_source: "",
      special_discount: 0.0,
      // doctor: "",
    }
  );
  //
  const [discount, setDiscount] = useState(0.0);
  const [servicePrice, setServicePrice] = useState();
  const [requestResult, setRequestResult] = useState({});

  //

  const handleNext = async () => {
    if (activeStep === 0) {
      if (!requestCopy.service && !requestCopy.digitalService) {
        setServiceFlag(true);
        return;
      }
    }
    if (activeStep === 1 && !requestCopy.DICON_source) {
      setDicomFlag(true);
      return;
    }
    if (activeStep === 2) {
      try {
        let service =
          requestCopy.digitalService && requestCopy.service
            ? [requestCopy.service, requestCopy.digitalService]
            : requestCopy.digitalService && !requestCopy.service
            ? [requestCopy.digitalService]
            : !requestCopy.digitalService && requestCopy.service
            ? [requestCopy.service]
            : null;
        let getPriceRes = await httpNonToken.post(`${endPoints.requestPrice}`, {
          patient: request.patient.id,
          service,
          branch: 4,
        });

        if (getPriceRes.status === 200) {
          setServicePrice(getPriceRes.data.amount);
        }
      } catch ({ response }) {
        for (const key in response.data) {
          toastMes.error(`${key}: ${response.data[key]}`);
        }
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRequestCopy = async () => {
    let copyReqResponse = await copyRequestFun("val", requestCopy);
    setRequestResult(copyReqResponse);
    setSubmit(true);
  };

  const handleCloseModel = () => {
    setActiveStep(0);
    handleClose();
    setSubmit(false);
    setRequestResult({});
    setRequestCopy({
      serviceName: "",
      digitalServiceName: "",
      digitalService: "",
      digitalFile: false,
      submit: false,
      service: "",
      DICON_source: "",
      special_discount: 0.0,
      doctorName: "",
    });
  };

  const handleDiscountChange = (e) => {
    setDiscount(e.target.value);
    setRequestCopy({ special_discount: e.target.value });
  };

  const pdfDownload = async (e) => {
    e.preventDefault();

    html2canvas(document.getElementById("service-pdf-view")).then(
      async function (canvas) {
        // Fetch JPEG image
        var dataURL = canvas.toDataURL("image/jpeg", 1.0);
        const jpgUrl = dataURL;
        const jpgImageBytes = await fetch(jpgUrl).then((res) =>
          res.arrayBuffer()
        );
        // Create a new PDFDocument
        const pdfDoc = await PDFDocument.create();
        // Embed the JPG image bytes and PNG image bytes
        const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
        // Get the width/height of the JPG image scaled down to 25% of its original size
        const jpgDims = jpgImage.scale(0.3);
        // Add a blank page to the document
        const page = pdfDoc.addPage();
        // Draw the JPG image in the center of the page
        page.drawImage(jpgImage, {
          x: page.getWidth() / 2 - jpgDims.width / 2,
          y: page.getHeight() - jpgDims.height,
          width: jpgDims.width,
          height: jpgDims.height,
        });
        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();
        // Trigger the browser to download the PDF document
        download(
          pdfBytes,
          `${request.patient.fullname} Case.pdf`,
          "application/pdf"
        );
      }
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <Fragment>
                <Box className="check-out-box mt-5">
                  <div className="d-flex justify-content-between">
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Price
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {servicePrice} EGP
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Discount
                    </Typography>
                    <TextField
                      disabled={submit}
                      type="number"
                      placeholder="0.00 EGP"
                      id="standard-basic"
                      size="small"
                      variant="standard"
                      value={discount}
                      onChange={(e) => handleDiscountChange(e)}
                    />
                  </div>
                  <Divider />
                  <div className="d-flex justify-content-between my-2">
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Total Price
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {isNaN(parseFloat(servicePrice) - parseFloat(discount))
                        ? servicePrice
                        : parseFloat(servicePrice) - parseFloat(discount)}{" "}
                      EGP
                    </Typography>
                  </div>
                  <div className="mt-3"></div>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button color="error" onClick={handleCloseModel}>
                    Close
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {submit ? (
                    <i
                      onClick={pdfDownload}
                      className="fa-solid fa-file-pdf text-primary fa-2x mx-2"
                      style={{ cursor: "pointer" }}
                    ></i>
                  ) : (
                    <Button onClick={handleRequestCopy}>Submit</Button>
                  )}
                </Box>
              </Fragment>
            ) : (
              <Fragment>
                <Box sx={{ mt: 2, mb: 1 }}>
                  {activeStep === 0 ? (
                    <div className="row">
                      {serviceFlag ? (
                        <p
                          className="col-12 text-center text-danger my-2"
                          style={{ fontSize: "12px" }}
                        >
                          {" "}
                          At Least One Service is required
                        </p>
                      ) : null}
                      <div className="col-md-6 col-sm-12">
                        <SelectPrint
                          setServiceFlag={setServiceFlag}
                          requestCopy={requestCopy}
                          setRequestCopy={setRequestCopy}
                        />
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <Print360
                          setServiceFlag={setServiceFlag}
                          requestCopy={requestCopy}
                          setRequestCopy={setRequestCopy}
                        />
                      </div>
                    </div>
                  ) : activeStep === 1 ? (
                    <div>
                      {dicomFlag ? (
                        <p
                          className="text-center text-danger my-2"
                          style={{ fontSize: "12px" }}
                        >
                          {" "}
                          DICOM Type is required
                        </p>
                      ) : null}
                      <SelectDicomType
                        setDicomFlag={setDicomFlag}
                        requestCopy={requestCopy}
                        setRequestCopy={setRequestCopy}
                      />
                    </div>
                  ) : activeStep === 2 ? (
                    <div>
                      {doctorFlag ? (
                        <p
                          className="text-center text-danger my-2"
                          style={{ fontSize: "12px" }}
                        >
                          {" "}
                          Doctor is required
                        </p>
                      ) : null}
                      <SelectDoctor
                        request={request}
                        setDoctorFlag={setDoctorFlag}
                        requestCopy={requestCopy}
                        setRequestCopy={setRequestCopy}
                      />
                    </div>
                  ) : null}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button color="error" onClick={handleClose} sx={{ mr: 1 }}>
                    Close
                  </Button>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />

                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Box>
              </Fragment>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      {Object.keys(requestResult).length ? (
        <div
          id="service-pdf-view"
          style={{
            position: "relative",
            minHeight: "2200px",
            borderBottom: "1px solid #365F91",
          }}
          className="p-2 w-100 mt-4"
        >
          <div
            style={{ borderTop: "1px solid #365F91" }}
            className={`d-flex justify-content-between my-5 py-3 px-2 ${Styles.header_section}`}
          >
            <div className="image-box w-25 h-25 mb-4">
              <img
                src="/images/header/logo-black.png"
                className="w-100 h-100"
                alt="logo"
              />
            </div>
            <h1 className="mt-3 bold" style={{ color: "#365F91" }}>
              INVOICE
            </h1>
          </div>
          <div className="d-flex justify-content-between my-3">
            <div className="">
              <h2>QUBX</h2>
              <h5>PERSONALIZED HEALTHCARE</h5>
            </div>
            <div>
              <div className="d-flex justify-content-start">
                <h4 className="mx-2">INVOICE </h4>
                <h4>#{requestResult.id}</h4>
              </div>
              <div className="d-flex justify-content-start">
                <h4 className="mx-2">DATE:</h4>
                <h4>{new Date(requestResult.created_at).toLocaleString()}</h4>
                {/* {Date().toLocaleString()} */}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between my-5">
            <div className="d-flex justify-content-start">
              <h4 className="mx-2">FROM:</h4>
              <h4>
                {Object.keys(dataAuth).length
                  ? dataAuth.branch
                    ? dataAuth?.branch?.name
                    : "qubx-rc"
                  : null}
              </h4>
            </div>
            <div>
              <div className="d-flex justify-content-start">
                <h4 className="mx-2">To:</h4>
                <h4>{request?.patient?.fullname}</h4>
              </div>
              <div className="d-flex justify-content-start">
                <h4 className="mx-2">Phone:</h4>
                <h4> {request?.patient?.mobile}</h4>
              </div>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#DAE4F0" }}>
                  <TableCell align="center">
                    <h5>QTY</h5>
                  </TableCell>
                  <TableCell align="center">
                    <h5>ITEM</h5>
                  </TableCell>
                  <TableCell align="center">
                    <h5>UNIT PRICE</h5>
                  </TableCell>
                  <TableCell align="center">
                    <h5>LINE TOTAL</h5>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <h6>1</h6>
                  </TableCell>

                  <TableCell align="center">
                    <h6>
                      {requestCopy.digitalService && requestCopy.service
                        ? "3D Printing & 360 Visual"
                        : requestCopy.digitalService && !requestCopy.service
                        ? "360 Visual"
                        : !requestCopy.digitalService && requestCopy.service
                        ? "3D Printing"
                        : null}
                    </h6>
                  </TableCell>
                  <TableCell align="center">
                    <h6>{servicePrice}</h6>
                  </TableCell>
                  <TableCell align="center">
                    <h6>EGP {servicePrice}</h6>
                  </TableCell>
                </TableRow>
                {/*  */}
                <TableRow>
                  <TableCell style={{ border: "none" }}></TableCell>
                  <TableCell style={{ border: "none" }}></TableCell>

                  <TableCell align="center">
                    <h5>SubTotal</h5>
                  </TableCell>
                  <TableCell align="center">
                    <h6>EGP {servicePrice}</h6>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: "none" }}></TableCell>
                  <TableCell style={{ border: "none" }}></TableCell>
                  <TableCell align="center">
                    <h5>Discount</h5>
                  </TableCell>
                  <TableCell align="center">
                    <h6>{discount}</h6>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: "none" }}></TableCell>
                  <TableCell style={{ border: "none" }}></TableCell>
                  <TableCell align="center">
                    <h5>Total</h5>
                  </TableCell>
                  <TableCell align="center">
                    <h5>
                      EGP
                      {isNaN(parseFloat(servicePrice) - parseFloat(discount))
                        ? servicePrice
                        : parseFloat(servicePrice) - parseFloat(discount)}{" "}
                    </h5>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <div
            className={`text-center w-100 py-5 px-2 ${Styles.invoice_footer}`}
            style={{ position: "absolute", left: "0px", bottom: "10px" }}
          >
            <h2 className="mb-3">Thank you for your trust</h2>
            <h5>info@qubx3d.com</h5>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CopyRequest;
