import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpNonToken, endPoints } from "./../../globalConf/http-non-token";
import CustomToastify, { toastMes } from "../../globalConf/notification";

//Thunk get all Doctors
export const getDoctors = createAsyncThunk(
  "doctors/get",
  async ({ page, pageSize }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await httpNonToken.get(
        `${endPoints.doctors}?page=${page}&page_size=${pageSize}&order_by=-id`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getDoctorByMobile = createAsyncThunk(
  "doctors/getByMobile",
  async ({ page, pageSize, mobile }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await httpNonToken.get(
        `${endPoints.doctors}?page=${page}&page_size=${pageSize}&user__mobile__contains=${mobile}`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//Thunk create Doctors
export const addDoctor = createAsyncThunk(
  "doctor/create",
  async (doctorData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await fetch(
        "https://py.qubx3d.com/api/v1/user/doctor-rc/create",
        {
          method: "POST",
          body: JSON.stringify(doctorData),
          headers: { "content-type": "application/json" },
        }
      );
      const response = await res.json();
      return response;
    } catch (error) {
      // return rejectWithValue(error.response.message);
      alert(error.response.message);
    }
  }
);

//Thunk Edit Doctors
export const updateDoctor = createAsyncThunk(
  "doctor/update",
  async ({ doctorData, id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await fetch(
        `https://py.qubx3d.com/api/v1/doctorProfile/update/${id}`,
        {
          method: "PUT",
          body: JSON.stringify(doctorData, id),
          headers: { "content-type": "application/json" },
        }
      );
      const response = await res.json();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.message);
    }
  }
);

const initialState = {
  doctors: [],
  isLoading: false,
  isSuccess: false,
  error: null,
};
export const doctorSlice = createSlice({
  name: "doctor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDoctors.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDoctors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.doctors = action.payload;
      })
      .addCase(getDoctors.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getDoctorByMobile.pending, (state) => {
        // state.isLoading = true;
        state.error = null;
      })
      .addCase(getDoctorByMobile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.doctors = action.payload;
      })
      .addCase(getDoctorByMobile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(addDoctor.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addDoctor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.doctors.results.unshift(action.payload);
        toastMes.success("Print Provider Added Successfully");
        <CustomToastify
          bgColor={state.isSuccess ? "green" : "red"}
          duration={false}
        />;
      })
      .addCase(addDoctor.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(updateDoctor.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateDoctor.fulfilled, (state, action) => {
        state.doctors.results = [...state?.doctors.results].map((doctor) =>
          doctor.id === action.payload.id ? action.payload : doctor
        );
        state.isLoading = false;
        state.isSuccess = true;
        toastMes.success("Doctor Updated Successfully");
        <CustomToastify
          bgColor={state.isSuccess ? "green" : "red"}
          duration={false}
        />;
      })
      .addCase(updateDoctor.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default doctorSlice.reducer;
