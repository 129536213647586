import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import {
  httpNonToken,
  endPoints,
  basicUrl,
} from "../../../globalConf/http-non-token";
import Styles from "../requestTracking.module.css";
import { convertZipFile } from "../../../globalConf/zipping";
import axios from "axios";
import CustomToastify, { toastMes } from "../../../globalConf/notification";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import CopyRequest from "./copyRequest";

import {
  Tooltip,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Box,
  MenuItem,
  FormControl,
  Select,
  TextField,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Loading from "../../loading/loading";
import { useSelector, useDispatch } from "react-redux";
import { addNote } from "../../../features/note/noteSlice";
import { ServiceBgContext } from "../../../globalContext/bgContext";

import "../style.css";

const RequestTracking_360 = () => {
  const serviceBgContext = useContext(ServiceBgContext);
  const { bgFun } = serviceBgContext;

  let { id } = useParams();

  const { user } = useSelector((state) => state.login);
  let dataAuth = JSON.parse(localStorage.getItem("userOP"));

  const [request, setRequest] = useState("");
  const [allOperator, setAllOperator] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState("");
  const [allAnatomy, setAllAnatomy] = useState([]);
  const [selectedAnatomy, setSelectedAnatomy] = useState("");
  const [allPathology, setAllPathology] = useState([]);
  const [selectedPathology, setSelectedPathology] = useState("");
  const [selectedSms, setSelectedSms] = useState("");
  const [allRequestStatus, setAllRequestStatus] = useState([]);
  const [allSms, setAllSms] = useState([]);
  const [allNotes, setAllNotes] = useState([]);
  const [load, setLoad] = useState(true);
  const [success, setSuccess] = useState(true);
  const [loadFile, setLoadFile] = useState(false);
  const [updateStlFlag, setUpdateStlFlag] = useState(false);
  const [url, setUrl] = useState("");
  const [checked, setChecked] = useState();
  const [toggleChecked, setToggleChecked] = useState(false);
  const [body, setBody] = useState("");
  const [requests, setRequests] = useState(id);
  const [category, setCategory] = useState("");
  const [created_by, setCreated_by] = useState("");
  const [loadFileDicone, setLoadFileDicone] = useState(false);
  const [flag, setFlag] = useState(false);

  const [upload, setUpload] = useState(null);
  const [uploadDocument, setUploadDocument] = useState(null);
  const [uploadFiles, setUploadFiles] = useState(null);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (dataAuth) {
        try {
          const reqestData = await httpNonToken.get(
            `${endPoints.request}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${dataAuth.access}`,
              },
            }
          );
          const resStatus = await httpNonToken.get(
            `${endPoints.status360}?order_by=id`
          );
          const qubxOperatorData = await httpNonToken.get(
            `${endPoints.qubxOperator}?&page_size=1000`
          );
          const anatomyData = await httpNonToken.get(
            `${endPoints.anatomy}?&page_size=1000`
          );

          const pathologyData = await httpNonToken.get(
            `${endPoints.pathology}?&page_size=1000`
          );

          const smsData = await httpNonToken.get(`${endPoints.smsType}${id}/`);

          const NoteData = await httpNonToken.get(
            `${endPoints.notes}?requests=${id}`
          );

          await httpNonToken.post(`${endPoints.request}/${id}/readNotes`, {
            read: dataAuth.user.groups[0].toString(),
          });
          setCreated_by(dataAuth.user.id);
          setRequest(reqestData.data);
          setToggleChecked(reqestData.data.sketchfab);
          setAllOperator(qubxOperatorData.data.results);
          setAllAnatomy(anatomyData.data.results);
          setAllPathology(pathologyData.data.results);
          setAllSms(smsData.data);
          setAllNotes(NoteData.data);
          setChecked(reqestData.data.quality_assurence);
          setAllRequestStatus(resStatus.data);

          if (reqestData.data.assign_to) {
            setSelectedOperator(reqestData.data.assign_to.id);
          }
          if (reqestData.data.anatomy) {
            setSelectedAnatomy(reqestData.data.anatomy.id);
          }
          if (reqestData.data.pathology) {
            setSelectedPathology(reqestData.data.pathology.id);
          }
          if (Object.keys(reqestData.data.last_sms).length > 0) {
            setSelectedSms(reqestData.data.last_sms.sms_template.id);
          }
          setLoad(false);
        } catch ({ response }) {
          if (response.status === 401 && response.data.code) {
            localStorage.removeItem("userOP");
            navigate("/login");
            return;
          }
        }
      } else {
        navigate("/login");
      }
    })();
  }, [flag]);

  const navigate = useNavigate();

  const handleSendNote = (e, category) => {
    try {
      e.preventDefault();
      const data = { body, requests, category, created_by };
      dispatch(addNote(data));
      setBody("");
      setFlag(!flag);
    } catch (error) {}
  };

  const uploadFileAttach = async (e) => {
    // bgFun();
    let file = await convertZipFile(
      e.target.files,
      `request${request.id}`,
      `request${request.id}.zip`
    );
    let data = new FormData();
    data.append("attach", file);
    try {
      let updateRes = await axios.put(
        `${basicUrl}/request/update/${request.id}`,
        data,
        {
          onUploadProgress: (data) => {
            setUploadDocument(Math.round((data.loaded / data.total) * 100));
          },
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (updateRes.status === 200) {
        setRequest({
          ...request,
          ...updateRes.data,
        });
        setSuccess(true);
        toastMes.success(
          `Files in Request ${request.id} Uploaded Successfully`
        );
      }
    } catch ({ response }) {
      setSuccess(true);
      toastMes.error(response.data.message);
    }
    setUploadDocument(null);
  };

  const uploadFile = async (e) => {
    // bgFun();
    setLoadFileDicone(true);
    let file = await convertZipFile(
      e.target.files,
      `request${request.id}`,
      `request${request.id}.zip`
    );
    let data = new FormData();
    data.append("DICON_File", file);
    data.append("statu", 3);
    try {
      let updateRes = await axios.put(
        `${basicUrl}/request/update/${request.id}`,
        data,
        {
          onUploadProgress: (data) => {
            setUploadFiles(Math.round((data.loaded / data.total) * 100));
          },
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setLoadFileDicone(false);
      setRequest({
        ...request,
        ...updateRes.data,
      });
      setSuccess(true);
      toastMes.success(`Files in Request ${request.id} Uploaded Successfully`);
    } catch ({ response }) {
      setLoadFileDicone(false);
      setSuccess(false);
      toastMes.error(response.data.message);
    }
    setUploadFiles(null);
  };

  const sendUrl = async () => {
    let regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (!regexp.test(url)) {
      setSuccess(false);
      toastMes.error("Valid url is required");
      return;
    }
    try {
      const updateStatusRequestRes = await httpNonToken.put(
        `${endPoints.request}/update/${id}`,
        {
          DICON_URL: url,
          statu: 3,
        }
      );

      setRequest({
        ...request,
        ...updateStatusRequestRes.data,
      });
      setSuccess(true);
      toastMes.success(`URL Uploaded Successfully`);
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };

  const handleSirvChange = async (e) => {
    // bgFun();
    setLoadFile(true);
    let file = await convertZipFile(
      e.target.files,
      `request${request.id}`,
      `request${request.id}.zip`
    );
    let data = new FormData();
    data.append("file", file);
    try {
      const updateStatusRequestRes = await httpNonToken.post(
        `${endPoints.sirv}/${id}`,
        data,
        {
          onUploadProgress: (data) => {
            setUpload(Math.round((data.loaded / data.total) * 100));
          },
        }
      );
      if (
        updateStatusRequestRes.status === 200 &&
        updateStatusRequestRes.data.url === "error"
      ) {
        setSuccess(false);
        toastMes.error("Spin Creation Fail");
      } else {
        setSuccess(true);
        toastMes.success(
          `Images in Request ${request.id} Uploaded Successfully`
        );
      }
      setLoadFile(false);
    } catch ({ response }) {
      setLoadFile(false);
      setSuccess(false);
      toastMes.error(response.data.message);
    }
    setUpload(null);
    setFlag(!flag);
  };

  const deleteSirve = async (reqId) => {
    try {
      let data = new FormData();
      data.append("dirname", `request${reqId}`);
      data.append("requestId", reqId);
      const deleteSirvRes = await httpNonToken.post(
        `${endPoints.deleteSirve}`,
        data
      );

      if (deleteSirvRes.data.statusCode === 404) {
        setSuccess(false);
        toastMes.error(deleteSirvRes.data.message);
      } else {
        setSuccess(true);
        toastMes.success("Sirv Deleted Successfully");
      }
    } catch ({ response }) {}
    setFlag(!flag);
  };

  const handleChangeOperator = async (e) => {
    try {
      const updateReq = await httpNonToken.put(
        `${endPoints.updateRequest}/${id}`,
        {
          assign_to: e.target.value,
          statu: 5,
        }
      );
      setFlag(!flag);
      setSuccess(true);
      toastMes.success("Request Updates Successfully");
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
    setFlag(!flag);
    setSelectedOperator(e.target.value);
  };

  const handleChangeAnatomy = async (e) => {
    try {
      const updateReq = await httpNonToken.put(
        `${endPoints.updateRequest}/${id}`,
        {
          anatomy: e.target.value,
        }
      );
      setFlag(!flag);
      setSuccess(true);
      toastMes.success("Request Updates Successfully");
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
    setFlag(!flag);
  };

  const handleChangePathology = async (e) => {
    try {
      const updateReq = await httpNonToken.put(
        `${endPoints.updateRequest}/${id}`,
        {
          pathology: e.target.value,
        }
      );
      setFlag(!flag);
      setSuccess(true);
      toastMes.success("Request Updates Successfully");
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
    setFlag(!flag);
  };

  const handleChangeSMS = async (e) => {
    try {
      const updateReq = await httpNonToken.post(
        `${endPoints.sendMsg}/${id}/template/${e.target.value}`
      );
      if (updateReq.status === 200) {
        setSuccess(true);
        toastMes.success("Request Updates Successfully");
      }
    } catch (error) {
      setSuccess(false);
      toastMes.error(error.message);
    }
    setSelectedSms(e.target.value);
  };

  const handleChangeQualityStatus = async (e) => {
    try {
      const updateReq = await httpNonToken.put(
        `${endPoints.updateRequest}/${id}`,
        {
          quality_assurence: e.target.checked,
        }
      );
      if (updateReq.status === 200) {
        setFlag(!flag);
        setSuccess(true);
        toastMes.success("Request Updates Successfully");
      }
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
    setChecked(!checked);
  };

  const handleChangeToggle = async (e) => {
    setToggleChecked(!toggleChecked);
    try {
      const updateRequest = await httpNonToken.put(
        `${endPoints.updateRequest}/${id}`,
        {
          sketchfab: !toggleChecked,
        }
      );

      setSuccess(true);
      setFlag(!flag);
      toastMes.success("Request Updates Successfully.");
    } catch ({ response }) {
      setFlag(!flag);
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };
  const handleChange = async (event, id) => {
    try {
      let data = {};
      data.statu = event.target.value;
      if (event.target.value === 5) {
        data.assign_to = dataAuth.user.id;
      }
      const updateRequest = await httpNonToken.put(
        `${endPoints.updateRequest}/${id}`,
        data
      );

      setSuccess(true);
      setFlag(!flag);
      toastMes.success("Request Updates Successfully.");
    } catch ({ response }) {
      setFlag(!flag);
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };

  const handleStlChange = async (e) => {
    // bgFun();
    setLoadFile(true);
    let file = await convertZipFile(
      e.target.files,
      `request${request.id}`,
      `request${request.id}.zip`
    );
    let data = new FormData();
    data.append("file", file);
    data.append("name", `request${id}`);

    try {
      const updateStlReq = await httpNonToken.put(`${endPoints.stl}`, data, {
        onUploadProgress: (data) => {
          setUpload(Math.round((data.loaded / data.total) * 100));
        },
      });
      const sketchFabResponse = await axios.get(updateStlReq.data.message, {
        headers: {
          Authorization: "Token 4f6cba8e50334555a101b61260260a5c",
        },
      });

      const updateReq = await httpNonToken.put(
        `${endPoints.updateRequest}/${id}`,
        {
          result_URL: sketchFabResponse.data.embedUrl,
          statu: 8,
        }
      );
      setRequest({ ...request, ...updateReq.data });
      setSuccess(true);
      setLoadFile(false);
      toastMes.success(`Request ${request.id} Updates Successfully`);
    } catch ({ response }) {
      setLoadFile(false);
      setSuccess(false);
      toastMes.error(response.error.message);
    }
    setUpload(null);
  };

  const getLinkCopied = (code) => {
    navigator.clipboard.writeText(`https://view.qubx3d.com/${code}`);

    setSuccess(true);
    toastMes.success("Link Copied To Clipboard Successfully");
  };

  const handleStlUpdate = async (e, uid) => {
    // bgFun();
    setUpdateStlFlag(true);
    try {
      let file = await convertZipFile(
        e.target.files,
        `request${request.id}`,
        `request${request.id}.zip`
      );
      let data = new FormData();
      data.append("modelFile", file);
      data.append("uid", uid);

      const updateStlReq = await httpNonToken.put(
        `${endPoints.updateStl}`,
        data,
        {
          onUploadProgress: (data) => {
            setUpload(Math.round((data.loaded / data.total) * 100));
          },
        }
      );
      if (updateStlReq.status === 200) {
        setSuccess(true);
        toastMes.success(
          `SketchFab File in Request ${request.id} Updated Successfully`
        );
        setUpdateStlFlag(false);
      }
    } catch ({ response }) {
      toastMes.error(response.data.message);
      setUpdateStlFlag(false);
    }
    setUpload(null);
  };

  const deleteResultUrl = async (id) => {
    try {
      const updateRequest = await httpNonToken.put(
        `${endPoints.updateRequest}/${id}`,
        {
          result_URL: "",
          statu: 5,
          quality_assurence: false,
        }
      );

      setSuccess(true);
      setFlag(!flag);
      toastMes.success("Request Updates Successfully.");
    } catch ({ response }) {
      setFlag(!flag);
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };

  const getStyle = (id) => {
    let style = {};
    style.borderRadius = "0px";
    style.textAlign = "center";
    style.padding = "5px 25px";
    if (id === 2) {
      style.backgroundColor = "#FE9F44";
    } else if (id === 4) {
      style.backgroundColor = "#Ff4b4b";
    } else if (id === 5) {
      style.backgroundColor = "#77c3f6";
    } else if (id === 10) {
      style.backgroundColor = "#86b4b5";
    } else if (id === 11) {
      style.backgroundColor = "#F8a4a4";
    } else if (id === 12) {
      style.backgroundColor = "#D6e3a0";
    } else if (id === 13) {
      style.backgroundColor = "#A6efb5";
    } else if (id === 3) {
      style.backgroundColor = "#Feff6b";
    } else if (id === 6) {
      style.backgroundColor = "#Cbb1c7";
    } else if (id === 7) {
      style.backgroundColor = "#D5a4ff";
    } else if (id === 8) {
      style.backgroundColor = "#Cae3ff";
    } else if (id === 9) {
      style.backgroundColor = "#559b85";
    } else if (id === 14) {
      style.backgroundColor = "#E6e6e6";
    } else {
      style.backgroundColor = "lightgrey";
    }
    return style;
  };

  const getBgStyle = (data) => {
    let style = {};
    if (data) {
      style.backgroundColor = "#ddebff ";
    } else {
      style.backgroundColor = "white";
    }
    return style;
  };

  const copyRequestFun = async (val, requestCop) => {
    let newRequest = {
      doctor: request.doctor?.id,
      ...requestCop,
      statu: 2,
      anatomy: request.anatomy ? request.anatomy.id : null,
      pathology: request.pathology ? request.pathology.id : null,
      descraption: request.descraption,
      patient: request.patient.id,
      created_by,
    };

    if (requestCop.DICON_source === 2) {
      newRequest.statu = 3;
    }
    try {
      let requestResponse = {};
      if (newRequest.digitalService && newRequest.service) {
        await axios.post(`${basicUrl}/request/create`, newRequest);
        newRequest.service = newRequest.digitalService;
        newRequest.special_discount = 0.0;

        let requestRes = await axios.post(
          `${basicUrl}/request/create`,
          newRequest
        );
        requestResponse = requestRes.data;
      } else if (!newRequest.digitalService && newRequest.service) {
        let requestRes = await axios.post(
          `${basicUrl}/request/create`,
          newRequest
        );
        requestResponse = requestRes.data;
      } else if (newRequest.digitalService && !newRequest.service) {
        newRequest.service = newRequest.digitalService;
        let requestRes = await axios.post(
          `${basicUrl}/request/create`,
          newRequest
        );
        requestResponse = requestRes.data;
      }

      setSuccess(true);
      toastMes.success("Request Copied Successfully");
      return requestResponse;
    } catch ({ response }) {
      for (const key in response.data) {
        setSuccess(false);
        toastMes.error(`${key}: ${response.data[key]}`);
      }
    }
  };

  if (load) {
    return <Loading />;
  }
  return (
    <div className="bg_dark">
      <Header />
      <div className="bg_light p-2 rounded-top-20">
        <div className="d-flex justify-content-end">
          <Tooltip title="Copy Request">
            <i
              onClick={handleClickOpen}
              className="fa-solid fa-copy fa-2x text-primary my-1 px-5 cursor-pointer"
            ></i>
          </Tooltip>
          <div className="mx-1">
            <i className="fa-solid fa-folders fa-2x text-success"></i>
          </div>

          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  disabled={request.result_URL ? true : false}
                  checked={toggleChecked ? true : false}
                  value={toggleChecked ? true : false}
                  onChange={(e) => handleChangeToggle(e)}
                />
              }
              label="Sketch-Fab"
            />
          </FormGroup>
        </div>

        <div className="m-4 overflow-hidden">
          <div className="overflow-hidden fn_main fn_sm d-flex align-items-center">
            <div className="float-start me-2">
              <button
                onClick={() => navigate(-1)}
                className="fw-bold border-0 f-primary bg-transparent btn-back"
              >
                <ArrowBackIosNewIcon />
                <h5 className="float-end text-primary">{request.id}</h5>
              </button>
            </div>

            <div className="mb-1">
              <span>{new Date(request.created_at).toLocaleString()}, </span>
              <span>{request.created_by.fullname}, </span>
              <span className="text-uppercase">{request.branchName}</span>
            </div>
            <div
              className="me-2 mx-2 request-box-info"
              style={{ position: "relative" }}
            >
              <h6 style={getStyle(request.statu.id)}>{request.statu.name}</h6>
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                style={{ position: "absolute", top: "0px" }}
                size="small"
              >
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={request.statu.id}
                  label="Status"
                  onChange={(e) => handleChange(e, request.id)}
                >
                  {allRequestStatus.map((status, index) => (
                    <MenuItem key={index} value={status.id}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className={`col-lg-7 ms-3 ${Styles.info_boxes}`}>
              <div className="row">
                <div
                  className={`col text-center bg-white border border-primary me-3 ${Styles.info_box}`}
                >
                  <img
                    alt="patient card"
                    className="w-50 d-block mx-auto my-3"
                    src={"/images/global/nurse_patient.svg"}
                  />
                  <h6 className="mb-1">{request.patient.fullname}</h6>
                  <p className="mt-1" style={{ fontSize: "12px" }}>
                    {request.patient.mobile}
                  </p>
                </div>
                <div className="col text-center bg-white border border-primary me-3 info-box">
                  <img
                    alt="doctor card"
                    className="w-50 d-block mx-auto my-3"
                    src={"/images/global/medical_doctor.svg"}
                  />
                  <h6 className="mb-1">{request.doctor?.fullname}</h6>
                  <p className="mt-1" style={{ fontSize: "12px" }}>
                    {request.doctor?.mobile}
                  </p>
                </div>
                <div className="col text-center bg-white border border-primary me-3 info-box">
                  <img
                    alt="service card"
                    className="w-50 d-block mx-auto my-3"
                    src={"/images/global/360-printing.svg"}
                  />
                  <h6>{request.service.type.name}</h6>
                  <span>{request.service.name}</span>
                </div>
              </div>
              <div className="row mt-3">
                <div className={`col-lg-6 ${Styles.input_boxes}`}>
                  <Box sx={{ minWidth: 200 }}>
                    <FormControl className="w-100">
                      <InputLabel id="demo-simple-select-label">
                        Pathology
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Pathology"
                        name="pathology"
                        value={selectedPathology}
                        onChange={(e) => handleChangePathology(e)}
                      >
                        {allPathology &&
                          allPathology.map((pathology, index) => (
                            <MenuItem key={index} value={pathology.id}>
                              {pathology.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ minWidth: 200, marginTop: "15px" }}>
                    <FormControl className="w-100">
                      <InputLabel id="demo-simple-select-label">
                        Anatomy
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Anatomy"
                        name="anatomy"
                        value={selectedAnatomy}
                        onChange={(e) => handleChangeAnatomy(e)}
                      >
                        {allAnatomy &&
                          allAnatomy.map((anatomy, index) => (
                            <MenuItem key={index} value={anatomy.id}>
                              {anatomy.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ minWidth: 200, marginTop: "15px" }}>
                    <FormControl className="w-100">
                      <InputLabel id="demo-simple-select-label">
                        Qubx Operator
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Qubx Operator"
                        name="operator"
                        value={selectedOperator}
                        onChange={(e) => handleChangeOperator(e)}
                      >
                        {allOperator &&
                          allOperator.map((operator, index) => (
                            <MenuItem key={index} value={operator.user.id}>
                              {operator.user.fullname}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <div className="mt-3">
                    <Box sx={{ minWidth: 200 }}>
                      <FormControl className="w-100">
                        <InputLabel id="demo-simple-select-label">
                          SMS
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="SMS"
                          name="sms"
                          value={selectedSms}
                          onChange={(e) => handleChangeSMS(e)}
                        >
                          {allSms &&
                            allSms.map((sms, index) => (
                              <MenuItem key={index} value={sms.id}>
                                {sms.sms_type.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div className="form-check">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={request.statu.id !== 8}
                            onChange={(e) => handleChangeQualityStatus(e)}
                            checked={checked}
                          />
                        }
                        label="Quality Assurance"
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="col-lg-6">
                  <>
                    {toggleChecked ? (
                      <Box sx={{ minWidth: 200 }}>
                        <div className="d-flex justify-content-start">
                          <h5>360 visual URL</h5>
                          {request.result_URL ? (
                            <p>
                              <i
                                className="fa-solid fa-copy text-primary mx-2 fa-lg"
                                onClick={() => getLinkCopied(request.code)}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </p>
                          ) : (
                            ""
                          )}
                        </div>

                        {request.result_URL ? (
                          <div className="row">
                            <p
                              className="border border-primary h-4 mt-3 p-2 col-8"
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              <a
                                className="text-decoration-none"
                                href={`https://view.qubx3d.com/${request.code}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {`https://view.qubx3d.com/${request.code}`}
                              </a>
                            </p>
                            <span
                              className={`col-4${
                                request.result_URL ? " mt-4" : " mt-5"
                              }`}
                              style={{ position: "relative" }}
                            >
                              {updateStlFlag ? (
                                <i className="fa-solid fa-spinner text-primary fa-xl"></i>
                              ) : (
                                <>
                                  <i
                                    className="fa-solid fa-file-arrow-up text-primary fa-xl"
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                  <input
                                    disabled={request.statu.id < 5}
                                    onChange={(e) =>
                                      handleStlUpdate(
                                        e,
                                        request.result_URL.split("/")[4]
                                      )
                                    }
                                    accept=".zip,.rar,.stl,.ply,.obj,.gltf,.bin,.glb,.ply,.blend,.fbx,.3dc, .asc,.3ds,.abc,.dae, .zae,.igs, .iges,.las,.usd, .usdz, .usda, .usdc,.mtl"
                                    type="file"
                                    style={{
                                      position: "absolute",
                                      left: "10px",
                                      width: "20px",
                                      opacity: "0",
                                    }}
                                  />
                                  <i
                                    onClick={() => deleteResultUrl(request.id)}
                                    className="fa-solid fa-circle-xmark mx-2 fa-xl text-danger"
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                </>
                              )}
                            </span>
                          </div>
                        ) : (
                          <label
                            style={{
                              cursor: `${
                                request.statu.id < 5 ? "not-allowed" : "pointer"
                              }`,
                            }}
                            className={`fn_sm fn_light bg-primary py-1 px-4 my-1 rounded ${Styles.file_upload}`}
                          >
                            {loadFile ? (
                              <span>Loading ..</span>
                            ) : (
                              <>
                                Browse files
                                <input
                                  disabled={request.statu.id < 5}
                                  onChange={(e) => handleStlChange(e)}
                                  accept=".zip,.stl,.rar,.ply,.obj,.gltf,.bin,.glb,.ply,.blend,.fbx,.3dc, .asc,.3ds,.abc,.dae, .zae,.igs, .iges,.las,.usd, .usdz, .usda, .usdc,.mtl"
                                  type="file"
                                  className={`h-1 ${Styles.custom_file_input}`}
                                />
                              </>
                            )}
                          </label>
                        )}
                      </Box>
                    ) : (
                      <Box sx={{ minWidth: 200 }}>
                        <div className="d-flex justify-content-start">
                          <h5>Sirv file</h5>
                          {request.result_URL ? (
                            <p>
                              <i
                                className="fa-solid fa-copy text-primary mx-2 fa-lg"
                                onClick={() => getLinkCopied(request.code)}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        {request.result_URL ? (
                          <div className="d-flex justify-content-between">
                            <p
                              className="border border-primary w-100 h-4 mt-3 p-2"
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              <a
                                className="text-decoration-none"
                                href={`https://view.qubx3d.com/${request.code}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {request.result_URL === "error"
                                  ? "error"
                                  : `https://view.qubx3d.com/${request.code}`}
                              </a>
                            </p>
                            <i
                              onClick={() => deleteSirve(request.id)}
                              className="fa-solid fa-circle-xmark mx-2 fa-xl text-danger"
                              style={{ lineHeight: 3, cursor: "pointer" }}
                            ></i>
                          </div>
                        ) : (
                          <label
                            style={{
                              cursor: `${
                                request.statu.id < 5 ? "not-allowed" : "pointer"
                              }`,
                            }}
                            className={`fn_sm fn_light bg-primary py-1 px-4 my-1 rounded ${Styles.file_upload}`}
                          >
                            {loadFile ? (
                              <span>Loading ..</span>
                            ) : (
                              <>
                                {" "}
                                Browse files
                                <input
                                  disabled={request.statu.id < 5}
                                  onChange={(e) => handleSirvChange(e)}
                                  multiple
                                  type="file"
                                  className={`h-1 ${Styles.custom_file_input}`}
                                />
                              </>
                            )}
                          </label>
                        )}
                      </Box>
                    )}
                    {upload ? (
                      <div
                        className="progress mt-2 mb-2"
                        style={{ height: "0.5rem" }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuenow={upload}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${upload}%` }}
                        >
                          <span className="sr-only">{upload}</span>
                        </div>
                      </div>
                    ) : null}
                  </>
                  <div className="mt-5">
                    <ul className="list-unstyled">
                      <li className="d-flex align-items-center justify-content-between mb-3">
                        <h5>Price</h5>
                        <span>{request.price} Egp</span>
                      </li>
                      <li className="d-flex align-items-center justify-content-between border-bottom mb-3">
                        <h5>Discount</h5>
                        <span>{request.special_discount} Egp</span>
                      </li>
                      <li className="d-flex align-items-center justify-content-between mb-3">
                        <h5>Total</h5>
                        <span>
                          {request.price - request.special_discount} Egp
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                style={getBgStyle(request.attach)}
                className="border-primary border_dash rounded mb-3"
              >
                <ul className="list-unstyled p-1">
                  {request.attach ? (
                    <li className="mb-2 d-flex justify-content-between px-3">
                      <a
                        id="fileDownload"
                        href={request.attach}
                        target="_blank"
                        download
                        className="mt-2"
                        rel="noreferrer"
                      >
                        <span htmlFor="fileDownload">
                          {request.patient.fullname} documents
                        </span>
                      </a>
                      <a
                        id="fileDownload"
                        href={request.attach}
                        target="_blank"
                        download
                        style={{ fontSize: "14px" }}
                        className="mt-2 text-secondary font-weight-light text-decoration-none"
                        rel="noreferrer"
                      >
                        <img
                          alt="file download"
                          src={"/images/requestTracking/file-download.svg"}
                          className="w-30 ms-3"
                        />
                      </a>
                    </li>
                  ) : (
                    <div className="d-flex justify-content-between mt-2 mx-3">
                      <p>{request.patient.fullname} documents not available</p>
                      <div style={{ position: "relative" }}>
                        <input
                          type="file"
                          id="digitalFile360"
                          multiple
                          onChange={uploadFileAttach}
                          style={{
                            position: "absolute",
                            left: "0px",
                            opacity: 0,
                          }}
                        />
                        <img
                          alt="file download"
                          src="/images/requestTracking/Upload.png"
                          width="50px"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  )}
                </ul>
              </div>

              {uploadDocument ? (
                <div className="progress mb-3" style={{ height: "0.5rem" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={uploadDocument}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${uploadDocument}%` }}
                  >
                    <span className="sr-only">{uploadDocument}</span>
                  </div>
                </div>
              ) : null}

              {request.DICON_source.id !== 2 ? (
                <div
                  style={getBgStyle(request.DICON_File)}
                  className="border-primary border_dash rounded mb-3"
                >
                  <ul className="list-unstyled p-1">
                    {request.DICON_source.id === 3 ? (
                      <li className="mb-1 d-flex justify-content-between px-3 mt-3">
                        {request.DICON_File ? (
                          <a
                            id="fileDownload"
                            href={request.DICON_File}
                            target="_blank"
                            download
                            style={{ fontSize: "14px" }}
                            className="mt-2 text-secondary font-weight-light text-decoration-none"
                            rel="noreferrer"
                          >
                            <span htmlFor="fileDownload">
                              {request.patient.fullname} DICOM Files
                            </span>
                          </a>
                        ) : (
                          <span>DICOM Files Not available</span>
                        )}
                        {request.DICON_File ? (
                          <a
                            id="fileDownload"
                            href={request.DICON_File}
                            target="_blank"
                            download
                            rel="noreferrer"
                          >
                            <img
                              alt="file download"
                              src="/images/requestTracking/file-download.svg"
                              className="w-30 ms-3 "
                            />
                          </a>
                        ) : (
                          <div style={{ position: "relative" }}>
                            {loadFileDicone ? (
                              <img
                                src="/images/load.gif"
                                alt="upload"
                                width="50px"
                              />
                            ) : null}
                            <input
                              type="file"
                              id="digitalFile360"
                              multiple
                              onChange={uploadFile}
                              style={{
                                position: "absolute",
                                left: "0px",
                                opacity: 0,
                              }}
                            />
                            <img
                              alt="file download"
                              src="/images/requestTracking/Upload.png"
                              width="50px"
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        )}
                      </li>
                    ) : null}
                    {request.DICON_source.id === 4 ? (
                      <li className="mb-1 d-flex justify-content-between px-3 mt-3">
                        {request.DICON_URL ? (
                          <a
                            id="fileDownload"
                            href={request.DICON_URL}
                            target="_blank"
                            download
                            style={{ fontSize: "14px" }}
                            className="mt-2 text-secondary font-weight-light text-decoration-none"
                            rel="noreferrer"
                          >
                            <span htmlFor="fileDownload">
                              {request.patient.fullname} DICOM URL
                            </span>
                          </a>
                        ) : (
                          <TextField
                            label="URL"
                            id="outlined-size-small"
                            size="small"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                          />
                        )}
                        {request.DICON_URL ? (
                          <a
                            id="fileDownload"
                            href={request.DICON_File}
                            target="_blank"
                            download
                            rel="noreferrer"
                          >
                            <img
                              alt="file download"
                              src="/images/requestTracking/file-download.svg"
                              className="w-30 ms-3 "
                            />
                          </a>
                        ) : (
                          <div style={{ position: "relative" }}>
                            <Button variant="contained" onClick={sendUrl}>
                              Send
                            </Button>
                          </div>
                        )}
                      </li>
                    ) : null}
                  </ul>
                </div>
              ) : (
                ""
              )}

              {uploadFiles ? (
                <div
                  className="progress mt-2 mb-2"
                  style={{ height: "0.5rem" }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={uploadFiles}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${uploadFiles}%` }}
                  >
                    <span className="sr-only">{uploadFiles}</span>
                  </div>
                </div>
              ) : null}

              {request.descraption ? (
                <div>
                  <p>
                    <b>Case Description: </b>
                    {request.descraption}
                  </p>
                </div>
              ) : null}

              <div className="bg-wite">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Notes
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Doctor
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Qubx
                    </button>
                  </li>
                </ul>

                <div
                  className="tab-content bg-white overflow-scroll h_300"
                  id="myTabContent"
                >
                  {/**** First Tab ****/}
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    {allNotes &&
                      allNotes.map((note) =>
                        note.category.id === 2 ? (
                          <div className="box p-2" key={note.id}>
                            <div
                              className="info-msg me-3 text-end"
                              id={
                                note.created_by.groups.some((elem) =>
                                  dataAuth.user.groups.includes(elem)
                                )
                                  ? "sender"
                                  : ""
                              }
                            >
                              <span className="time d-block fn_xxs f-main">
                                {note.created_by.fullname}
                                {" - "}
                                {new Date(note.created_at).toLocaleString()}
                              </span>
                            </div>
                            <div className="content-msg p-2 mx-2 rounded bg_light fn_sm">
                              {note.body}
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      )}

                    <div className="p-2 text-center mx-2">
                      <textarea
                        className="border border-secondry rounded w-100"
                        onChange={(e) => setBody(e.target.value)}
                        value={body}
                      ></textarea>
                      <button
                        className="btn btn-primary float-end"
                        type="submit"
                        onClick={(e) => handleSendNote(e, 2)}
                      >
                        send
                      </button>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    {allNotes &&
                      allNotes.map((note) =>
                        note.category.id === 4 ? (
                          <div className="box p-2" key={note.id}>
                            <div
                              className="info-msg me-3 text-end"
                              id={
                                note.created_by.groups.some((elem) =>
                                  dataAuth.user.groups.includes(elem)
                                )
                                  ? "sender"
                                  : ""
                              }
                            >
                              <span className="time d-block fn_xxs f-main">
                                {note.created_by.fullname}
                                {" - "}
                                {new Date(note.created_at).toLocaleString()}
                              </span>
                            </div>
                            <div className="content-msg p-2 mx-2 rounded bg_light fn_sm">
                              {note.body}
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    <div className="p-2 text-center mx-2">
                      <textarea
                        className="border border-secondry rounded w-100"
                        onChange={(e) => setBody(e.target.value)}
                        value={body}
                      ></textarea>
                      <button
                        className="btn btn-primary float-end"
                        onClick={(e) => handleSendNote(e, 4)}
                      >
                        send
                      </button>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    {allNotes &&
                      allNotes.map((note) =>
                        note.category.id === 3 ? (
                          <div className="box p-2" key={note.id}>
                            <div
                              className="info-msg me-3 text-end"
                              id={
                                note.created_by.groups.some((elem) =>
                                  dataAuth.user.groups.includes(elem)
                                )
                                  ? "sender"
                                  : ""
                              }
                            >
                              <span className="time d-block fn_xxs f-main">
                                {note.created_by.fullname}
                                {" - "}
                                {new Date(note.created_at).toLocaleString()}
                              </span>
                            </div>
                            <div className="content-msg p-2 mx-2 rounded bg_light fn_sm">
                              {note.body}
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    <div className="p-2 text-center mx-2">
                      <textarea
                        className="border border-secondry rounded w-100"
                        onChange={(e) => setBody(e.target.value)}
                        value={body}
                      ></textarea>
                      <button
                        className="btn btn-primary float-end"
                        type="submit"
                        onClick={(e) => handleSendNote(e, 3)}
                      >
                        send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CopyRequest
        request={request}
        open={open}
        handleClose={handleClose}
        copyRequestFun={copyRequestFun}
      />
      {/* <CustomToastify bgColor={success ? "green" : "red"} duration={false} /> */}
    </div>
  );
};

export default RequestTracking_360;
