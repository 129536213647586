import "./App.css";
import { useRef, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ChangePassword from "./components/changePassword/ChangePassword";
import Login from "./components/login/Login";
import Home from "./components/home/Home";
import RequestTracking_3D from "./components/requestTracking/requestTrackingPrinting/RequestTracking_3D";
import RequestTracking_360 from "./components/requestTracking/requestTrackingPrinting/RequestTracking_360";
import RadiologyCenter from "./components/radiologyCenter/RadiologyCenter";
import ControlPanel from "./components/controlPanel/ControlPanel";
import Patients from "./components/patient/Patients";
import Staff from "./components/staff/Staff";
import Doctors from "./components/doctor/Doctors";
import Footer from "./components/footer/Footer";
import PrintingProvider from "./components/printing/PrintingProvider";
import ShippingCourier from "./components/shippingCourier/ShippingCourier";
import StatisticPage from "./components/statisticPage/StatisticPage";
import Error from "./components/error/Error";
import { useSelector } from "react-redux";

function App() {
  const { user } = useSelector((state) => state.login);
  const footerRef = useRef(null);

  return (
    <>
      <div className="App" style={{ minHeight: "86vh" }}>
        <Routes>
          <Route exact path="/" element={<StatisticPage />} />

          <Route
            exact
            path="/reset-password/:id"
            element={<ChangePassword />}
          />
          <Route exact path="/admin-panel" element={<ControlPanel />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/requests" element={<Home />} />
          <Route exact path="/staff" element={<Staff />} />
          <Route
            exact
            path="/request_3D/:id"
            element={<RequestTracking_3D />}
          />
          <Route
            exact
            path="/request_360/:id"
            element={<RequestTracking_360 />}
          />
          <Route exact path="/rc" element={<RadiologyCenter />} />
          <Route exact path="/patients" element={<Patients />} />
          <Route exact path="/doctors" element={<Doctors />} />
          <Route
            exact
            path="/printing-provider"
            element={<PrintingProvider />}
          />
          <Route exact path="/shipping-courier" element={<ShippingCourier />} />
          <Route path="/not-found" element={<Error />} />
          <Route path="*" element={<Navigate replace to="/not-found" />} />
        </Routes>
      </div>
      <Footer footerRef={footerRef} />
    </>
  );
}

export default App;
