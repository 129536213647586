import JSZip from "jszip";

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const convertZipFile = async (files, folderName, fileName) => {
  let zip = new JSZip();
  let dirname = zip.folder(folderName);
  for (let i = 0; i < files.length; i++) {
    let base64 = await convertBase64(files[i]);
    dirname.file(files[i].name, base64.split("base64,")[1], {
      base64: true,
    });
  }

  let file = await zip.generateAsync({ type: "blob" }).then((content) => {
    return content;
  });
  file = new File([file], fileName, {
    lastModified: new Date(),
    type: "application/zip",
  });
  return file;
};