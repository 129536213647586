import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";

export const getGovernorates = createAsyncThunk(
  "governorates/get",
  async () => {
    try {
      const res = await httpNonToken.get(`${endPoints.governorate}`);
      return res.data;
    } catch (error) {
    }
  }
);

export const getRegions = createAsyncThunk("Regions/get", async () => {
  try {
    const res = await httpNonToken.get(`${endPoints.region}`);
    return res.data;
  } catch (error) {
  }
});

const initialState = {
  governorates: [],
  regions: [],
};

export const governorateSlice = createSlice({
  name: "governorate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGovernorates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.governorates = action.payload.results;
      })
      .addCase(getGovernorates.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getRegions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.regions = action.payload.results;
      })
      .addCase(getRegions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default governorateSlice.reducer;
