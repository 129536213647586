import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "./styles/main.css";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ServiceBgProvider } from "./globalContext/bgContext";

import { Provider } from "react-redux";
import store from "../src/redux/store";

// ReactDOM.render(

//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
//   document.getElementById("root")
// );

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ServiceBgProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ServiceBgProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
