import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import CustomToastify, { toastMes } from "../../globalConf/notification";

// thunk for get Courier
export const getCourier = createAsyncThunk(
  "courier/get",
  async ({ page, pageSize }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const res = await httpNonToken.get(
        `${endPoints.courier}?page=${page}&page_size=${pageSize}`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCourierByMobile = createAsyncThunk(
  "courier/getByMobile",
  async ({ page, pageSize, phone }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const res = await httpNonToken.get(
        `${endPoints.courier}?page=${page}&page_size=${pageSize}&phone__contains=${phone}`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// thunk for add Courier
export const addCourier = createAsyncThunk(
  "courier/create",
  async (courierData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const res = await fetch("https://py.qubx3d.com/api/v1/courier/create", {
        method: "POST",
        body: JSON.stringify(courierData),
        headers: { "content-type": "application/json" },
      });
      const response = await res.json();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCourier = createAsyncThunk(
  "courier/update",
  async ({ courierData, id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await fetch(
        `https://py.qubx3d.com/api/v1/courier/update/${id}`,
        {
          method: "PUT",
          body: JSON.stringify(courierData, id),
          headers: { "content-type": "application/json" },
        }
      );
      const response = await res.json();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const initialState = {
  couriers: [],
  isLoading: false,
  isSuccess: false,
  error: null,
};
export const courierSlice = createSlice({
  name: "courier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCourier.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCourier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.couriers = action.payload;
      })
      .addCase(getCourier.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getCourierByMobile.pending, (state) => {
        // state.isLoading = true;
        state.error = null;
      })
      .addCase(getCourierByMobile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.couriers = action.payload;
      })
      .addCase(getCourierByMobile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(addCourier.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addCourier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.couriers.results.unshift(action.payload);
        toastMes.success("Shipping Courier Added Successfully");
        <CustomToastify
          bgColor={state.isSuccess ? "green" : "red"}
          duration={false}
        />;
      })
      .addCase(addCourier.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(updateCourier.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCourier.fulfilled, (state, action) => {
        state.couriers.results = [...state?.couriers.results].map((courier) =>
          courier.id === action.payload.id ? action.payload : courier
        );
        state.isLoading = false;
        state.isSuccess = true;
        toastMes.success("Shipping Courier Updated Successfully");
        <CustomToastify
          bgColor={state.isSuccess ? "green" : "red"}
          duration={false}
        />;
      })
      .addCase(updateCourier.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default courierSlice.reducer;
