import React, { Fragment, useEffect, useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
  Button,
  MenuItem,
  FormControl,
  Select,
  TextField,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Switch,
  Backdrop,
  Fade,
  Modal,
} from "@mui/material";

import { IoMdSearch, IoMdAddCircle } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import Header from "../header/Header";
import Loading from "../loading/loading";
import {
  addPrint,
  getPrints,
  getPrintByMobile,
  updatePrint,
} from "../../features/print/printSlice";
import { getGovernorates } from "../../features/governorate/governorateSlice";
import CustomToastify, { toastMes } from "../../globalConf/notification";

const PrintingProvider = () => {
  let data = {};
  data = JSON.parse(localStorage.getItem("userOP"));
  const { prints, isLoading, isSuccess, error } = useSelector(
    (state) => state.print
  );
  const { governorates } = useSelector((state) => state.governorate);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [addEditFlag, setAddEditFlag] = useState(true);
  const [selectedPrint, setSelectedPrint] = useState("");
  const [selectedPrintInfo, setSelectedPrintInfo] = useState("");
  const [searchByMobile, setSearchByMobile] = useState("");

  const [name, setName] = useState("");
  const [mainStore, setMainStore] = useState("");
  const [manager_name, setManager_name] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [governorate, setGovernorate] = useState("");
  const [printingStatus, setPrintingStatus] = useState(false);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [checkedProviderStatus, setCheckedProviderStatus] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setName("");
    setMainStore("");
    setManager_name("");
    setAddress("");
    setMobile("");
    setPhone("");
    setGovernorate("");
    setPrintingStatus(false);
  };

  useEffect(() => {
    (async () => {
      if (data) {
        if (data.user.groups.includes(2)) {
          let printingData = await dispatch(getPrints({ page, pageSize }));
          dispatch(getGovernorates());

          let checkedProviderArr = [...checkedProviderStatus];
          for (let i = 0; i < printingData.payload.results.length; i++) {
            checkedProviderArr.push(
              printingData.payload.results[i].printingStatus
            );
          }
          setCheckedProviderStatus(checkedProviderArr);
        } else {
          navigate("/");
        }
      } else {
        navigate("/login");
      }
    })();
  }, [page, pageSize, flag]);

  const handlePageChange = (pageNum) => {
    setPage(pageNum);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !mainStore ||
      !manager_name ||
      !address ||
      !mobile ||
      !phone ||
      !governorate
    ) {
      toastMes.error("All Fields required");
      return;
    } else {
      const data = {
        name,
        mainStore,
        manager_name,
        address,
        mobile,
        phone,
        governorate,
        printingStatus,
      };
      try {
        await httpNonToken.post(`${endPoints.createPrinting}`, data);
        handleClose();
        setFlag(!flag);
      } catch ({ response }) {
        setSuccess(false);
        toastMes.error(response.data.message);
      }
    }
  };
  const handleSelected = (id) => {
    setAddEditFlag(false);
    setSelectedPrint(id);
    const selectedPrintInfo = prints.results.find(
      (printInfo) => printInfo.id === id
    );
    setSelectedPrintInfo(selectedPrintInfo);

    setName(selectedPrintInfo.name);
    setMainStore(selectedPrintInfo.mainStore);
    setManager_name(selectedPrintInfo.manager_name);
    setAddress(selectedPrintInfo.address);
    setMobile(selectedPrintInfo.mobile);
    setPhone(selectedPrintInfo.phone);
    setGovernorate(selectedPrintInfo.governorate.id);
    setPrintingStatus(selectedPrintInfo.printingStatus);
  };

  const handleChangeChecked = (e, val) => {
    setPrintingStatus(val);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = {
      name,
      mainStore,
      manager_name,
      address,
      mobile,
      phone,
      governorate,
      printingStatus,
    };
    if (
      !name ||
      !mainStore ||
      !manager_name ||
      !address ||
      !mobile ||
      !phone ||
      !governorate
    ) {
      toastMes.error("All Fields required");
      return;
    } else {
      const data = {
        name,
        mainStore,
        manager_name,
        address,
        mobile,
        phone,
        governorate,
        printingStatus,
      };
      try {
        await httpNonToken.put(
          `${endPoints.updatePrinting}/${selectedPrint}`,
          data
        );
        handleClose();
        setFlag(!flag);
      } catch ({ response }) {
        setSuccess(false);
        toastMes.error(response.data.message);
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchByMobile(e.target.value);
    dispatch(getPrintByMobile({ page, pageSize, mobile: e.target.value }));
  };

  const handleChange = async (event, id, position = 0) => {
    let data = {};
    const updatedCheckedState = checkedProviderStatus.map((item, index) => {
      if (index === position) data.printingStatus = !item;
      return index === position ? !item : item;
    });
    setCheckedProviderStatus(updatedCheckedState);

    try {
      await httpNonToken.put(`${endPoints.updatePrinting}/${id}`, data);
      setSuccess(true);
      toastMes.success("Printing Provider Updated Successfully");
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      <Header />
      <TableContainer component={Paper} className="w_90 mt-4 m-auto">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div>
            <h3 className="text-start mx-3 mt-4 float-start">Print Provider</h3>
            <TextField
              id="outlined-basic"
              label="Search By Phone Number"
              variant="outlined"
              size="small"
              value={searchByMobile}
              onChange={handleSearch}
              className="float-start mt-4 me-3"
            />
          </div>
          <div>
            <Button>
              <IoMdAddCircle
                className="fs-3 me-3 mt-3 fn_primary cursor-pointer"
                onClick={() => {
                  setAddEditFlag(true);
                  handleOpen();
                }}
              />
            </Button>
          </div>
        </div>
        <Table sx={{ minWidth: 450 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#dbdce0" }}>
            <TableRow>
              <TableCell>Store Name</TableCell>
              <TableCell>Main Store Group Owner</TableCell>
              <TableCell>Store Manager</TableCell>
              <TableCell>Store Address</TableCell>
              <TableCell>Store Phone Number</TableCell>
              <TableCell>Store LandLine</TableCell>
              <TableCell>governorate</TableCell>
              <TableCell>Store Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prints.results
              ? prints.results.map((printProvider, index) => (
                  <TableRow key={printProvider.id}>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {printProvider.name}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {printProvider.mainStore}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {printProvider.manager_name}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {printProvider.address}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {printProvider.mobile}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {printProvider.phone}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {printProvider.governorate.name}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              name="printingStatus"
                              checked={checkedProviderStatus[index]}
                              onChange={(e) =>
                                handleChange(e, printProvider.id, index)
                              }
                            />
                          }
                          label=""
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      <Button>
                        <MdModeEdit
                          className="fs-3 me-3 mt-3 fn_primary cursor-pointer"
                          onClick={() => {
                            handleOpen();
                            handleSelected(printProvider.id);
                          }}
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {prints.results?.length > 0 ? (
          <div className="paginate-box d-flex justify-content-end p-2">
            <Box>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pageSize}
                  label=""
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <button
              disabled={!prints.previous}
              className="m-2 border-0 bg-transparent"
              onClick={() => handlePageChange(1)}
            >
              <Tooltip title={`First Page: 1`}>
                <i className="	fas fa-angle-double-left	"></i>
              </Tooltip>
            </button>
            <button
              onClick={() => handlePageChange(prints.page_number - 1)}
              disabled={!prints.previous}
              className="m-2 mx-3 border-0 bg-transparent"
            >
              <Tooltip title={`Previous Page: ${prints.page_number - 1}`}>
                <i className="fas fa-chevron-left"></i>
              </Tooltip>
            </button>
            <p className="m-2 mt-3">{`${prints.from}-${prints.to}  of  ${prints.count}`}</p>

            <button
              className="m-2 mx-3 border-0 bg-transparent"
              disabled={!prints.next}
              onClick={() => handlePageChange(prints.page_number + 1)}
            >
              <Tooltip title={`Next Page: ${prints.page_number + 1}`}>
                <i className="fas fa-chevron-right"></i>
              </Tooltip>
            </button>
            <button
              className="m-2 border-0 bg-transparent"
              disabled={!prints.next}
              onClick={() => handlePageChange(prints.total_pages)}
            >
              <Tooltip title={`Last Page: ${prints.total_pages}`}>
                <i className="	fas fa-angle-double-right	"></i>
              </Tooltip>
            </button>
          </div>
        ) : (
          ""
        )}
      </TableContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="row">
            <h5 className="col-12">
              {addEditFlag ? "Add New Print Provider" : "Edit Print Provider"}
            </h5>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                id="outlined-basic"
                label="Store Name"
                variant="outlined"
                size="small"
                className="me-3 mb-3"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                id="outlined-basic"
                label="Main Store"
                variant="outlined"
                size="small"
                className="me-3 mb-3"
                value={mainStore}
                onChange={(e) => setMainStore(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                id="outlined-basic"
                label="Manager Name"
                variant="outlined"
                size="small"
                className="me-3 mb-3"
                value={manager_name}
                onChange={(e) => setManager_name(e.target.value)}
              />
            </div>

            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                id="outlined-basic"
                label="Address"
                variant="outlined"
                size="small"
                className="me-3 mb-3"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>

            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                id="outlined-basic"
                label="Mobile"
                variant="outlined"
                size="small"
                className="me-3 mb-3"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                size="small"
                className="me-3 mb-3"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="col-6 mt-2">
              <FormControl sx={{ minWidth: "100%" }} className="me-4">
                <InputLabel id="governorate-label">Governorate</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Governorate"
                  value={governorate}
                  onChange={(e) => setGovernorate(e.target.value)}
                  // MenuProps={{ disablePortal: true }}
                >
                  {governorates.map((governorate, index) => (
                    <MenuItem key={index} value={governorate.id}>
                      {governorate.name}
                    </MenuItem>
                  ))}
                  )
                </Select>
              </FormControl>
            </div>

            {/* <div className="col-6 mt-2 w-100">
              <Checkbox
                checked={printingStatus}
                onChange={handleChangeChecked}
              />
            </div> */}
            <div className="col-6 offset-6 mt-2">
              {addEditFlag ? (
                <button
                  className="btn btn-primary mt-2 w-100"
                  onClick={handleSubmit}
                >
                  Add Printing Center
                </button>
              ) : (
                <button
                  className="btn btn-primary mt-2 w-100"
                  onClick={handleUpdate}
                >
                  Edit Printing Center
                </button>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* <CustomToastify bgColor={success ? "green" : "red"} duration={false} /> */}
    </div>
  );
};

export default PrintingProvider;
