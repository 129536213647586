import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Checkbox,
  InputLabel,
  Button,
  Modal,
  Backdrop,
  Fade,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { IoMdAddCircle } from "react-icons/io";
import { MdModeEdit, MdDelete } from "react-icons/md";
import Header from "../header/Header";
import Loading from "../loading/loading";

import Footer from "../footer/Footer";
import CustomToastify, { toastMes } from "../../globalConf/notification";
import {
  getRadiologyCenter,
  getRcGroups,
} from "./../../features/radiologyCenter/radiologyCenterSlice";
import { getGovernorates } from "./../../features/governorate/governorateSlice";
import { getDiconSources } from "./../../features/diconSource/diconSourceSlice";

const RadiologyCenter = () => {
  let data = {};
  data = JSON.parse(localStorage.getItem("userOP"));
  const { radiologyCenters, rcGroups, isLoading, isSuccess, error } =
    useSelector((state) => state.radiologyCenter);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [addEditFlag, setAddEditFlag] = useState(true);
  const [flag, setFlag] = useState(true);
  const [selectedRCenter, setSelectedRCenter] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [open, setOpen] = useState(false);
  const [rcgOpen, setRCGOpen] = useState(false);

  const [fullname, setfullname] = useState("");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [governorate, setGovernorate] = useState("");
  const [rc_group, setRc_group] = useState("");
  const [dicon_source, setDicon_source] = useState("");
  const [modalities_ct, setModalities_ct] = useState("");
  const [discount, setDiscount] = useState("");
  const [rcgData, setRCGData] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      name: "",
      discount: "",
      hotline: "",
    }
  );

  const [modalities_mri, setModalities_mri] = useState(false);
  const [modalities_mar, setModalities_mar] = useState(false);

  const { governorates } = useSelector((state) => state.governorate);
  const { diconSources } = useSelector((state) => state.diconSource);

  const [checkedModalitiesMri, setCheckedModalitiesMri] = useState([]);
  const [checkedModalitiesMar, setCheckedModalitiesMar] = useState([]);
  const [success, setSuccess] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleRCGOpen = () => setRCGOpen(true);
  const handleRCGClose = () => {
    setRCGOpen(false);
    setRCGData({
      name: "",
      discount: "",
      hotline: "",
    });
  };
  const handleClose = () => {
    setOpen(false);
    setfullname("");
    setMobile("");
    setName("");
    setPhone("");
    setAddress("");
    setDiscount("");
    setGovernorate("");
    setRc_group("");
    setDicon_source("");
    setModalities_ct("");
    setModalities_mri(false);
    setModalities_mar(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (data) {
        if (data.user.groups.includes(2)) {
          let radiologyCentersData = await dispatch(
            getRadiologyCenter({ page, pageSize })
          );
          dispatch(getGovernorates());
          dispatch(getDiconSources());
          dispatch(getRcGroups());
          //
          let modalitiesMriArr = [...checkedModalitiesMri];
          let modalitiesMarArr = [...checkedModalitiesMar];
          for (
            let i = 0;
            i < radiologyCentersData.payload.results.length;
            i++
          ) {
            modalitiesMriArr.push(
              radiologyCentersData.payload.results[i].modalities_mri
            );
          }
          for (
            let i = 0;
            i < radiologyCentersData.payload.results.length;
            i++
          ) {
            modalitiesMarArr.push(
              radiologyCentersData.payload.results[i].modalities_mar
            );
          }
          setCheckedModalitiesMri(modalitiesMriArr);
          setCheckedModalitiesMar(modalitiesMarArr);
        } else {
          navigate("/");
        }
      } else {
        navigate("/login");
      }
    })();
  }, [page, pageSize, flag]);

  const handlePageChange = (pageNum) => {
    setPage(pageNum);
  };
  if (isLoading) {
    return <Loading />;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !fullname ||
      !mobile ||
      !name ||
      !phone ||
      !address ||
      !governorate ||
      !rc_group ||
      !dicon_source ||
      !modalities_ct ||
      !discount
    ) {
      setSuccess(false);
      toastMes.error("All Fields are required");
      return;
    }
    const data = {
      user: { fullname, mobile },
      name,
      phone,
      address,
      governorate,
      rc_group,
      dicon_source,
      modalities_ct,
      modalities_mri,
      modalities_mar,
      discount,
    };
    try {
      await httpNonToken.post(`${endPoints.createBranch}`, data);
      setSuccess(true);
      toastMes.success("Radiology Center Created Successfully");
      handleClose();
      setFlag(!flag);
      setfullname("");
      setMobile("");
      setName("");
      setPhone("");
      setAddress("");
      setGovernorate("");
      setDiscount("");
      setRc_group("");
      setDicon_source("");
      setModalities_ct("");
      setModalities_mri(false);
      setModalities_mar(false);
    } catch ({ response }) {
      for (const key in response.data) {
        if (key === "user") {
          for (const inKey in response.data[key]) {
            setSuccess(false);
            toastMes.error(`${key}-${inKey}: ${response.data[key][inKey]}`);
          }
        } else {
          setSuccess(false);
          toastMes.error(`${key}: ${response.data[key]}`);
        }
      }
    }
  };

  const handleSelected = async (id, userID) => {
    setAddEditFlag(false);
    setSelectedRCenter(id);
    setSelectedUser(userID);
    try {
      const getBranchRes = await httpNonToken.get(
        `${endPoints.getBranch}${id}`
      );
      setfullname(getBranchRes.data.user.fullname);
      setMobile(getBranchRes.data.user.mobile);
      setName(getBranchRes.data.name);
      setPhone(getBranchRes.data.phone);
      setDiscount(getBranchRes.data.discount);
      setAddress(getBranchRes.data.address);
      setGovernorate(getBranchRes.data.governorate);
      setRc_group(getBranchRes.data.rc_group);
      setDicon_source(getBranchRes.data.dicon_source);
      setModalities_ct(getBranchRes.data.modalities_ct);
      setModalities_mri(getBranchRes.data.modalities_mri);
      setModalities_mar(getBranchRes.data.modalities_mar);
      handleOpen();
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };

  const handleRCGSubmit = async () => {
    if (!rcgData.name) {
      toastMes.error("Radiology Center Group Name is Required");
      return;
    }
    try {
      await httpNonToken.post(`${endPoints.createRCG}`, rcgData);
      toastMes.success("Radiology Center Group Created Successfully");
      setFlag(!flag);
      handleRCGClose();
    } catch (err) {
      toastMes.error(err.message);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (
      !fullname ||
      !mobile ||
      !name ||
      !phone ||
      !address ||
      !governorate ||
      !rc_group ||
      !dicon_source ||
      !modalities_ct ||
      !discount
    ) {
      setSuccess(false);
      toastMes.error("All Fields are required");
      return;
    }

    const data = {
      user: { fullname, mobile },
      name,
      phone,
      address,
      governorate,
      rc_group,
      dicon_source,
      modalities_ct,
      modalities_mri,
      modalities_mar,
      discount,
    };

    try {
      await httpNonToken.put(
        `${endPoints.updateUser}/${selectedUser}/`,
        data.user
      );
      delete data.user;
      await httpNonToken.put(
        `${endPoints.updateRcBranch}/${selectedRCenter}`,
        data
      );
      setFlag(!flag);
      setfullname("");
      setMobile("");
      setName("");
      setPhone("");
      setAddress("");
      setGovernorate("");
      setRc_group("");
      setDiscount("");
      setDicon_source("");
      setModalities_ct("");
      setModalities_mri(false);
      setModalities_mar(false);

      setSuccess(true);
      toastMes.success("Request Updated Successfully");
      handleClose();
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };

  const handleChange = async (event, id, position = 0) => {
    let data = {};
    if (event.target.name === "modalities_mri") {
      const updatedCheckedState = checkedModalitiesMri.map((item, index) => {
        if (index === position) data.modalities_mri = !item;
        return index === position ? !item : item;
      });
      setCheckedModalitiesMri(updatedCheckedState);
    }
    if (event.target.name === "modalities_mar") {
      const updatedCheckedState = checkedModalitiesMar.map((item, index) => {
        if (index === position) data.modalities_mar = !item;
        return index === position ? !item : item;
      });
      setCheckedModalitiesMar(updatedCheckedState);
    }
    try {
      const updateRequest = await httpNonToken.put(
        `${endPoints.updateRcBranch}/${id}`,
        data
      );
      setSuccess(true);
      toastMes.success("Request Updated Successfully");
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Header />
      <TableContainer component={Paper} className="w_90 mt-4 m-auto">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h3 className="text-start ms-3 mt-3">Radiology Center</h3>
          <div>
            <Button onClick={handleRCGOpen}>
              <span>Add New Radiology Center Group</span>
            </Button>
            <span className="d-inline-block mx-3">/</span>
            <Button onClick={handleOpen}>
              <span
                // className="fs-3 me-3 mt-3 fn_primary cursor-pointer"
                onClick={() => setAddEditFlag(true)}
              >
                Add New Radiology Center
              </span>
            </Button>
          </div>
        </div>
        <Table sx={{ minWidth: 450 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#dbdce0" }}>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>DICOM Source</TableCell>
              <TableCell>Main Group Name</TableCell>
              <TableCell>modalities_ct</TableCell>
              {/* <TableCell>Branch Name</TableCell>
                <TableCell>LandLine</TableCell> */}
              <TableCell>modalities_mri</TableCell>
              <TableCell>modalities_mar</TableCell>
              <TableCell colSpan={2} className="text-center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {radiologyCenters.results
              ? radiologyCenters?.results.map((branch, index) => (
                  <TableRow key={branch.id}>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {branch.user.fullname}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {branch.user.mobile}
                    </TableCell>

                    <TableCell sx={{ padding: "2px 14px" }}>
                      {branch.name}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {branch.phone}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {branch.address}
                    </TableCell>
                    <TableCell>
                      {branch.governorate ? branch.governorate.name : null}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {branch.dicon_source.name}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {branch.rc_group.name}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {branch.modalities_ct}
                    </TableCell>

                    <TableCell sx={{ padding: "2px 14px" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              name="modalities_mri"
                              checked={checkedModalitiesMri[index]}
                              onChange={(e) =>
                                handleChange(e, branch.id, index)
                              }
                            />
                          }
                          label=""
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              name="modalities_mar"
                              checked={checkedModalitiesMar[index]}
                              onChange={(e) =>
                                handleChange(e, branch.id, index)
                              }
                            />
                          }
                          label=""
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell
                      sx={{ padding: "2px 14px" }}
                      className="d-flex justify-content-between"
                    >
                      <Button>
                        <MdModeEdit
                          className="fs-3 me-3 mt-3 fn_primary cursor-pointer"
                          onClick={() =>
                            handleSelected(branch.id, branch.user.id)
                          }
                        />
                      </Button>{" "}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {radiologyCenters.results?.length > 0 ? (
          <div className="paginate-box d-flex justify-content-end p-2 fn_xs">
            <Box>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  className="fn_xs mt-2"
                  id="demo-simple-select"
                  value={pageSize}
                  label=""
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <button
              disabled={!radiologyCenters.previous}
              className="m-2 border-0 bg-transparent"
              onClick={() => handlePageChange(1)}
            >
              <Tooltip title={`First Page: 1`}>
                <i className="	fas fa-angle-double-left	"></i>
              </Tooltip>
            </button>
            <button
              onClick={() => handlePageChange(radiologyCenters.page_number - 1)}
              disabled={!radiologyCenters.previous}
              className="m-2 mx-3 border-0 bg-transparent"
            >
              <Tooltip
                title={`Previous Page: ${radiologyCenters.page_number - 1}`}
              >
                <i className="fas fa-chevron-left"></i>
              </Tooltip>
            </button>
            <p className="m-2 mt-3">{`${radiologyCenters.from}-${radiologyCenters.to}  of  ${radiologyCenters.count}`}</p>

            <button
              className="m-2 mx-3 border-0 bg-transparent"
              disabled={!radiologyCenters.next}
              onClick={() => handlePageChange(radiologyCenters.page_number + 1)}
            >
              <Tooltip title={`Next Page: ${radiologyCenters.page_number + 1}`}>
                <i className="fas fa-chevron-right"></i>
              </Tooltip>
            </button>
            <button
              className="m-2 border-0 bg-transparent"
              disabled={!radiologyCenters.next}
              onClick={() => handlePageChange(radiologyCenters.total_pages)}
            >
              <Tooltip title={`Last Page: ${radiologyCenters.total_pages}`}>
                <i className="	fas fa-angle-double-right	"></i>
              </Tooltip>
            </button>
          </div>
        ) : (
          ""
        )}
      </TableContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="row">
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {addEditFlag
                ? "Add New Radiology Center"
                : "Edit Radiology Center"}
            </Typography>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                value={fullname}
                type="text"
                id="outlined-basic"
                label="Admin Full Name"
                variant="outlined"
                size="small"
                className="me-3 mb-3"
                onChange={(e) => setfullname(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                value={mobile}
                type="text"
                id="outlined-basic"
                label=" Admin Mobile"
                variant="outlined"
                size="small"
                className="me-3 mb-3"
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                value={name}
                type="text"
                id="outlined-basic"
                label="Branch Name"
                variant="outlined"
                size="small"
                className="me-3 mb-3"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                id="outlined-basic"
                label="Branch Phone Number"
                variant="outlined"
                size="small"
                value={phone}
                className="me-3 mb-3"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                value={address}
                id="outlined-basic"
                label="Branch Address"
                variant="outlined"
                size="small"
                className="me-3 mb-3"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label-gov">
                    Governorate
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-gov"
                    id="demo-simple-select"
                    value={governorate}
                    label="Governorate"
                    onChange={(e) => setGovernorate(e.target.value)}
                  >
                    {governorates?.map((governorate, index) => (
                      <MenuItem key={index} value={governorate.id}>
                        {governorate.name}
                      </MenuItem>
                    ))}
                    )
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-6 mt-2">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label-rc">
                    RC Group
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-rc"
                    id="demo-simple-select"
                    label="Governorate"
                    value={rc_group}
                    onChange={(e) => setRc_group(e.target.value)}
                  >
                    {rcGroups?.map((rcGroup, index) => (
                      <MenuItem key={index} value={rcGroup.id}>
                        {rcGroup.name}
                      </MenuItem>
                    ))}
                    )
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-6 mt-2">
              <FormControl sx={{ minWidth: "100%" }} className="me-4">
                <InputLabel id="demo-simple-select-label-dicon">
                  DICOM Source
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-dicon"
                  id="demo-simple-select"
                  label="DICOM Source"
                  value={dicon_source}
                  onChange={(e) => setDicon_source(e.target.value)}
                >
                  {diconSources?.map((diconSource, index) => (
                    <MenuItem key={index} value={diconSource.id}>
                      {diconSource.name}
                    </MenuItem>
                  ))}
                  )
                </Select>
              </FormControl>
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="number"
                id="outlined-basic"
                label=" modalities_ct"
                variant="outlined"
                size="small"
                value={modalities_ct}
                className="me-3 mb-3"
                onChange={(e) => setModalities_ct(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="number"
                id="outlined-basic"
                label="Discount"
                variant="outlined"
                size="small"
                value={discount}
                className="me-3 mb-3"
                onChange={(e) => setDiscount(e.target.value)}
              />
            </div>

            {/* <div className="col-6 mt-2">
                <div className="row">
                  <FormControlLabel
                    className="col-6"
                    control={
                      <Checkbox
                        checked={modalities_mri}
                        onChange={handleChangeCheckedModalities_mri}
                      />
                    }
                    label="modalities_mri"
                  />
                  <FormControlLabel
                    className="col-6"
                    control={
                      <Checkbox
                        checked={modalities_mar}
                        onChange={handleChangeCheckedModalities_mar}
                      />
                    }
                    label="modalities_mri"
                  />
                </div>
              </div> */}
            <div className="col-6 offset-6">
              {addEditFlag ? (
                <button
                  className="btn btn-primary mt-2 w-100"
                  onClick={handleSubmit}
                >
                  Add Radiology Center
                </button>
              ) : (
                <button
                  className="btn btn-primary mt-2 w-100"
                  onClick={handleUpdate}
                >
                  Edit Radiology Center
                </button>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
      {/*  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={rcgOpen}
        onClose={handleRCGClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={rcgOpen}>
          <Box sx={style} className="row">
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add New Radiology Center Group
            </Typography>

            <div className="col-12 mt-3">
              <TextField
                fullWidth
                value={rcgData.name}
                type="text"
                id="outlined-basic"
                label="Name"
                variant="outlined"
                size="small"
                className="me-3 mb-3"
                onChange={(e) => setRCGData({ name: e.target.value })}
              />
            </div>

            <div className="col-12 mt-2">
              <TextField
                fullWidth
                type="text"
                value={rcgData.hotline}
                id="outlined-basic"
                label="Hot-Line"
                variant="outlined"
                size="small"
                className="me-3 mb-3"
                onChange={(e) => setRCGData({ hotline: e.target.value })}
              />
            </div>

            <div className="col-12 mt-2">
              <TextField
                fullWidth
                type="number"
                id="outlined-basic"
                label="Discount"
                variant="outlined"
                size="small"
                value={rcgData.discount}
                className="me-3 mb-3"
                onChange={(e) => setRCGData({ discount: e.target.value })}
              />
            </div>

            <div className="col-7 offset-5">
              <button
                className="btn btn-primary mt-2 w-100"
                onClick={handleRCGSubmit}
              >
                Add Radiology Center Group
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* <CustomToastify bgColor={success ? "green" : "red"} duration={false} /> */}
    </div>
  );
};

export default RadiologyCenter;
