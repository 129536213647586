import { createSlice } from "@reduxjs/toolkit";
const user = JSON.parse(localStorage.getItem("userOP"));

// export const operatorLogin = createAsyncThunk(
//   "operator/login",
//   async (user, thunkAPI) => {
//     const { rejectWithValue } = thunkAPI;
//     try {
//       const res = await fetch("https://py.qubx3d.com/api/v1/login", {
//         method: "POST",
//         body: JSON.stringify(user),
//         headers: { "content-type": "application/json" },
//       });
//       const response = await res.json();
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

const initialState = {
  user: user ? user : null,
  isLoading: false,
  isAuth: false,
  error: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginPending: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state) => {
      // user = user.user;
      state.isLoading = false;
      state.isAuth = true;
      state.error = "";
    },
    loginFail: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
  // extraReducers: (builder) => {
  //   //get all print
  //   builder
  //     .addCase(operatorLogin.pending, (state) => {
  //       state.isLoading = true;
  //       state.error = null;
  //     })
  //     .addCase(operatorLogin.fulfilled, (state, action) => {
  //       state.isLoading = false;
  //       state.isSuccess = true;
  //       state.prints = action.payload;
  //     })
  //     .addCase(operatorLogin.rejected, (state, action) => {
  //       state.isLoading = false;
  //       state.error = action.payload;
  //     });

  // },
});

const { reducer, actions } = loginSlice;

export const { loginPending, loginSuccess, loginFail } = actions;
export default reducer;
// export default loginSlice.reducer;
