import React, { Fragment, useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
  Button,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Divider,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Backdrop,
  Fade,
  Modal,
} from "@mui/material";

import { IoMdAddCircle } from "react-icons/io";
import Header from "../header/Header";
import Loading from "../loading/loading";
import CustomToastify, { toastMes } from "../../globalConf/notification";

const Staff = () => {
  let data = {};
  data = JSON.parse(localStorage.getItem("userOP"));

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [operators, setOperators] = useState([]);
  const [operatorsStatus, setOperatorsStatus] = useState([]);
  const [operatorsAdministration, setOperatorsAdministration] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [userData, setUserData] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      fullname: "",
      mobile: "",
      address: "",
      national_id: "",
    }
  );

  const [open, setOpen] = React.useState(false);
  const [editFlag, setEditFlag] = React.useState(false);
  const [selectedOperator, setSelectedOperator] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [success, setSuccess] = useState(false);
  const [flag, setFlag] = useState(false);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    (async () => {
      if (data) {
        if (
          JSON.parse(localStorage.getItem("userOP")).user.groups.includes(2)
        ) {
          try {
            let operatorsRes = await httpNonToken.get(
              `${endPoints.qubxOperator}?page=${page}&page_size=${pageSize}${
                searchValue ? `&user__mobile__contains=${searchValue}` : ""
              }`
            );
            setOperators(operatorsRes.data);
            let operatorStatusArr = [];
            let operatorAdministrationArr = [];
            for (let i = 0; i < operatorsRes.data.results.length; i++) {
              operatorStatusArr.push(
                operatorsRes.data.results[i].user.is_active
              );
              operatorAdministrationArr.push(
                operatorsRes.data.results[i].user.groups.includes(2)
                  ? true
                  : false
              );
            }
            setOperatorsStatus(operatorStatusArr);
            setOperatorsAdministration(operatorAdministrationArr);
            setLoad(false);
          } catch (error) {
            toastMes.error(error.message);
          }
        } else {
          navigate("/");
        }
      } else {
        navigate("/login");
      }
    })();
  }, [page, pageSize, flag, searchValue]);

  const handleDataChange = () => {};

  const handlePageChange = (pageNum) => {
    setPage(pageNum);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUserData({
      fullname: "",
      mobile: "",
      address: "",
      national_id: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let { fullname, mobile, national_id, address } = userData;
    if (!fullname || !mobile) {
      toastMes.success("User Data is Required");
      return;
    }
    let data = {
      user: { fullname, mobile },
      national_id,
      address,
    };
    try {
      if (editFlag) {
        await httpNonToken.put(
          `${endPoints.updateUser}/${selectedUser}/`,
          data.user
        );
        delete data.user;
        await httpNonToken.put(
          `${endPoints.updateEmployee}${selectedOperator}`,
          data
        );
        toastMes.success("Operator Updated Successfully");
      } else {
        await httpNonToken.post(`${endPoints.employeeCreate}`, data);
        toastMes.success("Operator Created Successfully");
      }
      handleClose();
      setFlag(!flag);
    } catch ({ response }) {
      for (const key in response.data) {
        if (key === "user") {
          for (const inKey in response.data[key]) {
            toastMes.error(`${key}-${inKey}: ${response.data[key][inKey]}`);
          }
        } else {
          toastMes.error(`${key}: ${response.data[key]}`);
        }
      }
    }
  };

  const handleStatusChange = async (e, id, position = 0) => {
    let data = {};
    if (e.target.name === "status") {
      const updatedCheckedState = operatorsStatus.map((item, index) => {
        if (index === position) data.is_active = !item;
        return index === position ? !item : item;
      });
      setOperatorsStatus(updatedCheckedState);
    } else if (e.target.name === "admin") {
      const operatorsAdministrationUpdate = operatorsAdministration.map(
        (item, index) => {
          if (index === position) {
            data.groups = !item;
            data.groups = data.groups ? [2] : [5];
          }
          return index === position ? !item : item;
        }
      );
      setOperatorsAdministration(operatorsAdministrationUpdate);
    }
    try {
      let response = await httpNonToken.put(
        `${endPoints.updateUser}/${id}/`,
        data
      );
      setFlag(!flag);
      toastMes.success("Operator Updated Successfully");
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  const handleSelected = async (id, userID) => {
    setEditFlag(true);
    setSelectedOperator(id);
    setSelectedUser(userID);
    try {
      const getOpRes = await httpNonToken.get(
        `${endPoints.qubxOperator}/${id}`
      );
      setUserData({
        fullname: getOpRes.data.user.fullname,
        mobile: getOpRes.data.user.mobile,
        national_id: getOpRes.data.national_id,
        address: getOpRes.data.address,
      });
      handleClickOpen();
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  if (load) {
    return <Loading />;
  }
  return (
    <div>
      <Header />
      <TableContainer component={Paper} className="w_90 mt-4 m-auto">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div>
            <h3 className="text-start mx-3 mt-4 float-start">Qubx Staff</h3>
            <TextField
              id="outlined-basic"
              label="Search By Phone Number"
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className="float-start mt-4"
            />
          </div>
          <div>
            <Button>
              <IoMdAddCircle
                onClick={handleClickOpen}
                className="fs-3 me-3 mt-3 fn_primary cursor-pointer"
              />
            </Button>
          </div>
        </div>
        <Table sx={{ minWidth: 450 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#dbdce0" }}>
            <TableRow>
              <TableCell>Employee Id</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>National Id</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Hiring Date</TableCell>
              <TableCell>Employee Type</TableCell>
              <TableCell>Employee Status</TableCell>
              <TableCell>Administration</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {operators && operators.results.length > 0
              ? operators.results.map((operator, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: "2px 14px" }}>
                        {operator.id}
                      </TableCell>
                      <TableCell sx={{ padding: "2px 14px" }}>
                        {operator.user.fullname}
                      </TableCell>
                      <TableCell sx={{ padding: "2px 14px" }}>
                        {operator.national_id}
                      </TableCell>
                      <TableCell sx={{ padding: "2px 14px" }}>
                        {operator.user.mobile}
                      </TableCell>
                      <TableCell sx={{ padding: "2px 14px" }}>
                        {operator.address}
                      </TableCell>
                      <TableCell sx={{ padding: "2px 14px" }}>
                        {new Date(operator.user.created_at).toLocaleString()}
                      </TableCell>
                      <TableCell sx={{ padding: "2px 14px" }}>
                        {operator.user.groups.includes(2)
                          ? "Admin"
                          : "Operator"}
                      </TableCell>
                      <TableCell sx={{ padding: "2px 14px" }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                name="status"
                                checked={operatorsStatus[index]}
                              />
                            }
                            label=""
                            onChange={(e) =>
                              handleStatusChange(e, operator.user.id, index)
                            }
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell sx={{ padding: "2px 14px" }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                name="admin"
                                checked={operatorsAdministration[index]}
                              />
                            }
                            label=""
                            onChange={(e) =>
                              handleStatusChange(e, operator.user.id, index)
                            }
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>
                        <i
                          onClick={() =>
                            handleSelected(operator.id, operator.user.id)
                          }
                          className="fa-solid fa-pen fa-lg text-primary"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
        {operators.results?.length > 0 ? (
          <div className="paginate-box d-flex justify-content-end p-2">
            <Box>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pageSize}
                  label=""
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <button
              disabled={!operators.previous}
              className="m-2 border-0 bg-transparent"
              onClick={() => handlePageChange(1)}
            >
              <Tooltip title={`First Page: 1`}>
                <i className="fas fa-angle-double-left"></i>
              </Tooltip>
            </button>
            <button
              onClick={() => handlePageChange(operators.page_number - 1)}
              disabled={!operators.previous}
              className="m-2 mx-3 border-0 bg-transparent"
            >
              <Tooltip title={`Previous Page: ${operators.page_number - 1}`}>
                <i className="fas fa-chevron-left"></i>
              </Tooltip>
            </button>
            <p className="m-2 mt-3">{`${operators.from}-${operators.to}  of  ${operators.count}`}</p>

            <button
              className="m-2 mx-3 border-0 bg-transparent"
              disabled={!operators.next}
              onClick={() => handlePageChange(operators.page_number + 1)}
            >
              <Tooltip title={`Next Page: ${operators.page_number + 1}`}>
                <i className="fas fa-chevron-right"></i>
              </Tooltip>
            </button>
            <button
              className="m-2 border-0 bg-transparent"
              disabled={!operators.next}
              onClick={() => handlePageChange(operators.total_pages)}
            >
              <Tooltip title={`Last Page: ${operators.total_pages}`}>
                <i className="	fas fa-angle-double-right	"></i>
              </Tooltip>
            </button>
          </div>
        ) : (
          ""
        )}
      </TableContainer>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="row">
            <Typography variant="h5" gutterBottom component="div">
              {editFlag ? "Edit Operator" : "Add Operator"}
            </Typography>
            <Divider sx={{ width: "50%", margin: "left" }} />
            <div className="row">
              <div className="col-6 mt-4">
                <TextField
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                  value={userData.fullname}
                  onChange={(e) => setUserData({ fullname: e.target.value })}
                />
              </div>
              <div className="col-6 mt-4">
                <TextField
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label="Mobile Number"
                  variant="outlined"
                  value={userData.mobile}
                  onChange={(e) => setUserData({ mobile: e.target.value })}
                />
              </div>
              <div className="col-6 mt-4">
                <TextField
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  value={userData.address}
                  onChange={(e) => setUserData({ address: e.target.value })}
                />
              </div>
              <div className="col-6 mt-4">
                <TextField
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label="National Id"
                  variant="outlined"
                  onChange={(e) => setUserData({ national_id: e.target.value })}
                  value={userData.national_id}
                />
              </div>
              <div className="col-12 d-flex justify-content-end mt-4">
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  className="mx-2"
                  color="error"
                >
                  Close
                </Button>
                <Button variant="outlined" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* <CustomToastify bgColor={success ? "green" : "red"} duration={false} /> */}
    </div>
  );
};

export default Staff;
