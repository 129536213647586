import React, { useEffect, useState } from "react";

import { httpNonToken, endPoints } from "../../../globalConf/http-non-token";
import "../style.css";

export default function SelectDoctor({
  setRequestCopy,
  setDoctorFlag,
  requestCopy,
  request,
}) {
  const [doctor, setDoctor] = useState("1");
  const [doctors, setDoctors] = useState([]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const doctorsRes = await httpNonToken.get(
          `${endPoints.doctors}?page_size=2000`
        );
        if (isMounted) {
          let doctorsResArr = Array.from(doctorsRes.data.results, (doc) => {
            return { label: doc.user.fullname, id: doc.id };
          });
          setDoctors(doctorsResArr);
        }
      } catch ({ response }) {}
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleChange = (event) => {
    setDoctor(event.target.value);
    if (event.target.value == 1) {
      setRequestCopy({ doctor: request.doctor?.id });
    } else {
      setRequestCopy({ doctor: 238 });
    }
  };

  return (
    <div className="doctor-container">
      <div className="middle">
        <label>
          <input
            type="radio"
            onChange={handleChange}
            value={1}
            name="radio"
            checked={doctor == 1}
          />
          <div className="front-end box">
            <span>Same Doctor</span>
          </div>
        </label>

        <label>
          <input
            type="radio"
            onChange={handleChange}
            value={2}
            checked={doctor == 2}
            name="radio"
          />
          <div className="back-end box">
            <span>Qubx Doctor</span>
          </div>
        </label>
      </div>
    </div>
  );
}
