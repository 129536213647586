import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import Loading from "../loading/loading";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Typography,
  InputLabel,
  Modal,
  Backdrop,
  Fade,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { IoMdSearch, IoMdAddCircle } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import Style from "./doctor.module.css";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import CustomToastify, { toastMes } from "../../globalConf/notification";
import {
  getDoctors,
  addDoctor,
  updateDoctor,
  getDoctorByMobile,
} from "./../../features/doctor/doctorSlice";
import {
  getGovernorates,
  getRegions,
} from "./../../features/governorate/governorateSlice";

const Doctors = () => {
  let data = {};
  data = JSON.parse(localStorage.getItem("userOP"));
  const { doctors, isLoading, isSuccess, error } = useSelector(
    (state) => state.doctor
  );
  const { governorates, regions } = useSelector((state) => state.governorate);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [addEditFlag, setAddEditFlag] = useState(true);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedDoctorInfo, setSelectedDoctorInfo] = useState("");
  const [searchByMobile, setSearchByMobile] = useState("");
  const [open, setOpen] = useState(false);
  const [doctorsStatus, setDoctorsStatus] = useState([]);

  //Data Doctor
  const [fullname, setFullname] = useState("");
  const [mobile, setMobile] = useState("");
  const [assistance_phone, setAssistance_phone] = useState("");
  const [clinic_name, setClinic_name] = useState("");
  const [clinic_address, setClinic_address] = useState("");
  const [clinic_contact, setClinic_contact] = useState("");
  const [region, setRegion] = useState("");
  const [governorate, setGovernorate] = useState("");
  const [success, setSuccess] = useState(true);
  const [flag, setFlag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFullname("");
    setMobile("");
    setAssistance_phone("");
    setClinic_name("");
    setClinic_address("");
    setClinic_contact("");
    setRegion("");
    setGovernorate("");
  };

  useEffect(() => {
    (async () => {
      if (data) {
        if (data.user.groups.includes(2)) {
          let doctorsRes = await dispatch(getDoctors({ page, pageSize }));
          dispatch(getGovernorates());
          dispatch(getRegions());
          let doctorStatusArr = [];
          for (let i = 0; i < doctorsRes.payload.results.length; i++) {
            doctorStatusArr.push(doctorsRes.payload.results[i].user.is_active);
          }
          setDoctorsStatus(doctorStatusArr);
        } else {
          navigate("/");
        }
      } else {
        navigate("/login");
      }
    })();
  }, [flag]);

  const handlePageChange = (pageNum) => {
    setPage(pageNum);
    setFlag(!flag);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !fullname ||
      !mobile ||
      !assistance_phone ||
      !clinic_name ||
      !clinic_address ||
      !clinic_contact ||
      !region ||
      !governorate
    ) {
      setSuccess(false);
      toastMes.error("All Fields are required");
      return;
    }
    const data = {
      user: { fullname, mobile },
      assistance_phone,
      clinic_name,
      clinic_address,
      clinic_contact,
      region,
      governorate,
    };

    try {
      const createDoctor = await httpNonToken.post(
        `${endPoints.createDoctor}`,
        data
      );
      setSuccess(true);
      toastMes.success("Doctor Created Successfully");
      handleClose();
      setFlag(!flag);
      setFullname("");
      setMobile("");
      setAssistance_phone("");
      setClinic_name("");
      setClinic_address("");
      setClinic_contact("");
      setRegion("");
      setGovernorate("");
    } catch ({ response }) {
      for (const key in response.data) {
        if (key === "user") {
          for (const inKey in response.data[key]) {
            setSuccess(false);
            toastMes.error(`${key}-${inKey}: ${response.data[key][inKey]}`);
          }
        } else {
          setSuccess(false);
          toastMes.error(`${key}: ${response.data[key]}`);
        }
      }
    }
  };
  const handleSelected = (id, userId) => {
    setAddEditFlag(false);
    setSelectedDoctor(id);
    setSelectedUser(userId);
    const selectedDoctorInfo = doctors.results.find(
      (doctorInfo) => doctorInfo.id === id
    );
    setSelectedDoctorInfo(selectedDoctorInfo);
    setFullname(selectedDoctorInfo.user.fullname);
    setMobile(selectedDoctorInfo.user.mobile);
    setAssistance_phone(selectedDoctorInfo.assistance_phone);
    setClinic_name(selectedDoctorInfo.clinic_name);
    setClinic_address(selectedDoctorInfo.clinic_address);
    setClinic_contact(selectedDoctorInfo.clinic_contact);
    if (selectedDoctorInfo.governorate)
      setGovernorate(selectedDoctorInfo.governorate.id);
    if (selectedDoctorInfo.governorate && selectedDoctorInfo.governorate.region)
      setRegion(selectedDoctorInfo.governorate.region.id);
    handleOpen();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = {
      user: { fullname, mobile },
      assistance_phone,
      clinic_name,
      clinic_address,
      clinic_contact,
      governorate,
      region,
    };
    if (!fullname || !mobile || !governorate || !region) {
      setSuccess(false);
      toastMes.error("All Fields are required");
      return;
    }
    try {
      let res = await httpNonToken.put(
        `${endPoints.updateUser}/${selectedUser}/`,
        data.user
      );
      delete data.user;
      await httpNonToken.put(
        `${endPoints.updateDoctor}/${selectedDoctor}`,
        data
      );
      setFlag(!flag);
      handleClose();
    } catch ({ response }) {
      for (const key in response.data) {
        if (key === "user") {
          for (const inKey in response.data[key]) {
            setSuccess(false);
            toastMes.error(`${key}-${inKey}: ${response.data[key][inKey]}`);
          }
        } else {
          setSuccess(false);
          toastMes.error(`${key}: ${response.data[key]}`);
        }
      }
    }
  };

  const handleStatusChange = async (id, position = 0) => {
    let data = {};
    const updatedCheckedState = doctorsStatus.map((item, index) => {
      if (index === position) data.is_active = !item;
      return index === position ? !item : item;
    });
    setDoctorsStatus(updatedCheckedState);
    try {
      let res = await httpNonToken.put(`${endPoints.updateUser}/${id}/`, data);
      if (res.status === 200) {
        toastMes.success("Doctor Updated Successfully");
      }
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchByMobile(e.target.value);
    dispatch(getDoctorByMobile({ page, pageSize, mobile: e.target.value }));
  };

  if (isLoading) {
    return <Loading />;
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <Header />
      <TableContainer component={Paper} className="w_90 mt-4 m-auto">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div>
            <h2 className="text-start mx-3 mt-4 float-start">Doctor</h2>
            <TextField
              id="outlined-basic"
              label="Search By Phone Number"
              variant="outlined"
              size="small"
              value={searchByMobile}
              onChange={handleSearch}
              className="float-start mt-4 me-3"
            />
          </div>
          <div>
            <Button onClick={handleOpen}>
              <IoMdAddCircle
                className="fs-3 me-3 mt-3 fn_primary cursor-pointer"
                onClick={() => setAddEditFlag(true)}
              />
            </Button>
          </div>
        </div>
        <Table sx={{ minWidth: 450 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#dbdce0" }}>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Phone Number(other)</TableCell>
              <TableCell>Clinic Name</TableCell>
              <TableCell>Clinic Address</TableCell>
              <TableCell>Clinic Contact</TableCell>
              <TableCell>Government</TableCell>
              <TableCell>Region</TableCell>
              <TableCell>Activation</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {doctors.results
              ? doctors.results.map((doctor, index) => (
                  <TableRow key={doctor.id}>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {doctor.user.fullname}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {doctor.user.mobile}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {doctor.assistance_phone}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {doctor.clinic_name}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {doctor.clinic_address}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {doctor.clinic_contact}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {doctor.governorate ? doctor.governorate.name : null}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      {doctor.governorate
                        ? doctor.governorate.region.name
                        : null}
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              name="status"
                              checked={doctorsStatus[index]}
                            />
                          }
                          label=""
                          onChange={() =>
                            handleStatusChange(doctor.user.id, index)
                          }
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell sx={{ padding: "2px 14px" }}>
                      <Button>
                        <MdModeEdit
                          className="fs-3 me-3 mt-3 fn_primary cursor-pointer"
                          onClick={() =>
                            handleSelected(doctor.id, doctor.user.id)
                          }
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {doctors?.results?.length > 0 ? (
          <div className="paginate-box d-flex justify-content-end p-2">
            <Box>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pageSize}
                  label=""
                  onChange={(e) => {
                    setPageSize(e.target.value);
                    setFlag(!flag);
                  }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <button
              disabled={!doctors.previous}
              className="m-2 border-0 bg-transparent"
              onClick={() => handlePageChange(1)}
            >
              <Tooltip title={`First Page: 1`}>
                <i className="	fas fa-angle-double-left	"></i>
              </Tooltip>
            </button>
            <button
              onClick={() => handlePageChange(doctors.page_number - 1)}
              disabled={!doctors.previous}
              className="m-2 mx-3 border-0 bg-transparent"
            >
              <Tooltip title={`Previous Page: ${doctors.page_number - 1}`}>
                <i className="fas fa-chevron-left"></i>
              </Tooltip>
            </button>
            <p className="m-2 mt-3">{`${doctors.from}-${doctors.to}  of  ${doctors.count}`}</p>

            <button
              className="m-2 mx-3 border-0 bg-transparent"
              disabled={!doctors.next}
              onClick={() => handlePageChange(doctors.page_number + 1)}
            >
              <Tooltip title={`Next Page: ${doctors.page_number + 1}`}>
                <i className="fas fa-chevron-right"></i>
              </Tooltip>
            </button>
            <button
              className="m-2 border-0 bg-transparent"
              disabled={!doctors.next}
              onClick={() => handlePageChange(doctors.total_pages)}
            >
              <Tooltip title={`Last Page: ${doctors.total_pages}`}>
                <i className="	fas fa-angle-double-right	"></i>
              </Tooltip>
            </button>
          </div>
        ) : (
          ""
        )}
      </TableContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="row">
            <div className="col-12 mt-2">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {addEditFlag ? "Add New Doctor" : "Edit Doctor"}
              </Typography>
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                id="outlined-basic"
                label="Full name"
                variant="outlined"
                size="small"
                className="me-3 mb-3 mt-3"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                id="outlined-basic"
                label="Mobile"
                variant="outlined"
                size="small"
                className="me-3 mb-3 mt-3"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                size="small"
                className="me-3 mb-3 mt-3"
                value={assistance_phone}
                onChange={(e) => setAssistance_phone(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                id="outlined-basic"
                label="Clinic name"
                variant="outlined"
                size="small"
                className="me-3 mb-3 mt-3"
                value={clinic_name}
                onChange={(e) => setClinic_name(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                id="outlined-basic"
                label="Clinic Address"
                variant="outlined"
                size="small"
                className="me-3 mb-3 mt-3"
                value={clinic_address}
                onChange={(e) => setClinic_address(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <TextField
                fullWidth
                type="text"
                id="outlined-basic"
                label="Clinic Contact"
                variant="outlined"
                size="small"
                className="me-3 mb-3 mt-3"
                value={clinic_contact}
                onChange={(e) => setClinic_contact(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <FormControl sx={{ minWidth: "100%" }} className="me-4">
                <InputLabel id="governorate-label">Governorate</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Governorate"
                  value={governorate}
                  onChange={(e) => setGovernorate(e.target.value)}
                >
                  {governorates?.map((governorate, index) => (
                    <MenuItem key={index} value={governorate.id}>
                      {governorate.name}
                    </MenuItem>
                  ))}
                  )
                </Select>
              </FormControl>
            </div>

            <div className="col-6 mt-2">
              <FormControl sx={{ minWidth: "100%" }}>
                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Region"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                >
                  {regions?.map((region, index) => (
                    <MenuItem key={index} value={region.id}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-6 mt-3 offset-6">
              {addEditFlag ? (
                <button
                  className="btn btn-primary  w-100"
                  onClick={handleSubmit}
                >
                  Add Doctor
                </button>
              ) : (
                <button
                  className="btn btn-primary  w-100"
                  onClick={handleUpdate}
                >
                  Edit Doctor
                </button>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* <CustomToastify bgColor={success ? "green" : "red"} duration={false} /> */}
    </div>
  );
};

export default Doctors;
