import React, { useEffect, useState } from "react";
import Styles from "./style.module.css";
import { AiOutlineCloudServer } from "react-icons/ai";
import { GiCompactDisc } from "react-icons/gi";
import { GiBreakingChain } from "react-icons/gi";
import { httpNonToken, endPoints } from "../../../globalConf/http-non-token";

const SelectDicomType = ({ requestCopy, setRequestCopy, setDicomFlag }) => {
  const [dicones, setdicones] = useState([]);
  const [selectedDicone, setSelectedDicone] = useState(
    requestCopy.DICON_source
  );
  const [check, setCheck] = useState(false);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const diconSourceRes = await httpNonToken.get(
          `${endPoints.diconSource}`
        );
        if (isMounted) {
          setdicones(diconSourceRes.data.results);
        }
      } catch ({ response }) {}
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (requestCopy.submit) {
      setCheck(false);
      setSelectedDicone("");
    }
  }, [requestCopy]);

  const handleSelectedDicone = (e, id) => {
    setSelectedDicone(id);
    setRequestCopy({ DICON_source: id });
    setDicomFlag(false);
  };

  const getStyle = () => {
    let style = {};
    if (check) {
      style.backgroundColor = "#DAE8FC";
      style.color = "#275ff8 ";
    } else {
      style.backgroundColor = "white";
      style.color = "#afafb9";
    }
    return style;
  };

  return (
    <div className={`bg-white m-3 p-3  text-center `}>
      <div className="row">
        {dicones &&
          dicones.length > 0 &&
          dicones.map((dicone) => {
            return (
              <div
                className={`col border p-0 m-1 ${Styles.select_data}`}
                key={dicone.id}
              >
                <input
                  type="radio"
                  id={dicone.name}
                  onChange={(e) => handleSelectedDicone(e, dicone.id)}
                  checked={selectedDicone === dicone.id}
                  value={selectedDicone}
                  className={`d-none form-check-input ${Styles.select_data}`}
                  name="select"
                />
                <label
                  htmlFor={dicone.name}
                  className={`w-100 ${Styles.select_data}`}
                >
                  {dicone.name === "URL" && dicone.id === 4 ? (
                    <GiBreakingChain className="mt-4 mb-3 w-50 h-50" />
                  ) : dicone.name === "Pacs Cloud" && dicone.id === 2 ? (
                    <AiOutlineCloudServer className="mt-4 mb-3 w-50 h-50" />
                  ) : dicone.name === "CD" && dicone.id === 3 ? (
                    <GiCompactDisc className="mt-4 mb-3 w-50 h-50" />
                  ) : (
                    ""
                  )}
                  <h6>{dicone.name}</h6>
                </label>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SelectDicomType;
