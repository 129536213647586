import React, { useEffect, useState } from "react";

import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import Loading from "../loading/loading";

import CustomToastify, { toastMes } from "../../globalConf/notification";

const PriceList = () => {
  const [flag, setFlag] = useState(true);
  const [load, setLoad] = useState(true);
  const [success, setSuccess] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedPart, setSelectedPart] = useState("");

  const [services, setServices] = useState([]);

  const [ageGroups, setAgeGroups] = useState([]);
  const [price, setPrice] = useState("");
  const [pricesArr, setPricesArr] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      let allServices = await httpNonToken.get(
        `${endPoints.customService}?order_by=id`
      );
      let allAgeGroup = await httpNonToken.get(
        `${endPoints.ageGroups}?order_by=id`
      );

      let pricesArray = [...pricesArr];
      for (let i = 0; i < allServices.data.results.length; i++) {
        for (let j = 0; j < allServices.data.results[i].prices.length; j++) {
          pricesArray.push(allServices.data.results[i].prices[j].amount);
        }
      }
      setPricesArr(pricesArray);
      setServices(allServices.data.results);
      setAgeGroups(allAgeGroup.data.results);
      setLoad(false);
    })();
  }, [flag]);

  const handlePriceChange = (e, position = 0) => {
    const updatedPrice = pricesArr.map((item, index) => {
      return index === position ? e.target.value : item;
    });
    setPricesArr(updatedPrice);
  };

  const handleEdit = async (id, index) => {
    try {
      await httpNonToken.put(`${endPoints.updatePrice}/${id}`, {
        amount: pricesArr[index],
      });
      setFlag(!flag);

      toastMes.success("Price Updated Successfully");
      setSelectedPart("");
      setEdit(false);
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  if (load) {
    return <Loading />;
  }

  return (
    <div className="w-100 py-5 px-3 ">
      <Table aria-label="simple table" sx={{ bgcolor: "background.paper" }}>
        <TableHead>
          <TableRow>
            <TableCell className="border text-bold" colSpan={2}>
              Price List
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="border">Anatomy Parts</TableCell>
            {ageGroups.length > 0
              ? ageGroups.map((ag, index) => {
                  return (
                    <TableCell key={index} className="border">
                      {ag.name}
                    </TableCell>
                  );
                })
              : null}
            <TableCell className="border"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.length > 0
            ? services.map((service, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell className="border" sx={{ padding: "10px 14px" }}>
                      {service.name}{" "}
                      {/* {service.prices.length === 0 ? (
                        <i
                          // onClick={handleOpen}
                          className="fa-solid fa-circle-plus d-inline-block mx-3 cursor-pointer text-success"
                        >
                        </i>
                      ) : null} */}
                    </TableCell>
                    {service.prices.map((price, indx) => {
                      return (
                        <>
                          {edit &&
                          selectedPart ===
                            ageGroups.length * services.indexOf(services[idx]) +
                              service.prices.indexOf(service.prices[indx]) ? (
                            <TableCell
                              key={price.id}
                              className="border"
                              sx={{ padding: "10px 14px" }}
                            >
                              <TextField
                                fullWidth
                                type="number"
                                size="small"
                                id="outlined-basic"
                                label="Service Price"
                                variant="outlined"
                                onChange={(e) =>
                                  handlePriceChange(
                                    e,
                                    ageGroups.length *
                                      services.indexOf(services[idx]) +
                                      service.prices.indexOf(
                                        service.prices[indx]
                                      )
                                  )
                                }
                                value={
                                  pricesArr[
                                    ageGroups.length *
                                      services.indexOf(services[idx]) +
                                      service.prices.indexOf(
                                        service.prices[indx]
                                      )
                                  ]
                                }
                              />
                              <div className="text-center mt-1">
                                <i
                                  className="fa-solid fa-circle-xmark text-danger cursor-pointer mx-2 fa-lg"
                                  onClick={() => {
                                    setEdit(false);
                                    setSelectedPart("");
                                  }}
                                ></i>
                                <i
                                  className="fa-solid fa-circle-check text-success cursor-pointer mx-2 fa-lg"
                                  onClick={() =>
                                    handleEdit(
                                      services[idx].prices[indx].id,
                                      ageGroups.length *
                                        services.indexOf(services[idx]) +
                                        service.prices.indexOf(
                                          service.prices[indx]
                                        )
                                    )
                                  }
                                ></i>
                              </div>
                            </TableCell>
                          ) : (
                            <TableCell
                              key={price.id}
                              className="border"
                              sx={{ padding: "10px 14px" }}
                              onClick={() => {
                                setEdit(true);
                                setSelectedPart(
                                  ageGroups.length *
                                    services.indexOf(services[idx]) +
                                    service.prices.indexOf(service.prices[indx])
                                );
                              }}
                            >
                              <p className="price-amount">
                                {
                                  pricesArr[
                                    ageGroups.length *
                                      services.indexOf(services[idx]) +
                                      service.prices.indexOf(
                                        service.prices[indx]
                                      )
                                  ]
                                }
                              </p>
                            </TableCell>
                          )}
                        </>
                      );
                    })}
                  </TableRow>
                );
              })
            : null}
        </TableBody>
      </Table>

      {/* <CustomToastify duration={false} /> */}
    </div>
  );
};

export default PriceList;
