import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Styles from "./style.module.css";

const SelectPrint = ({ requestCopy, setRequestCopy, setServiceFlag }) => {
  const [printSection, setPrintSection] = useState(true);
  const [printService, setPrintService] = useState([]);
  const [selectedService, setSelectedService] = useState(requestCopy.service);
  useEffect(() => {
    setSelectedService("");
    fetch("https://py.qubx3d.com/api/v1/service?type__id=2")
      .then((response) => response.json())
      .then((data) => setPrintService(data.results));
    setSelectedService(requestCopy.service);
  }, []);

  const handleChange = (e) => {
    setSelectedService(parseFloat(e.target.id));
    setRequestCopy({
      service: parseFloat(e.target.id),
      serviceName: e.target.name,
    });
    setPrintSection(!printSection);
    setServiceFlag(false);
  };

  const resetChoice = () => {
    setSelectedService("");
    setRequestCopy({ serviceName: "", service: "" });
    setPrintSection(!printSection);
  };
  const getStyle = () => {
    let style = {};
    if (requestCopy.service) style.backgroundColor = "#DAE8FC";
    else style.backgroundColor = "white";
    return style;
  };
  return (
    <>
      {printSection ? (
        <div
          style={getStyle()}
          className={`m-3 border rounded p-2  text-center  ${Styles.printing3d_section}`}
        >
          <img
            src="/images/global/3d-printing.svg"
            className="my-3 w-50"
            alt="doctor"
            onClick={() => setPrintSection(!printSection)}
          />
          <h6 style={{ fontSize: "14px" }}>
            {`3D Printing ${
              requestCopy.serviceName ? `( ${requestCopy.serviceName} )` : ""
            }`}
          </h6>
        </div>
      ) : (
        <div
          className={`bg-white m-3 border p-2 rounded ${Styles.printing3d_section}`}
        >
          <div className="border-bottom">
            <img
              src="/images/global/3d-printing.svg"
              className="my-1 w_15 cursor-pointer"
              alt="doctor"
              onClick={() => setPrintSection(!printSection)}
            />
          </div>
          <div className="mt-2 ms-2 mx-1">
            <div className="d-flex justify-content-end">
              <Button
                size="small"
                onClick={resetChoice}
                variant="outlined"
                color="error"
              >
                Clear
              </Button>
            </div>
            {printService.map((printing, id) => (
              <div className="form-check  fn_sm fw-bold m-2 ls-1" key={id}>
                <input
                  className={`form-check-input ${Styles.select_printing}`}
                  type="radio"
                  name={printing.name}
                  id={printing.id}
                  checked={selectedService === printing.id}
                  value={selectedService}
                  onChange={(e) => handleChange(e)}
                />
                <label className="form-check-label" htmlFor={printing.id}>
                  {printing.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SelectPrint;
