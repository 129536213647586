import React, { useEffect, useState } from "react";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import Loading from "../loading/loading";

import CustomToastify, { toastMes } from "../../globalConf/notification";

const StatusTimerSla = () => {
  const [flag, setFlag] = useState(true);
  const [load, setLoad] = useState(true);
  const [status, setStatus] = useState([]);
  const [SLA, setSLA] = useState("");
  const [StatusSLAArr, setStatusSLAArr] = useState([]);

  useEffect(() => {
    (async () => {
      let allStatus = await httpNonToken.get(
        `${endPoints.requestStatus}?order_by=id`
      );
      let SLAArr = [...StatusSLAArr];
      for (let i = 0; i < allStatus.data.results.length; i++) {
        SLAArr.push(allStatus.data.results[i].SLA);
      }
      setStatusSLAArr(SLAArr);
      setStatus(allStatus.data.results);
      setLoad(false);
    })();
  }, [flag]);

  const handleSubmit = async (id, SLAVal) => {
    try {
      let SLAValue;

      SLAValue = !SLA ? SLAVal : SLA;
      await httpNonToken.put(`${endPoints.updateStatus}/${id}`, {
        SLA: SLAValue,
      });
      toastMes.success("Status Timer Updated Successfully");
      setFlag(!flag);
      setSLA("");
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  const handleSLAChange = async (e, position = 0) => {
    setSLA(e.target.value);
    const updatedSLAState = StatusSLAArr.map((item, index) => {
      return index === position ? e.target.value : item;
    });
    setStatusSLAArr(updatedSLAState);
  };

  if (load) {
    return <Loading />;
  }

  return (
    <div className="w-100 py-5 px-3 ">
      <Table aria-label="simple table" sx={{ bgcolor: "background.paper" }}>
        <TableHead>
          <TableRow>
            <TableCell className="border text-bold" colSpan={2}>
              Timer
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="border">Status</TableCell>
            <TableCell className="border">Threshold (Hours)</TableCell>
            <TableCell className="border"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {status.length > 0
            ? status.map((statu, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell className="border" sx={{ padding: "10px 14px" }}>
                      {statu.name}
                    </TableCell>
                    <TableCell className="border" sx={{ padding: "10px 14px" }}>
                      <TextField
                        fullWidth
                        type="number"
                        size="small"
                        id="outlined-basic"
                        label="Status SLA"
                        variant="outlined"
                        value={StatusSLAArr[idx]}
                        onChange={(e) => handleSLAChange(e, idx)}
                      />
                    </TableCell>
                    <TableCell className="border">
                      <i
                        onClick={() =>
                          handleSubmit(statu.id, StatusSLAArr[idx])
                        }
                        className="fa-solid fa-pen fa-lg text-primary"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </TableCell>
                  </TableRow>
                );
              })
            : null}
        </TableBody>
      </Table>

      {/* <CustomToastify duration={false} /> */}
    </div>
  );
};

export default StatusTimerSla;
