/* eslint-disable */
import React, { useReducer, useState } from "react";

export const ServiceBgContext = React.createContext();

export function ServiceBgProvider(Props) {
  const [data, setData] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      uploadFlagCon: true,
      stopInterval: false,
      reqBg: [],
    }
  );

  const bgFun = () => {
    let interval;
    if (data.uploadFlagCon) {
      interval = setInterval(() => {
        if (data.stopInterval) {
          clearInterval(interval);
        }
      }, 5000);
    }
  };

  return (
    <ServiceBgContext.Provider
      value={{
        bgFun,
        data,
        setData,
      }}
    >
      {Props.children}
    </ServiceBgContext.Provider>
  );
}
