import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Header from "../header/Header";
import SmsTemplate from "../smsTemplate/SmsTemplate";
import StatusTimerSla from "../statusTimerSla/StatusTimerSla";
import PriceList from "../priceList/PriceList";
import CPanel from "../cPanel/CPanel";
import CustomToastify from "../../globalConf/notification";

import "./panel.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      // className={value === index ? "overflow-scroll" : "overflow-hidden"}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
    className: "py-5 overflow-auto",
  };
}

export default function ControlPanel() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Header />
      <Box
        className="panel-container"
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="SMS Center" {...a11yProps(0)} />
          <Tab label="Timer" {...a11yProps(1)} />
          <Tab label="Price List" {...a11yProps(2)} />
          <Tab label="cPanel" {...a11yProps(3)} />
        </Tabs>
        <div
          value={value}
          index={0}
          className={
            value === 0 ? "overflow-auto w-100" : "overflow-hidden d-none"
          }
        >
          <SmsTemplate />
        </div>
        <div
          value={value}
          index={1}
          className={
            value === 1 ? "overflow-auto w-100" : "overflow-hidden d-none"
          }
        >
          <StatusTimerSla />
        </div>

        <div
          value={value}
          index={3}
          className={
            value === 2 ? "overflow-auto w-100" : "overflow-hidden d-none"
          }
        >
          <PriceList />
        </div>
        <div
          value={value}
          index={4}
          className={
            value === 3 ? "overflow-auto w-100" : "overflow-hidden d-none"
          }
        >
          <CPanel />
        </div>
      </Box>
      {/* <CustomToastify duration={false} /> */}
    </>
  );
}
