import axios from "axios";

const instance = axios.create({
  baseURL: "https://py.qubx3d.com/api/v1",
});

let data = JSON.parse(localStorage.getItem("userOP"));
const token = data ? data.access : "";

instance.interceptors.request.use((config) => {
  config.headers.authorization = `Bearer ${
    JSON.parse(localStorage.getItem("userOP")).access
  }`;
  return config;
});
export const http = instance;

export const endPointsToken = {
  login: "/login", //Login - auth
  requestStatus: "/status", // get all status
  status360: "/status/all/360",
  qubxOperator: "/employeeProfile", // get all qubx operator
  sms: "/sms/template", //get all SMS
  smsType: "/sms/request/template/",

  //Request
  request: "/request", //get all request tracking
  updateRequest: "/request/update", //update request Data
  deleteRequest: "/request/delete", //delete request Data

  //patient
  patients: "/patient", // get all patients
  createPatient: "/patient/create",

  //doctor
  doctors: "/doctorProfile", // get all doctor
  createDoctor: "/user/user-doctor/create",

  //Printing Provider
  printing: "/printing", //get all Printing Provider
  createPrinting: "/printing/create",
  requestPrinting: "/request/printing",
  createRequestPrinting: "/request/printing/create",
  updateRequestPrinting: "/request/printing/update",

  //courier
  courier: "/courier", //get all Courier Branches
  createCourier: "/courier/create",
  requestCourier: "/request/courier",
  createRequestCourier: "/request/courier/create",
  updateRequestCourier: "/request/courier/update",

  //Branch
  rcBranch: "/branch", //get all RC Branches
  createRcBranch: "/branch/create",

  //Notes
  notes: "/note",
  createNote: "/note/create",

  //   notifications
  notifications: "/notifications",
  updateNotification: "/notifications/update",
  updateGroupNotifications: "/notifications/read",

  // send Msg
  sendMsg: "/sms/send",

  // stl file
  stl: "/request/stl",
  updateStl: "/request/stl/reupload",

  // Sirv
  sirv: "/sirv/upload-file",
  deleteSirve: "/sirv/delete-folder-content",

  // get sirv url
  getSirvUrl: "/sirv/get-url-spin",
};
