// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".changePassword_p_warning__nXrqN {\n    width: 95% !important;\n  }\n  .changePassword_img_warning__d6ksh {\n    float: left;\n    margin: 21px 5px 0 20px;\n  }\n  @media only screen and (max-width: 900px) {\n    .changePassword_content__hYu3g {\n      width: 70% !important;\n    }\n  }", "",{"version":3,"sources":["webpack://./src/components/changePassword/changePassword.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;EACvB;EACA;IACE,WAAW;IACX,uBAAuB;EACzB;EACA;IACE;MACE,qBAAqB;IACvB;EACF","sourcesContent":[".p_warning {\n    width: 95% !important;\n  }\n  .img_warning {\n    float: left;\n    margin: 21px 5px 0 20px;\n  }\n  @media only screen and (max-width: 900px) {\n    .content {\n      width: 70% !important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"p_warning": "changePassword_p_warning__nXrqN",
	"img_warning": "changePassword_img_warning__d6ksh",
	"content": "changePassword_content__hYu3g"
};
export default ___CSS_LOADER_EXPORT___;
