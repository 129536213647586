import React, { useEffect, useState } from "react";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Fade,
  Box,
  Modal,
  Typography,
  Backdrop,
  Divider,
  TextField,
} from "@mui/material";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import Loading from "../loading/loading";

import CustomToastify, { toastMes } from "../../globalConf/notification";

const SmsTemplate = () => {
  const [flag, setFlag] = useState(true);
  const [load, setLoad] = useState(true);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [success, setSuccess] = useState(false);

  const [sms, setSms] = useState([]);
  const [smsBody, setSmsBody] = useState("");
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    (async () => {
      let allSms = await httpNonToken.get(`${endPoints.sms}?order_by=id`);
      setSms(allSms.data.results);
      setLoad(false);
    })();
  }, [flag]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
    setSmsBody("");
  };

  const handleSelected = async (id, body) => {
    setSmsBody(body);
    setSelectedId(id);

    handleOpen();
  };

  const handleSubmit = async () => {
    try {
      await httpNonToken.put(`${endPoints.updateSms}/${selectedId}`, {
        body: smsBody,
      });
      toastMes.error("SMS Updated Successfully");
      setFlag(!flag);
      setConfirm(true);
      handleClose();
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  const handleConfirm = async () => {
    setConfirm(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  if (load) {
    return <Loading />;
  }

  return (
    <div className="w-100 py-5 px-3 ">
      <Table aria-label="simple table" sx={{ bgcolor: "background.paper" }}>
        <TableHead>
          <TableRow>
            <TableCell className="border text-bold" colSpan={2}>
              3D Printing
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="border">Status</TableCell>
            <TableCell className="border">SMS Template</TableCell>
            <TableCell className="border"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sms.length > 0
            ? sms.map((item, idx) => {
                return item.service === "3D printed model" ? (
                  <TableRow key={idx}>
                    <TableCell className="border" sx={{ padding: "10px 14px" }}>
                      {item.status.name}-{item.sms_type.name}
                    </TableCell>
                    <TableCell className="border" sx={{ padding: "10px 14px" }}>
                      {item.body}
                    </TableCell>
                    <TableCell className="border">
                      <i
                        onClick={() => handleSelected(item.id, item.body)}
                        className="fa-solid fa-pen fa-lg text-primary"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </TableCell>
                  </TableRow>
                ) : null;
              })
            : null}
        </TableBody>
      </Table>
      <Table
        sx={{ marginTop: "60px", bgcolor: "background.paper" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell className="border text-bold" colSpan={2}>
              360 Digital Printing
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="border">Status</TableCell>
            <TableCell className="border">SMS Template</TableCell>
            <TableCell className="border"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sms.length > 0
            ? sms.map((item, idx) => {
                return item.service === "Digital file" ? (
                  <TableRow key={idx}>
                    <TableCell className="border" sx={{ padding: "10px 14px" }}>
                      {item.status.name}-{item.sms_type.name}
                    </TableCell>
                    <TableCell className="border" sx={{ padding: "10px 14px" }}>
                      {item.body}
                    </TableCell>
                    <TableCell className="border">
                      <i
                        onClick={() => handleSelected(item.id, item.body)}
                        className="fa-solid fa-pen fa-lg text-primary"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </TableCell>
                  </TableRow>
                ) : null;
              })
            : null}
        </TableBody>
      </Table>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="row">
            <Typography variant="h5" gutterBottom component="div">
              {confirm ? "Confirmation" : "Edit SMS Template Body"}
            </Typography>
            <Divider sx={{ width: "50%", margin: "left" }} />
            {confirm ? (
              <div className="row">
                <div className="col-12 mt-4 text-center">
                  <i className="text-danger text-danger fas fa-exclamation-triangle fa-4x"></i>
                </div>
                <div className="col-12 mt-2 text-center">
                  <p className="text-danger">
                    Be careful......!!! <br />
                    change in SMS template body very sensitive and may be cause
                    problems
                  </p>
                </div>

                <div className="col-12 d-flex justify-content-end mt-4">
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    className="mx-2"
                    color="error"
                  >
                    Cancel
                  </Button>
                  <Button variant="outlined" onClick={handleSubmit}>
                    Submit
                  </Button>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 mt-4">
                  <TextField
                    fullWidth
                    multiline
                    rows={7}
                    size="small"
                    id="outlined-basic"
                    label="SMS Body"
                    variant="outlined"
                    value={smsBody}
                    onChange={(e) => setSmsBody(e.target.value)}
                  />
                </div>

                <div className="col-12 d-flex justify-content-end mt-4">
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    className="mx-2"
                    color="error"
                  >
                    Close
                  </Button>
                  <Button variant="outlined" onClick={handleConfirm}>
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
      {/* <CustomToastify duration={false} /> */}
    </div>
  );
};

export default SmsTemplate;
