import axios from "axios";

export const basicUrl = `https://py.qubx3d.com/api/v1`;

const instance = axios.create({
  baseURL: "https://py.qubx3d.com/api/v1",
});

instance.interceptors.request.use((config) => {
  return config;
});
export const httpNonToken = instance;

export const endPoints = {
  login: "/login", //Login - auth
  requestStatus: "/status", // get all status
  status360: "/status/all/360",
  updateStatus: "/status/update",
  qubxOperator: "/employeeProfile", // get all qubx operator
  updateEmployee: "/employeeProfile/update/",
  sms: "/sms/template", //get all SMS
  smsType: "/sms/request/template/",
  updateSms: "/sms/template/update", //get all SMS
  governorate: "/governorates", //get all governorates
  region: "/regions", //get all governorates
  diconSource: "/diconSource",

  user: "/user/",

  requestPrice: "/request/price/",
  price: "/price",
  updatePrice: "/price/update",
  ageGroups: "/agegroup",
  updateAgeGroup: "/agegroup/update",

  //Request
  request: "/request", //get all request tracking
  allRequests: "/request/all",
  updateRequest: "/request/update", //update request Data
  deleteRequest: "/request/delete", //delete request Data

  //patient
  patients: "/patient", // get all patients
  createPatient: "/patient/create",
  updatePatient: "/patient/update",

  //doctor
  doctors: "/doctorProfile", // get all doctor
  createDoctor: "/user/user-doctor/create",
  updateDoctor: "/doctorProfile/update",

  //Printing Provider
  printing: "/printing", //get all Printing Provider
  createPrinting: "/printing/create",
  updatePrinting: "/printing/update",
  requestPrinting: "/request/printing",
  createRequestPrinting: "/request/printing/create",
  updateRequestPrinting: "/request/printing/update",

  //courier
  courier: "/courier", //get all Courier Branches
  createCourier: "/courier/create",
  updateCourier: "/courier/update",
  requestCourier: "/request/courier",
  createRequestCourier: "/request/courier/create",
  updateRequestCourier: "/request/courier/update",

  //Branch
  rcBranch: "/branch", //get all RC Branches
  createRcBranch: "/branch/user/create",
  updateRcBranch: "/branch/update",
  rcGroup: "/rcGroup",
  createRCG: "/rcGroup/create",

  //Notes
  notes: "/note",
  createNote: "/note/create",

  //   notifications
  notifications: "/notifications",
  updateNotification: "/notifications/update",
  updateGroupNotifications: "/notifications/read",

  // send Msg
  sendMsg: "/sms/send",

  // stl file
  stl: "/request/stl",
  updateStl: "/request/stl/reupload",

  // Sirv
  sirv: "/sirv/upload-file",
  deleteSirve: "/sirv/delete-folder-content",

  // get sirv url
  getSirvUrl: "/sirv/get-url-spin",

  // create branch
  createBranch: "branch/user/create",
  getBranch: "branch/",
  updateBranch: "/branch/update",

  // user
  updateUser: "/user/update",
  getUser: "/user",

  // create employee
  employeeCreate: "/user/employee/create",

  changePassword: "/user/changePassword/",

  // service
  serviceType: "/type",
  service: "/service",
  updateService: "/service/update",
  createService: "/service/create",
  deleteService: "/service/delete/",
  //

  // forget-password
  forgetPassword: "/user/forgot-password",

  pathology: "/pathology",
  updatePathology: "/pathology/update",
  createPathology: "/pathology/create",

  anatomy: "/anatomy",
  updateAnatomy: "/anatomy/update",
  createAnatomy: "/anatomy/create",

  customService: "/service/custom",

  //
  statistics: "statistics",
};
