import React, { useEffect, useState } from "react";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Divider,
} from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import Loading from "../loading/loading";

import CustomToastify, { toastMes } from "../../globalConf/notification";

const AnatomyService = ({ services, flag, setFlag }) => {
  const [serviceNameArr, setServiceNameArr] = useState([]);
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [serFlag, setSerFlag] = useState(false);
  const [service, setService] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [servicesType, setServicesType] = useState([]);

  const handleChange = (event) => {
    setService(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      let serviceTypeRes = await httpNonToken.get(`${endPoints.serviceType}`);
      let serviceNameArray = [];
      for (let i = 0; i < services.length; i++) {
        serviceNameArray.push(services[i].name);
      }
      serviceNameArray.push(serviceName);
      setServiceName("");
      setServicesType(serviceTypeRes.data.results);
      setServiceNameArr(serviceNameArray);
    })();
  }, [flag, serFlag]);

  const handleSubmit = async (id, nameVal) => {
    try {
      let nameValue;
      nameValue = !name ? nameVal : name;

      await httpNonToken.put(`${endPoints.updateService}/${id}`, {
        name: nameValue,
      });
      toastMes.error("Service Updated Successfully");
      setFlag(!flag);
      setName("");
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  const handleAddService = async () => {
    if (!servicesType || !serviceName) {
      toastMes.error("all fields required .!");
      return;
    }

    try {
      let createServiceRes = await httpNonToken.post(
        `${endPoints.createService}`,
        {
          type: service,
          name: serviceName,
        }
      );
      if (createServiceRes.status === 201) {
        setFlag(!flag);

        toastMes.success("Service Create Successfully");
        handleClose();
        // setServiceName("");
        // setServiceNameArr([...serviceNameArr, serviceName]);
        setService("");
        setSerFlag(!serFlag);
      }
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  const handleSerChange = (e, position = 0) => {
    setName(e.target.value);
    const updatedServiceName = serviceNameArr.map((item, index) => {
      return index === position ? e.target.value : item;
    });
    setServiceNameArr(updatedServiceName);
  };

  const handleDeleteSer = async (id) => {
    try {
      let deleteServiceRes = await httpNonToken.delete(
        `${endPoints.deleteService}${id}`
      );
      if (deleteServiceRes.status === 200) {
        setFlag(!flag);
        toastMes.error("Service Deleted Successfully");
      }
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  return (
    <div className="w-100 py-5 px-3 ">
      <Table aria-label="simple table" sx={{ bgcolor: "background.paper" }}>
        <TableHead>
          <TableRow>
            <TableCell className="border text-bold" colSpan={2}>
              Anatomy Services
            </TableCell>
            <TableCell>
              <i
                onClick={handleClickOpen}
                className="fa-solid fa-circle-plus fa-lg text-primary cursor-pointer"
              ></i>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="border">Anatomy Part</TableCell>
            <TableCell className="border">Type</TableCell>
            <TableCell className="border"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.length > 0
            ? services.map((ser, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell
                      className="border d-flex justify-content-between"
                      sx={{ padding: "10px 14px" }}
                    >
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="outlined-basic"
                        label="Service-Name"
                        variant="outlined"
                        size="small"
                        name="name"
                        value={serviceNameArr[idx]}
                        onChange={(e) => handleSerChange(e, idx)}
                      />
                    </TableCell>
                    <TableCell className="border" sx={{ padding: "10px 14px" }}>
                      {ser.type.name}
                    </TableCell>

                    <TableCell className="border">
                      <i
                        className="fa-solid fa-pen fa-lg text-primary d-inline-block mx-2"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleSubmit(ser.id, serviceNameArr[idx])
                        }
                      ></i>
                      <i
                        class="fa-solid fa-trash fa-lg text-danger d-inline-block"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteSer(ser.id)}
                      ></i>
                    </TableCell>
                  </TableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add New Service"}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              label="Service Name"
              fullWidth
              value={serviceName}
              onChange={(e) => setServiceName(e.target.value)}
              className="mt-3"
              id="outlined-size-small"
              size="small"
            />
            <Box className="mt-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Service Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={service}
                  size="small"
                  label="Service Type"
                  onChange={handleChange}
                >
                  {servicesType.map((ser) => {
                    return <MenuItem value={ser.id}>{ser.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddService} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* <CustomToastify duration={false} /> */}
    </div>
  );
};

export default AnatomyService;
