import React, { useState, useEffect } from "react";
// import CanvasJSReact from "../assets/canvasjs.react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../loading/loading";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Requests from "../requestTracking/RequestTracking";
import {
  Tooltip,
  Pie,
  Label,
  PieChart,
  AreaChart,
  XAxis,
  YAxis,
  Area,
} from "recharts";
import AnalyticalData from "./analyticalData";

import "./statistic-style.css";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";

const StatisticPage = () => {
  const [branches, setBranches] = useState([]);
  //
  const [couriers, setCouriers] = useState([]);
  const [centers, setCenters] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [patients, setPatients] = useState([]);
  const [status, setStatus] = useState([]);
  const [orders, setOrders] = useState([]);
  const [services, setServices] = useState([]);

  const [load, setLoad] = useState(true);

  const navigate = useNavigate();

  let data = {};
  data = JSON.parse(localStorage.getItem("userOP"));

  useEffect(() => {
    (async () => {
      (async () => {
        if (data) {
          if (data.user.groups.includes(2)) {
            try {
              let getBranchesRes = await httpNonToken.get(
                `${endPoints.rcBranch}?page_size=5`
              );
              let statisticsRes = await httpNonToken.get(
                `${endPoints.statistics}`
              );
              setPatients(statisticsRes.data.numOfPaitients);
              setDoctors(statisticsRes.data.numOfDoctors);
              setOrders(statisticsRes.data.numOfRequests);
              setCouriers(statisticsRes.data.couriers);
              setCenters(statisticsRes.data.numOfBranchs);
              setStatus(statisticsRes.data.status);
              setBranches(getBranchesRes.data.results);
              setServices(statisticsRes.data.services);
              setLoad(false);
            } catch (error) {}
          } else {
            navigate("/requests");
          }
        } else {
          navigate("/login");
        }
      })();
    })();
  }, []);

  if (load) {
    return <Loading />;
  }

  return (
    <div className="bg_dark statistic-page">
      <Header />

      <div className="bg_light p-2 rounded-top-20">
        <div className="d-flex justify-content-end m-2 text-success cursor-pointer">
          <AnalyticalData />
        </div>
        <div className="w-100 m-auto">
          <div className="row">
            <div className="col bg-white p-2 mx-4 my-3 rounded">
              <h6 className="title font-sm fw-bold border-bottom text-uppercase pb-3 f-main d-flex justify-content-between">
                <span> number of patients </span>
                <span className="text-primary"> {patients.total} </span>
              </h6>
              <AreaChart width={300} height={160} data={patients.data}>
                <defs>
                  <linearGradient id="MyGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="rgba(0, 136, 254, 0.8)" />
                    <stop offset="95%" stopColor="rgba(0, 136, 254, 0)" />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name">
                  <Label position="insideBottom" />
                </XAxis>
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#0088FE"
                  strokeWidth="2"
                  fillOpacity="1"
                  fill="url(#MyGradient)"
                  dot
                />
              </AreaChart>
            </div>
            <div className="col bg-white p-2 mx-4 my-3 rounded">
              <h6 className="title font-sm fw-bold border-bottom text-uppercase pb-3 f-main d-flex justify-content-between">
                <span> number of orders </span>
                <span className="text-primary"> {orders.total} </span>
              </h6>
              <AreaChart width={300} height={160} data={orders.data}>
                <defs>
                  <linearGradient id="MyGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="rgba(0, 136, 254, 0.8)" />
                    <stop offset="95%" stopColor="rgba(0, 136, 254, 0)" />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name">
                  <Label position="insideBottom" />
                </XAxis>
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#0088FE"
                  strokeWidth="2"
                  fillOpacity="1"
                  fill="url(#MyGradient)"
                  dot
                />
              </AreaChart>
            </div>
            <div className="col bg-white p-2 mx-4 my-3 rounded">
              <h6 className="title font-sm fw-bold border-bottom text-uppercase pb-3 f-main d-flex justify-content-between">
                <span> number of doctors </span>
                <span className="text-primary"> {doctors.total} </span>
              </h6>
              <AreaChart width={300} height={160} data={doctors.data}>
                <defs>
                  <linearGradient id="MyGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="rgba(0, 136, 254, 0.8)" />
                    <stop offset="95%" stopColor="rgba(0, 136, 254, 0)" />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name">
                  <Label position="insideBottom" />
                </XAxis>
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#0088FE"
                  strokeWidth="2"
                  fillOpacity="1"
                  fill="url(#MyGradient)"
                  dot
                />
              </AreaChart>
            </div>

            <div className="col bg-white p-2 mx-4 my-3 rounded">
              <h6 className="title font-sm fw-bold border-bottom text-uppercase pb-3 f-main d-flex justify-content-between">
                <span> number of centers </span>
                <span className="text-primary"> {centers.total} </span>
              </h6>
              <AreaChart width={300} height={160} data={centers.data}>
                <defs>
                  <linearGradient id="MyGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="rgba(0, 136, 254, 0.8)" />
                    <stop offset="95%" stopColor="rgba(0, 136, 254, 0)" />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name">
                  <Label position="insideBottom" />
                </XAxis>
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#0088FE"
                  strokeWidth="2"
                  fillOpacity="1"
                  fill="url(#MyGradient)"
                  dot
                />
              </AreaChart>
            </div>
          </div>

          <div className="row">
            <div className="col-2 bg-white p-2 mx-4 my-3 rounded">
              <h6 className="title font-sm fw-bold border-bottom text-uppercase pb-3 f-main d-flex justify-content-between">
                <span> services </span>
                <span className="text-primary"> {services.total} </span>
              </h6>
              <AreaChart
                width={200}
                height={160}
                style={{ marginTop: 100 }}
                data={services.data}
              >
                <defs>
                  <linearGradient id="MyGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="rgba(0, 136, 254, 0.8)" />
                    <stop offset="95%" stopColor="rgba(0, 136, 254, 0)" />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name">
                  <Label position="insideBottom" />
                </XAxis>
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#0088FE"
                  strokeWidth="2"
                  fillOpacity="1"
                  fill="url(#MyGradient)"
                  dot
                />
              </AreaChart>
            </div>
            <div className="col bg-white p-2 mx-4 my-3 rounded">
              <h6 className="title font-sm fw-bold border-bottom text-uppercase pb-3 f-mainm d-flex justify-content-between">
                <span> status</span>
                <span className="text-primary">
                  {status.total} - {status.totalOfReq}
                </span>
              </h6>
              <div className="row">
                {status.data.length > 0
                  ? status.data.map((statu, idx) => {
                      return (
                        <div
                          key={idx}
                          className={`col-${Math.floor(
                            12 / Math.ceil(status.data.length / 5)
                          )}`}
                        >
                          <div
                            className="m-auto"
                            style={{
                              backgroundColor: statu.color,
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                            }}
                          ></div>
                          <div
                            className="mt-1 mb-3 text-center"
                            style={{ fontSize: "12px" }}
                          >
                            {statu.value} {"  "} {statu.name} {"  "}
                            in {status.totalOfReq} Requests
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>

            <div className="col bg-white p-2 my-3 mx-4  rounded">
              <h6 className="title font-sm fw-bold border-bottom text-uppercase pb-3 f-main d-flex justify-content-between">
                <span>couriers</span>
                <span className="text-primary">{couriers.total}</span>
              </h6>
              <div>
                <PieChart width={400} height={400}>
                  <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={couriers.data}
                    cx={200}
                    cy={200}
                    outerRadius={100}
                    fill="#8884d8"
                    label
                  />

                  <Tooltip />
                </PieChart>
              </div>
            </div>
            <div className="row">
              <div
                className="col-9 bg-white p-2 mx-4 my-3 rounded home-table"
                style={{ maxHeight: "450px", overflow: "auto" }}
              >
                <h6 className="title font-sm fw-bold border-bottom text-uppercase pb-3 f-main">
                  Requests
                </h6>
                <Requests home={true} />
              </div>
              <div className="col bg-white p-2 mx-4 my-3 rounded">
                <h6 className="title font-sm fw-bold border-bottom text-uppercase pb-3 f-main">
                  Radiology Center
                </h6>
                {branches &&
                  branches.map((branch, index) => {
                    return (
                      <div key={index}>
                        <h5 className="m-4">{branch.user.branch.name}</h5>
                        <hr />
                      </div>
                    );
                  })}
                <Link
                  to="/rc"
                  className="text-secondary text-center d-block text-decoration-none"
                >
                  Full Radiology Center{" "}
                  <i className="fa-solid fa-arrow-right-from-line"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StatisticPage;
