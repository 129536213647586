// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".doctor_input_form__gPgG8{\n    height: 20px;\n    width: 100px;\n}", "",{"version":3,"sources":["webpack://./src/components/doctor/doctor.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".input_form{\n    height: 20px;\n    width: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_form": "doctor_input_form__gPgG8"
};
export default ___CSS_LOADER_EXPORT___;
