import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Styles from "./requestTracking.module.css";
import { MdDelete } from "react-icons/md";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import { http, endPointsToken } from "../../globalConf/http";
import CustomToastify, { toastMes } from "../../globalConf/notification";
import Loading from "./../loading/loading";
import { Link } from "react-router-dom";
import moment from "moment";
import Style from "./requestTracking.module.css";
import { Modal, Backdrop, Fade, Typography, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import ModelStatus from "./modelStatus";

import { enGB } from "date-fns/locale";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import TableToExcel from "@linways/table-to-excel";

import "react-nice-dates/build/style.css";
import "./style.css";

const RequestTracking = ({ home = false }) => {
  const [load, setLoad] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [allRequestStatus, setAllRequestStatus] = useState([]);
  const [allRequestStatus360, setAllRequestStatus360] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [allRequestService, setAllRequestService] = useState([]);
  const [date, setDate] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(home ? 5 : 20);
  const [success, setSuccess] = useState(false);
  const [flag, setFlag] = useState(false);
  const [patientPhone, setPatientPhone] = useState("");
  const [patientName, setPatientName] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [selectedRequestId, setSelectedRequestId] = useState("");
  const [requestId, setRequestId] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [open, setOpen] = useState(false);
  //
  const [statusFlag, setStatusFlag] = useState(false);
  const [statusId, setStatusId] = useState();
  const [request, setRequest] = useState({});
  //
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { user } = useSelector((state) => state.login);
  let data = JSON.parse(localStorage.getItem("userOP"));
  const navigate = useNavigate();

  const fetchData = async () => {
    return new Promise(async (resolve, reject) => {
      if (data) {
        try {
          const res = await httpNonToken.get(
            `${endPoints.request}?order_by=-created_at${
              requestId ? `&id=${requestId}` : ""
            }&page=${page}${
              selectedStatus ? `&statu__id=${selectedStatus}` : ""
            }${date ? `&created_at__date=${date}` : ""}${
              selectedBranch
                ? `&created_by__branch_profile__id=${selectedBranch}`
                : ""
            }${selectedService ? `&service__type__id=${selectedService}` : ""}${
              patientName ? `&patient__fullname__icontains=${patientName}` : ""
            }${doctorName ? `&doctor__fullname__icontains=${doctorName}` : ""}${
              startDate
                ? `&created_at__date__gte=${
                    new Date(startDate).toISOString().split("T")[0]
                  }`
                : ""
            }${
              endDate
                ? `&created_at__date__lte=${
                    new Date(endDate).toISOString().split("T")[0]
                  }`
                : ""
            }&page_size=${pageSize}${
              patientPhone ? `&patient__mobile__contains=${patientPhone}` : ""
            }`,
            {
              headers: {
                Authorization: `Bearer ${data.access}`,
              },
            }
          );

          resolve(res.data);
          const resStatus360 = await httpNonToken.get(
            `${endPoints.status360}?order_by=id`
          );
          const resStatus = await httpNonToken.get(
            `${endPoints.requestStatus}?order_by=id`
          );

          const branchedRes = await httpNonToken.get(
            `${endPoints.rcBranch}?order_by=id&page_size=1000`
          );
          const resService = await httpNonToken.get(
            `${endPoints.serviceType}?order_by=id`
          );

          setDataTable(res.data);

          let sketchfabArr = [...checkedState];
          for (let i = 0; i < res.data.results.length; i++) {
            sketchfabArr.push(res.data.results[i].sketchfab);
          }

          setAllRequestStatus360(resStatus360.data);
          setCheckedState(sketchfabArr);
          setAllRequestStatus(resStatus.data.results);
          setAllRequestService(resService.data.results);
          setAllBranches(branchedRes.data.results);
          setLoad(false);
        } catch ({ response }) {
          if (response.status === 401 && response.data.code) {
            localStorage.removeItem("userOP");
            navigate("/login");
            return;
          }
        }
      } else {
        navigate("/login");
      }
    });
  };
  useEffect(() => {
    if (data) {
      fetchData();
    } else {
      navigate("/login");
    }
  }, [
    selectedStatus,
    selectedBranch,
    selectedService,
    startDate,
    endDate,
    page,
    date,
    pageSize,
    patientName,
    doctorName,
    requestId,
    flag,
  ]);

  const clearAllFilter = () => {
    setSelectedStatus("");
    setSelectedService("");
    setPatientName("");
    setDoctorName("");
    setRequestId("");
    setDate(null);
    setStartDate(null);
    setEndDate(null);
    setSelectedBranch("");
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const getRequestsByPhone = async (e) => {
    setPatientPhone(e.target.value);
    try {
      const res = await httpNonToken.get(
        `${endPoints.request}?page=${page}${
          selectedStatus ? `&statu__id=${selectedStatus}` : ""
        }${date ? `&created_at__date=${date}` : ""}&page_size=${pageSize}${
          patientPhone ? `&patient__mobile__contains=${patientPhone}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${data.access}`,
          },
        }
      );

      setDataTable(res.data);
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };

  const handleChange = async (event, id, position = 0) => {
    event.stopPropagation();
    event.preventDefault();
    let data = {};
    if (event.target.name === "cloud") {
      const updatedCheckedState = checkedState.map((item, index) => {
        if (index === position) data.sketchfab = !item;
        return index === position ? !item : item;
      });

      setCheckedState(updatedCheckedState);
    } else if (event.target.name === "status") {
      data.statu = event.target.value;
    }
    try {
      await httpNonToken.put(`${endPoints.updateRequest}/${id}`, data);
      setFlag(!flag);
      setSuccess(true);
      toastMes.success("Request Updates Successfully");
    } catch ({ response }) {
      setFlag(!flag);
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };

  const getTodayRequests = () => {
    let today = moment(new Date()).format("YYYY-MM-DD");
    setDate(today);
  };

  if (load) {
    return <Loading />;
  }

  const handlePageChange = (pageNum) => {
    setPage(pageNum);
  };

  const handleOpenDeleteModel = async (id) => {
    setSelectedRequestId(id);
    handleOpen();
  };

  const handleSubmit = async (e) => {
    try {
      await http.delete(`${endPointsToken.deleteRequest}/${selectedRequestId}`);
      toastMes.success("Request Deleted Successfully");
      setFlag(!flag);
      handleClose();
    } catch ({ response }) {
      toastMes.error(response.data.message[0]);
    }
  };

  const getStyle = (id) => {
    let style = {};
    style.borderRadius = "0px";
    style.textAlign = "center";
    style.padding = "5px";
    if (id === 2) {
      style.backgroundColor = "#FE9F44";
    } else if (id === 4) {
      style.backgroundColor = "#Ff4b4b";
    } else if (id === 5) {
      style.backgroundColor = "#77c3f6";
    } else if (id === 10) {
      style.backgroundColor = "#86b4b5";
    } else if (id === 11) {
      style.backgroundColor = "#F8a4a4";
    } else if (id === 12) {
      style.backgroundColor = "#D6e3a0";
    } else if (id === 13) {
      style.backgroundColor = "#A6efb5";
    } else if (id === 3) {
      style.backgroundColor = "#Feff6b";
    } else if (id === 6) {
      style.backgroundColor = "#Cbb1c7";
    } else if (id === 7) {
      style.backgroundColor = "#D5a4ff";
    } else if (id === 8) {
      style.backgroundColor = "#Cae3ff";
    } else if (id === 9) {
      style.backgroundColor = "#559b85";
    } else if (id === 14) {
      style.backgroundColor = "#E6e6e6";
    } else {
      style.backgroundColor = "lightgrey";
    }
    return style;
  };

  const actionChange = async (req) => {
    if (req.statu.id === 3) {
      try {
        let reqData = {};
        reqData.statu = 5;
        reqData.assign_to = data.user.id;
        await httpNonToken.put(`${endPoints.updateRequest}/${req.id}`, reqData);

        setSuccess(true);
        setFlag(!flag);
        toastMes.success("Request Updates Successfully.");
      } catch ({ response }) {
        setFlag(!flag);
        setSuccess(false);
        toastMes.error(response.data.message);
      }
    } else if (req.statu.id === 5) {
      setStatusId(req.statu.id);
      setStatusFlag(true);
      setRequest(req);
    } else if (req.statu.id === 8) {
      try {
        const updateReq = await httpNonToken.put(
          `${endPoints.updateRequest}/${req.id}`,
          {
            quality_assurence: true,
          }
        );
        if (updateReq.status === 200) {
          setFlag(!flag);
          toastMes.success("Request Updates Successfully");
        }
      } catch ({ response }) {
        toastMes.error(response.data.message);
      }
    } else if (req.statu.id === 6) {
      setStatusId(req.statu.id);
      setStatusFlag(true);
      setRequest(req);
    } else if (req.statu.id === 9) {
      setStatusId(req.statu.id);
      setStatusFlag(true);
      setRequest(req);
    } else if (req.statu.id === 11) {
      try {
        await httpNonToken.put(`${endPoints.updateRequest}/${req.id}`, {
          statu: 12,
        });

        setFlag(!flag);
        toastMes.success("Request Updates Successfully.");
      } catch ({ response }) {
        toastMes.error(response.data.message);
      }
    }
  };

  const ToExcell = () => {
    TableToExcel.convert(document.getElementById("table-data"), {
      name: "Qubx-Requests.xlsx",
      sheet: {
        name: "Sheet 1",
      },
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className={`${home ? "" : "bg_light p-2 h-100"}`}>
      <div className="bg-white border m-4 table-content request-track-table">
        <div className="align-items-center pt-3 mb-2">
          <div className="row">
            <div className=" col-md-2 filters-label">
              <div className="mx-2" style={{ marginTop: "24px" }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={clearAllFilter}
                >
                  Clear Filters
                </Button>
              </div>
            </div>
          </div>
          <div className="row px-2">
            {/* <div className="col-md-2">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Search By Phone Number .."
                variant="outlined"
                size="small"
                value={patientPhone}
                onChange={(e) => getRequestsByPhone(e)}
                className="float-start mt-4 me-3"
              />
            </div> */}
            <div className="col-md-3">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Search By Patient Name .."
                variant="outlined"
                size="small"
                value={patientName}
                onChange={(e) => setPatientName(e.target.value)}
                className="float-start mt-4 me-3"
              />
            </div>

            {home ? null : (
              <div className="searchDate col-md-5 mt-4">
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onStartDateChange={setStartDate}
                  onEndDateChange={setEndDate}
                  minimumLength={1}
                  format="dd MMM yyyy"
                  locale={enGB}
                >
                  {({ startDateInputProps, endDateInputProps, focus }) => (
                    <div className="date-range">
                      <input
                        className={
                          "input" + (focus === START_DATE ? " -focused" : "")
                        }
                        {...startDateInputProps}
                        placeholder="Start date"
                      />
                      <i className="fa-solid fa-angle-right text-secondary mx-2"></i>
                      <input
                        className={
                          "input" + (focus === END_DATE ? " -focused" : "")
                        }
                        {...endDateInputProps}
                        placeholder="End date"
                      />
                    </div>
                  )}
                </DateRangePicker>
              </div>
            )}
            <div className="col-md-2 mt-4">
              <Box sx={{ minWidth: 200 }}>
                <FormControl className={`w-100`}>
                  <InputLabel
                    id="demo-simple-select-label"
                    className={Styles.label_margin}
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={selectedStatus}
                    className={`${Styles.h_status}`}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    {allRequestStatus.map((status, index) => (
                      <MenuItem key={index} value={status.id}>
                        {status.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2 mt-4">
              <Box sx={{ minWidth: 200 }}>
                <FormControl className={`w-100`}>
                  <InputLabel
                    id="demo-simple-select-label"
                    className={Styles.label_margin}
                  >
                    Service
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={selectedService}
                    className={`${Styles.h_status}`}
                    onChange={(e) => setSelectedService(e.target.value)}
                  >
                    {allRequestService.map((service, index) => (
                      <MenuItem key={index} value={service.id}>
                        {service.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-3">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Search By ID .."
                variant="outlined"
                size="small"
                value={requestId}
                onChange={(e) => setRequestId(e.target.value)}
                className="float-start mt-4 me-3"
              />
            </div>
            <div className="col-md-3">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Search By Doctor Name"
                variant="outlined"
                size="small"
                value={doctorName}
                onChange={(e) => setDoctorName(e.target.value)}
                className="float-start mt-4 me-3"
              />
            </div>
            {/* <div className="searchDate float-start mt-4">
              <input
                type="date"
                id="searchDate"
                className="form-control"
                onChange={(e) => setDate(e.target.value)}
                value={date}
              />
            </div> */}
          </div>
          <div className="row px-4">
            <div
              className={`col-sm-2 mt-4 mx-1 py-1 text-center filters ${Style.filter_today} text-bold cursor-pointer`}
              onClick={getTodayRequests}
            >
              {date ? <i className="fa-solid fa-check mx-2"></i> : ""} Today
            </div>
            <div
              className={`col-sm-2 mt-4 mx-1 py-1 text-center filters ${Style.filter_pending} text-bold cursor-pointer`}
              onClick={() => setSelectedStatus(2)}
            >
              {selectedStatus === 2 ? (
                <i className="fa-solid fa-check mx-2"></i>
              ) : (
                ""
              )}
              Pending
            </div>
            <div
              className={`col-sm-2 mt-4 mx-1 py-1 text-center filters ${Style.filter_ready} text-bold cursor-pointer`}
              onClick={() => setSelectedStatus(3)}
            >
              {selectedStatus === 3 ? (
                <i className="fa-solid fa-check mx-2"></i>
              ) : (
                ""
              )}
              Ready
            </div>
            <div
              className={`col-sm-2 mt-4 mx-1 py-1 text-center filters ${Style.filter_returned} text-bold cursor-pointer`}
              onClick={() => setSelectedStatus(4)}
            >
              {selectedStatus === 4 ? (
                <i className="fa-solid fa-check mx-2"></i>
              ) : (
                ""
              )}
              Returned
            </div>
            <div
              className={`col-sm-2 mt-4 mx-1 py-1 text-center filters ${Style.filter_shipping} text-bold cursor-pointer`}
              onClick={() => setSelectedStatus(10)}
            >
              {selectedStatus === 10 ? (
                <i className="fa-solid fa-check mx-2"></i>
              ) : (
                ""
              )}
              Shipping Courier
            </div>
          </div>
        </div>
        <TableContainer sx={{ marginTop: 4 }} className="request-table-content">
          <div className="d-flex justify-content-end m-2 text-success cursor-pointer">
            <i onClick={ToExcell} className="fa-solid fa-file-excel fa-2x"></i>
          </div>
          <Table
            id="table-data"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
          >
            <TableHead sx={{ backgroundColor: "#dbdce0" }}>
              <TableRow>
                <TableCell style={{ padding: "6px 16px" }}>ID</TableCell>
                <TableCell style={{ padding: "6px 16px" }}>Date</TableCell>
                <TableCell
                  style={{
                    position: "relative",
                    padding: "4px 16px",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={{ minWidth: 120, border: 0 }}>Branch Name</Box>
                  <FormControl
                    sx={{ m: 1, minWidth: 120 }}
                    style={{
                      position: "absolute",
                      left: "-10px",
                      top: "-10px",
                    }}
                    size="small"
                  >
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={selectedBranch}
                      label="Status"
                      name="status"
                      onChange={(e) => setSelectedBranch(e.target.value)}
                    >
                      {allBranches.map((branch, index) => (
                        <MenuItem key={index} value={branch.id}>
                          {branch.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell style={{ padding: "6px 16px" }}>
                  Patient Name
                </TableCell>
                {/* <TableCell style={{ padding: "6px 16px" }}>
                  Patient Mobile
                </TableCell> */}
                <TableCell style={{ padding: "6px 16px" }}>
                  Doctor Name
                </TableCell>
                <TableCell style={{ padding: "6px 16px" }}>Service</TableCell>
                <TableCell style={{ padding: "6px 16px" }}>Operator</TableCell>
                <TableCell style={{ padding: "6px 16px" }}></TableCell>
                <TableCell style={{ padding: "6px 16px" }}>Status</TableCell>
                {/* <TableCell style={{ padding: "6px 16px" }}>
                  Cloud Type
                </TableCell> */}
                <TableCell style={{ padding: "6px 16px" }}></TableCell>
                {data.user.groups.includes(2) ? (
                  <TableCell style={{ padding: "6px 16px" }}>Action</TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.results.length > 0 ? (
                dataTable.results.map((req, index) => (
                  <TableRow key={req.id}>
                    <TableCell style={{ padding: "4px 16px" }}>
                      <Link
                        to={
                          req.service.type.id === 2
                            ? `/request_3D/${req.id}`
                            : req.service.type.id === 3
                            ? `/request_360/${req.id}`
                            : ""
                        }
                      >
                        {req.id}
                      </Link>
                    </TableCell>
                    <TableCell style={{ padding: "4px 16px" }}>
                      {new Date(req.created_at).toLocaleString()}
                    </TableCell>
                    <TableCell style={{ padding: "4px 16px" }}>
                      {req.branchName}
                    </TableCell>
                    <TableCell style={{ padding: "4px 16px" }}>
                      {req.patient.fullname}
                    </TableCell>
                    {/* <TableCell style={{ padding: "4px 16px" }}>
                      {req.patient.mobile}
                    </TableCell> */}
                    <TableCell style={{ padding: "4px 16px" }}>
                      {req.doctor?.fullname}
                    </TableCell>
                    <TableCell style={{ padding: "4px 16px" }}>
                      {req.service.type.id === 2 ? (
                        <>
                          <img
                            width="50px"
                            alt="service card"
                            className="mx-2"
                            src={"/images/global/3d-printing.svg"}
                          />
                          <span>{req.service.name}</span>
                        </>
                      ) : (
                        <>
                          <img
                            width="50px"
                            alt="service card"
                            className="mx-2"
                            src={"/images/global/360-printing.svg"}
                          />
                          {req.service.name}
                        </>
                      )}
                    </TableCell>
                    <TableCell style={{ padding: "4px 16px" }}>
                      {req.assign_to ? req.assign_to.fullname : ""}
                    </TableCell>
                    {req.service.type.id === 2 ? (
                      <TableCell
                        style={{
                          position: "relative",
                          padding: "4px 16px",
                          cursor: "pointer",
                        }}
                      >
                        <FormControl
                          sx={{ m: 1, minWidth: 120 }}
                          style={{
                            position: "absolute",
                            top: "5px",
                            left: "15px",
                          }}
                          size="small"
                        >
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={req.statu.id}
                            label="Status"
                            name="status"
                            onChange={(e) => handleChange(e, req.id)}
                          >
                            {allRequestStatus.map((status, index) => (
                              <MenuItem key={index} value={status.id}>
                                {status.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    ) : (
                      <TableCell
                        style={{
                          position: "relative",
                          padding: "4px 16px",
                          cursor: "pointer",
                        }}
                      >
                        <FormControl
                          sx={{ m: 1, minWidth: 120 }}
                          style={{
                            position: "absolute",
                            top: "5px",
                            left: "15px",
                          }}
                          size="small"
                        >
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={req.statu.id}
                            label="Status"
                            name="status"
                            onChange={(e) => handleChange(e, req.id)}
                          >
                            {allRequestStatus360.map((status, index) => (
                              <MenuItem key={index} value={status.id}>
                                {status.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    )}
                    <TableCell
                      onClick={() => actionChange(req)}
                      style={{
                        position: "relative",
                        padding: "4px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        style={getStyle(req.statu.id)}
                        sx={{ minWidth: 120, border: 0 }}
                      >
                        {req.statu.name}
                      </Box>
                      {/* <FormControl
                        sx={{ m: 1, minWidth: 120 }}
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "-20px",
                        }}
                        size="small"
                      >
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={req.statu.id}
                          label="Status"
                          name="status"
                          onChange={(e) => handleChange(e, req.id)}
                        >
                          {allRequestStatus.map((status, index) => (
                            <MenuItem key={index} value={status.id}>
                              {status.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                    </TableCell>
                    {/* <TableCell>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              name="cloud"
                              // disabled={req.service.type.id === 2}
                              disabled
                              checked={checkedState[index]}
                              onChange={(e) => handleChange(e, req.id, index)}
                            />
                          }
                          label="Sketch-Fab"
                        />
                      </FormGroup>
                    </TableCell> */}
                    <TableCell style={{ padding: "5px" }}>
                      <div className="d-flex justify-content-between">
                        <img
                          src={
                            req.note
                              ? "/images/requestTracking/note_activated.svg"
                              : "/images/requestTracking/note_deactivated.svg"
                          }
                          alt="img"
                          className="mx-1 d-block"
                        />
                        <img
                          src={
                            req.statu.SLA !== 0 &&
                            moment(req.updatedAtStatus)
                              .add(req.statu.SLA, "hours")
                              .isBefore(new Date())
                              ? "/images/requestTracking/delay_activated.svg"
                              : "/images/requestTracking/delay_deactivated.svg"
                          }
                          alt="img"
                          className="mx-1 d-block"
                        />
                      </div>
                    </TableCell>
                    {data.user.groups.includes(2) ? (
                      <TableCell sx={{ padding: "2px 14px" }}>
                        <Button>
                          <MdDelete
                            onClick={() => handleOpenDeleteModel(req.id)}
                            className="fs-3 me-3 mt-3 text-danger cursor-pointer"
                          ></MdDelete>
                        </Button>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="text-center" colSpan={8}>
                    No Items Here
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {dataTable.results.length > 0 ? (
            <div className="paginate-box d-flex justify-content-end p-2">
              {home ? (
                ""
              ) : (
                <Box>
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pageSize}
                      label=""
                      onChange={(e) => setPageSize(e.target.value)}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
              <button
                disabled={!dataTable.previous}
                className="m-2 border-0 bg-transparent"
                onClick={() => handlePageChange(1)}
              >
                <Tooltip title={`First Page: 1`}>
                  <i className="	fas fa-angle-double-left	"></i>
                </Tooltip>
              </button>
              <button
                onClick={() => handlePageChange(dataTable.page_number - 1)}
                disabled={!dataTable.previous}
                className="m-2 mx-3 border-0 bg-transparent"
              >
                <Tooltip title={`Previous Page: ${dataTable.page_number - 1}`}>
                  <i className="fas fa-chevron-left"></i>
                </Tooltip>
              </button>
              <p className="m-2 mt-3">{`${dataTable.from}-${dataTable.to}  of  ${dataTable.count}`}</p>

              <button
                className="m-2 mx-3 border-0 bg-transparent"
                disabled={!dataTable.next}
                onClick={() => handlePageChange(dataTable.page_number + 1)}
              >
                <Tooltip title={`Next Page: ${dataTable.page_number + 1}`}>
                  <i className="fas fa-chevron-right"></i>
                </Tooltip>
              </button>
              <button
                className="m-2 border-0 bg-transparent"
                disabled={!dataTable.next}
                onClick={() => handlePageChange(dataTable.total_pages)}
              >
                <Tooltip title={`Last Page: ${dataTable.total_pages}`}>
                  <i className="	fas fa-angle-double-right	"></i>
                </Tooltip>
              </button>
            </div>
          ) : (
            ""
          )}
        </TableContainer>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="row">
            <Typography variant="h5" gutterBottom component="div">
              Request Deleting
            </Typography>
            <Divider sx={{ width: "50%", margin: "left" }} />
            <div className="row">
              <div className="col-12 mt-3 text-center">
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  className="mx-2"
                  color="error"
                >
                  Attention... !!
                </Button>
              </div>
              <p className="col-12 lean text-center mt-2">
                Are You Sure To Delete This Request
              </p>

              <div className="col-12 d-flex justify-content-end mt-4">
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  className="mx-2"
                  color="error"
                >
                  Close
                </Button>
                <Button variant="outlined" onClick={handleSubmit}>
                  Delete
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* <CustomToastify bgColor={success ? "green" : "red"} duration={false} /> */}
      <ModelStatus
        statusFlag={statusFlag}
        setStatusFlag={setStatusFlag}
        request={request}
        statusId={statusId}
        setStatusId={setStatusId}
        setFlag={setFlag}
        flag={flag}
      />
    </div>
  );
};

export default RequestTracking;
