import React, { useEffect, useState } from "react";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import { useNavigate } from "react-router-dom";
import Loading from "../loading/loading";
import AgeGroup from "../ageGroup/AgeGroup";
import AnatomyService from "../anatomyService/AnatomyService";
import AnatomyParts from "../anatomyParts/AnatomyParts";
import Pathologies from "../pathologies/Pathologies";

const CPanel = () => {
  let data = {};
  data = JSON.parse(localStorage.getItem("userOP"));
  const navigate = useNavigate();

  const [flag, setFlag] = useState(true);
  const [load, setLoad] = useState(true);
  const [ageGroups, setAgeGroups] = useState([]);
  const [services, setServices] = useState([]);
  const [pathologies, setPathologies] = useState([]);
  const [anatomies, setAnatomies] = useState([]);

  useEffect(() => {
    (async () => {
      if (data) {
        if (data.user.groups.includes(2)) {
          let allAgeGroups = await httpNonToken.get(
            `${endPoints.ageGroups}?order_by=id`
          );
          let allServices = await httpNonToken.get(
            `${endPoints.service}?order_by=id`
          );
          let allAnatomies = await httpNonToken.get(
            `${endPoints.anatomy}?order_by=id`
          );
          let allPathologies = await httpNonToken.get(
            `${endPoints.pathology}?order_by=id`
          );

          setAgeGroups(allAgeGroups.data.results);
          setServices(allServices.data.results);
          setPathologies(allPathologies.data.results);
          setAnatomies(allAnatomies.data.results);
          setLoad(false);
        } else {
          navigate("/");
        }
      } else {
        navigate("/login");
      }
    })();
  }, [flag]);

  if (load) {
    return <Loading />;
  }

  return (
    <div className="w-100 py-5 px-1 ">
      <div className="row">
        <div className="col-md-6">
          <AnatomyService services={services} flag={flag} setFlag={setFlag} />
        </div>
        <div className="col-md-6">
          <AnatomyParts anatomies={anatomies} flag={flag} setFlag={setFlag} />
        </div>
        <div className="col-md-6">
          <AgeGroup ageGroups={ageGroups} flag={flag} setFlag={setFlag} />
        </div>
        <div className="col-md-6">
          <Pathologies
            pathologies={pathologies}
            flag={flag}
            setFlag={setFlag}
          />
        </div>
      </div>
    </div>
  );
};

export default CPanel;
