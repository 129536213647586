import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";

export const getDiconSources = createAsyncThunk(
  "diconSources/get",
  async () => {
    try {
      const res = await httpNonToken.get(`${endPoints.diconSource}`);
      return res.data;
    } catch (error) {
    }
  }
);

const initialState = {
  diconSources: [],
};

export const diconSourceSlice = createSlice({
  name: "diconSource",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDiconSources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.diconSources = action.payload.results;
      })
      .addCase(getDiconSources.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default diconSourceSlice.reducer;
