import React, { useState, useReducer, useEffect } from "react";
import Button from "@mui/material/Button";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";

import { convertZipFile } from "../../globalConf/zipping";
import { httpNonToken, endPoints } from "../../globalConf/http-non-token";
import CustomToastify, { toastMes } from "../../globalConf/notification";
import axios from "axios";

import "./modelStatus.css";

function SimpleDialog(props) {
  const [upload, setUpload] = useState(null);
  const [allPrinting, setAllPrintings] = useState("");
  const [allCouriers, setAllCouriers] = useState("");

  const { onClose, request, statusFlag, statusId, setStatusId, setFlag, flag } =
    props;

  const [requestPrinting, setRequestPrinting] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      printing: "",
      weight: "",
      size: "",
      cost: "",
      duration: "",
      requests: "",
    }
  );
  const [requestCourier, setRequestCourier] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      duration: "",
      tracking_code: "",
      cost: "",
      requests: "",
      courier: "",
      phone: "",
    }
  );

  useEffect(() => {
    (async () => {
      try {
        const couriers = await httpNonToken.get(
          `${endPoints.courier}?page_size=1000`
        );
        const printings = await httpNonToken.get(
          `${endPoints.printing}?page_size=1000`
        );

        setAllCouriers(couriers.data.results);
        setAllPrintings(printings.data.results);
      } catch ({ response }) {
        toastMes.error(response.data.message);
      }
    })();
  }, []);

  const handleClose = () => {
    onClose();
    setStatusId("");
  };

  const handleCourierChange = (event) => {
    setRequestCourier({ courier: event.target.value });
  };

  const handlePrintingChange = (event) => {
    setRequestPrinting({ printing: event.target.value });
  };

  const handleFileChange = async (e) => {
    let file = await convertZipFile(
      e.target.files,
      `request${request.id}`,
      `request${request.id}.zip`
    );
    let data = new FormData();
    data.append("file", file);
    data.append("name", `request${request.id}`);

    if (request.service.type.id === 3) {
      if (request.sketchfab) {
        try {
          const updateStlReq = await httpNonToken.put(
            `${endPoints.stl}`,
            data,
            {
              onUploadProgress: (data) => {
                setUpload(Math.round((data.loaded / data.total) * 100));
              },
            }
          );
          const sketchFabResponse = await axios.get(updateStlReq.data.message, {
            headers: {
              Authorization: "Token 4f6cba8e50334555a101b61260260a5c",
            },
          });

          await httpNonToken.put(`${endPoints.updateRequest}/${request.id}`, {
            result_URL: sketchFabResponse.data.embedUrl,
            statu: 8,
          });
          setUpload(null);
          setFlag(!flag);
          handleClose();
          toastMes.success(`Request ${request.id} Updates Successfully`);
        } catch ({ response }) {
          toastMes.error(response.error.message);
        }
      } else {
        try {
          const updateStatusRequestRes = await httpNonToken.post(
            `${endPoints.sirv}/${request.id}`,
            data,
            {
              onUploadProgress: (data) => {
                setUpload(Math.round((data.loaded / data.total) * 100));
              },
            }
          );
          if (
            updateStatusRequestRes.status === 200 &&
            updateStatusRequestRes.data.url === "error"
          ) {
            toastMes.error("Spin Creation Fail");
          } else {
            setUpload(null);
            setFlag(!flag);
            handleClose();
            toastMes.success(
              `Images in Request ${request.id} Uploaded Successfully`
            );
          }
        } catch ({ response }) {
          toastMes.error(response.data.message);
        }
      }
    } else {
      try {
        const updateStlReq = await httpNonToken.put(`${endPoints.stl}`, data, {
          onUploadProgress: (data) => {
            setUpload(Math.round((data.loaded / data.total) * 100));
          },
        });
        const sketchFabResponse = await axios.get(updateStlReq.data.message, {
          headers: {
            Authorization: "Token 4f6cba8e50334555a101b61260260a5c",
          },
        });

        await httpNonToken.put(`${endPoints.updateRequest}/${request.id}`, {
          result_URL: sketchFabResponse.data.embedUrl,
          statu: 6,
        });
        setUpload(null);
        setFlag(!flag);
        handleClose();
        toastMes.success(`Request ${request.id} Updates Successfully`);
      } catch ({ response }) {
        toastMes.error(response.error.message);
      }
    }
  };

  const submitPrinting = async () => {
    if (!requestPrinting.printing) {
      toastMes.error("Printing Center is required");
      return;
    }
    try {
      await httpNonToken.post(`${endPoints.createRequestPrinting}`, {
        ...requestPrinting,
        requests: request.id,
      });
      setFlag(!flag);
      setRequestPrinting({
        printing: "",
        weight: "",
        size: "",
        cost: "",
        duration: "",
        requests: "",
      });
      handleClose();
      toastMes.success("Printing Center Saved");
    } catch ({ response }) {
      for (const key in response.data) {
        toastMes.error(`${key}, ${response.data[key]}`);
      }
    }
  };

  const submitCourier = async () => {
    if (!requestCourier.courier) {
      toastMes.error("Courier is required");
      return;
    }
    try {
      await httpNonToken.post(`${endPoints.createRequestCourier}`, {
        ...requestCourier,
        requests: request.id,
      });
      handleClose();
      setRequestCourier({
        duration: "",
        tracking_code: "",
        cost: "",
        requests: "",
        courier: "",
        phone: "",
      });
      setFlag(!flag);
      toastMes.success("Courier Saved");
    } catch ({ response }) {
      for (const key in response.data) {
        toastMes.error(`${key}, ${response.data[key]}`);
      }
    }
  };

  return (
    <Dialog onClose={handleClose} className="model-status" open={statusFlag}>
      {statusId === 5 ? (
        <div className="file-upload-form">
          <h6 className="title font-sm fw-bold border-bottom text-uppercase pb-3 f-main ">
            <span>
              {request.service.type.id === 2 ||
              (request.service.type.id === 3 && request.sketchfab)
                ? "Upload SketchFab File"
                : "Upload Files"}
            </span>{" "}
            - <span>( {request.patient.fullname} )</span>
          </h6>
          <label className="filelabel mt-5">
            <i className="fa fa-paperclip"></i>
            <span className="title">
              {request.service.type.id === 2 ||
              (request.service.type.id === 3 && request.sketchfab)
                ? "Upload Your SketchFab File"
                : "Upload Your Files"}
            </span>
            <input
              multiple={request.service.type.id === 3}
              onChange={(e) => handleFileChange(e)}
              // accept=".zip,.stl,.rar,.ply,.obj,.gltf,.bin,.glb,.ply,.blend,.fbx,.3dc, .asc,.3ds,.abc,.dae, .zae,.igs, .iges,.las,.usd, .usdz, .usda, .usdc,.mtl"
              className="FileUpload1"
              id="FileInput"
              name="booking_attachment"
              type="file"
            />
          </label>
          {upload ? (
            <div className="progress mt-2 mb-4" style={{ height: "0.3rem" }}>
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow={upload}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${upload}%` }}
              >
                <span className="sr-only">{upload}</span>
              </div>
            </div>
          ) : null}
          <div className="d-flex justify-content-end">
            <Button variant="contained" className="mt-4">
              Close
            </Button>
          </div>
        </div>
      ) : null}
      {statusId === 6 ? (
        <>
          <div className="modal-body">
            <h6 className="title font-sm fw-bold border-bottom text-uppercase pb-3 f-main ">
              <span>Connect With Printing Center</span> -{" "}
              <span>( {request.patient.fullname} )</span>
            </h6>
            <div className="me-3  mt-5 w-100">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  3D Printing Center
                </InputLabel>

                <Select
                  MenuProps={{ disablePortal: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={requestPrinting.printing}
                  label="3D Printing Center"
                  onChange={handlePrintingChange}
                >
                  {allPrinting &&
                    allPrinting.map((printing, index) => (
                      <MenuItem key={index} value={printing.id}>
                        {printing.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="row mt-3 f-main">
              <div className="col-6">
                <TextField
                  className="w-100"
                  id="outlined-basic"
                  label="Weight"
                  variant="outlined"
                  onChange={(e) =>
                    setRequestPrinting({ weight: e.target.value })
                  }
                  value={requestPrinting.weight}
                />
              </div>
              <div className="col-6">
                <TextField
                  className="w-100"
                  id="outlined-basic"
                  label="Size"
                  onChange={(e) => setRequestPrinting({ size: e.target.value })}
                  value={requestPrinting.size}
                  variant="outlined"
                />
              </div>
              <div className="col-6 mt-3">
                <TextField
                  className="w-100"
                  id="outlined-basic"
                  label="Cost"
                  onChange={(e) => setRequestPrinting({ cost: e.target.value })}
                  value={requestPrinting.cost}
                  variant="outlined"
                />
              </div>
              <div className="col-6 mt-3">
                <TextField
                  className="w-100"
                  id="outlined-basic"
                  label="Processing Time"
                  onChange={(e) =>
                    setRequestPrinting({ duration: e.target.value })
                  }
                  value={requestPrinting.duration}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary w-25"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button onClick={submitPrinting} className="btn btn-primary w-25">
              Save
            </button>
          </div>
        </>
      ) : null}
      {statusId === 9 ? (
        <>
          <div className="modal-body">
            <h6 className="title font-sm fw-bold border-bottom text-uppercase pb-3 f-main ">
              <span>Connect With Courier Center</span> -{" "}
              <span>( {request.patient.fullname} )</span>
            </h6>
            <div className="me-3 mt-5 w-100">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Courier</InputLabel>

                <Select
                  MenuProps={{ disablePortal: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={requestCourier.courier}
                  label="Courier"
                  onChange={handleCourierChange}
                >
                  {allCouriers &&
                    allCouriers.map((cour, index) => (
                      <MenuItem key={index} value={cour.id}>
                        {cour.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="row mt-3 f-main">
              <div className="col-6">
                <TextField
                  id="outlined-basic"
                  label="Shipment Tracking ID"
                  onChange={(e) =>
                    setRequestCourier({ tracking_code: e.target.value })
                  }
                  value={requestCourier.tracking_code}
                  variant="outlined"
                  className="w-100"
                />
              </div>
              <div className="col-6">
                <TextField
                  className="w-100"
                  id="outlined-basic"
                  label="Delivery Time"
                  onChange={(e) =>
                    setRequestCourier({ duration: e.target.value })
                  }
                  value={requestCourier.duration}
                  variant="outlined"
                />
              </div>
              <div className="col-12 mt-3">
                <TextField
                  className="w-100"
                  id="outlined-basic"
                  label="Cost"
                  onChange={(e) => setRequestCourier({ cost: e.target.value })}
                  value={requestCourier.cost}
                  variant="outlined"
                />
              </div>
              <div className="col-12 mt-3">
                <TextField
                  className="w-100"
                  id="outlined-basic"
                  label="Delivery Phone Number"
                  onChange={(e) => setRequestCourier({ phone: e.target.value })}
                  value={requestCourier.phone}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary w-25"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button onClick={submitCourier} className="btn btn-primary w-25">
              Save
            </button>
          </div>
        </>
      ) : null}
    </Dialog>
  );
}

export default function ModelStatus({
  setStatusFlag,
  statusFlag,
  statusId,
  setStatusId,
  request,
  setFlag,
  flag,
}) {
  const handleClose = (value) => {
    setStatusFlag(false);
    setStatusId("");
  };

  return (
    <div>
      <SimpleDialog
        flag={flag}
        setFlag={setFlag}
        setStatusFlag={setStatusFlag}
        statusFlag={statusFlag}
        statusId={statusId}
        setStatusId={setStatusId}
        request={request}
        onClose={handleClose}
      />
    </div>
  );
}
